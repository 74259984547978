import React, { useState, useEffect } from 'react';
import {
	Box,
	IconButton,
	Menu,
	MenuItem,
	MenuProps,
	Skeleton,
	Typography,
	Tooltip,
	Button,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { isEmpty } from 'lodash';
import moment from 'moment';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import { DeleteIcon, PassIcon, ProductOverview } from 'shared_components/src/common/icons';

import { VerificationList as List } from 'shared_components/src/components/verification';

import {
	ADMIN,
	AWAITING_PAYMENT_DETAILS,
	BIOPASS,
	CANCEL,
	EDIT_ACCOUNT_DETAILS,
	EDIT_PAYMENT_DETAILS,
	ERROR,
	INVOICE_PARSER,
	KYC,
	PAYMENT_FAILED,
	PENDING,
	SUBSCRIBE_AGAIN,
	SUSPENDED,
	TERMINATED,
	VERIFY,
} from './icons';

const useStyles = makeStyles(() => ({
	root: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		minHeight: '50px',
		paddingBottom: '4px',

		'& svg': {
			minWidth: '16px',
		},

		'& .render-menu': {
			'@media screen and (max-width: 1280px)': {
				marginRight: '24px',
			},
		},

		'& .mobile-card': {
			minWidth: '95%',

			'@media screen and (max-width: 425px)': {
				minWidth: '92%',
			},
		},

		'& .mobile-actions': {
			marginBottom: '8px',
		},
	},
}));

const StyledMenu = styled((props: MenuProps) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: 6,
		marginTop: theme.spacing(1),
		width: 240,
		color: '#470D82',
		border: '1px solid #E1E1EA',
		boxShadow:
			'0px 5px 5px -1px rgba(0,0,0, 0.02), 0px 5px 5px -1px rgba(0,0,0,0.0), 0px 8px 8px 1px rgba(0,0,0,0.0)',

		'& .MuiMenu-list': {
			padding: '4px 0',
		},
		'& .MuiMenuItem-root': {
			fontSize: '16px',
			lineHeight: '20px',
			fontWeight: '400',
			'& svg': {
				marginRight: '14px',
			},
		},
	},
}));

interface IProps {
	data: any;
	order?: any;
	orderBy?: string;
	showEmptyTable?: boolean;
	handleView?: any;
	handleEditAccountDetails?: any;
	handleEditPaymentDetails?: any;
	handleSubscribeAgain?: any;
	handleDelete?: any;
	sortTenants?: any;
	setOrder?: any;
}

const TenantList = ({
	data,
	order,
	orderBy,
	sortTenants,
	setOrder,
	showEmptyTable,
	handleView,
	handleEditAccountDetails,
	handleEditPaymentDetails,
	handleSubscribeAgain,
	handleDelete,
}: IProps) => {
	const classes = useStyles();

	const [loading, setLoading] = useState(true);
	const [listData, setListData] = useState([] as any);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [actions, setAction] = useState([] as any);

	useEffect(() => {
		prepareData();
	}, [data]);

	const prepareData = () => {
		if (!isEmpty(data)) {
			const items = data.map((el, i) => ({
				organisationName: el.organisationName,
				alias: el.alias,
				product: el.product,
				admin: el.owner.email,
				status: el.status,
				createdAt: moment(el.createdAt).fromNow(),
				region: el.region.name,
				currency: el.currency,
				actions: actionItems[el.alias],
			}));
			setListData(items);
			setLoading(false);
		}
	};

	const headerData = [
		{
			label: 'TENANT NAME',
			id: 'TENANT_NAME',
			sortable: true,
			size: {
				xxlSize: '22%',
				xlSize: '15%',
				lgSize: '16%',
				mdSize: '200px',
				smSize: '200px',
			},
			shouldHaveMinWidth: true,
		},
		{
			label: 'TENANT ALIAS',
			id: 'TENANT_ALIAS',
			sortable: true,
			size: {
				xxlSize: '15%',
				xlSize: '15%',
				lgSize: '15%',
				mdSize: '126px',
				smSize: '126px',
			},
			shouldHaveMinWidth: true,
		},
		{
			label: 'PRODUCT',
			id: 'PRODUCT',
			size: {
				xxlSize: '12%',
				xlSize: '13%',
				lgSize: '15%',
				mdSize: '165px',
				smSize: '165px',
			},
			shouldHaveMinWidth: true,
		},
		{
			label: 'ADMIN',
			id: 'ADMIN',
			size: {
				xxlSize: '20%',
				xlSize: '23%',
				lgSize: '21%',
				mdSize: '130px',
				smSize: '130px',
			},
			shouldHaveMinWidth: true,
		},
		{
			label: 'STATUS',
			id: 'STATUS',
			size: {
				xxlSize: '15%',
				xlSize: '17%',
				lgSize: '16%',
				mdSize: '100px',
				smSize: '100px',
			},
			shouldHaveMinWidth: true,
		},
		{
			label: 'CREATED ON',
			id: 'CREATED_ON',
			sortable: true,
			size: {
				xxlSize: '14%',
				xlSize: '14%',
				lgSize: '14%',
				mdSize: '130px',
				smSize: '130px',
			},
			shouldHaveMinWidth: true,
		},
		{
			label: '',
			id: '',
			size: {
				xxlSize: '50px',
				xlSize: '50px',
				lgSize: '50px',
				mdSize: '50px',
				smSize: '50px',
			},
			shouldHaveMinWidth: true,
		},
	];

	const renaderIcon = (type) => {
		switch (type) {
			case 'ADMIN':
				return <ADMIN width={16} height={16} />;
				break;
			case 'BIOPASS':
				return <BIOPASS width={16} height={16} />;
				break;
			case 'KYC':
				return <KYC width={16} height={16} />;
				break;
			case 'VERIFY':
				return <VERIFY width={16} height={16} />;
				break;
			case 'INVOICE-PARSER':
				return <INVOICE_PARSER width={16} height={16} />;
				break;
			case 'ACTIVE':
				return <PassIcon width={16} height={16} />;
				break;
			case 'AWAITING-PAYMENT-DETAILS':
				return <AWAITING_PAYMENT_DETAILS width={16} height={16} />;
				break;
			case 'CANCELLED':
				return <CANCEL width={16} height={16} />;
				break;
			case 'ACTIVE-PAYMENT-FAILED':
				return <PAYMENT_FAILED width={16} height={16} />;
				break;
			case 'SUSPENDED':
				return <SUSPENDED width={16} height={16} />;
				break;
			case 'TERMINATED':
				return <TERMINATED width={16} height={16} />;
				break;
			case 'ERROR':
				return <ERROR width={16} height={16} />;
				break;
			case 'DELETE':
				return <DeleteIcon width={16} height={16} />;
				break;
			case 'PENDING':
				return <PENDING width={16} height={16} />;
				break;
			case 'VIEW_PRODUCT_OVERVIEW':
				return <ProductOverview width={24} height={24} />;
				break;
			case 'EDIT_ACCOUNT_DETAILS':
				return <EDIT_ACCOUNT_DETAILS width={24} height={24} />;
				break;
			case 'EDIT_PAYMENT_DETAILS':
				return <EDIT_PAYMENT_DETAILS width={24} height={24} />;
				break;
			case 'SUBSCRIBE_AGAIN':
				return <SUBSCRIBE_AGAIN width={24} height={24} />;
				break;
			default:
				return <></>;
				break;
		}
	};

	const actionItems = {
		ACTIVE: [
			{
				label: 'View Product Overview',
				key: 'VIEW_PRODUCT_OVERVIEW',
				icon: renaderIcon('VIEW_PRODUCT_OVERVIEW'),
			},
			{
				label: 'Edit Account Details',
				key: 'EDIT_ACCOUNT_DETAILS',
				icon: renaderIcon('EDIT_ACCOUNT_DETAILS'),
			},
			/* {
				label: 'Extend Free Trial',
				key: 'EXTEND_FREE_TRIAL',
				icon: renaderIcon('EXTEND_FREE_TRIAL'),
			}, */
			// { label: 'Delete', key: 'DELETE', icon: renaderIcon('DELETE') },
			// { label: 'Suspend', key: 'SUSPEND', icon: renaderIcon('SUSPEND') },
		],
		'AWAITING-PAYMENT-DETAILS': [
			{
				label: 'View Product Overview',
				key: 'VIEW_PRODUCT_OVERVIEW',
				icon: renaderIcon('VIEW_PRODUCT_OVERVIEW'),
			},
			{
				label: 'Edit Payment Details',
				key: 'EDIT_PAYMENT_DETAILS',
				icon: renaderIcon('EDIT_PAYMENT_DETAILS'),
			},
			{
				label: 'Edit Account Details',
				key: 'EDIT_ACCOUNT_DETAILS',
				icon: renaderIcon('EDIT_ACCOUNT_DETAILS'),
			},
			/* {
				label: 'Extend Free Trial',
				key: 'EXTEND_FREE_TRIAL',
				icon: renaderIcon('EXTEND_FREE_TRIAL'),
			}, */
			// { label: 'Delete', key: 'DELETE', icon: renaderIcon('DELETE') },
			// { label: 'Suspend', key: 'SUSPEND', icon: renaderIcon('SUSPEND') },
		],
		CANCELLED: [
			{
				label: 'Subscribe Again',
				key: 'SUBSCRIBE_AGAIN',
				icon: renaderIcon('SUBSCRIBE_AGAIN'),
			},
			{
				label: 'View Product Overview',
				key: 'VIEW_PRODUCT_OVERVIEW',
				icon: renaderIcon('VIEW_PRODUCT_OVERVIEW'),
			},
			{
				label: 'Edit Account Details',
				key: 'EDIT_ACCOUNT_DETAILS',
				icon: renaderIcon('EDIT_ACCOUNT_DETAILS'),
			},
			/* {
				label: 'Extend Free Trial',
				key: 'EXTEND_FREE_TRIAL',
				icon: renaderIcon('EXTEND_FREE_TRIAL'),
			}, */
			// { label: 'Delete', key: 'DELETE', icon: renaderIcon('DELETE') },
			// { label: 'Suspend', key: 'SUSPEND', icon: renaderIcon('SUSPEND') },
		],
		'ACTIVE-PAYMENT-FAILED': [
			{
				label: 'View Product Overview',
				key: 'VIEW_PRODUCT_OVERVIEW',
				icon: renaderIcon('VIEW_PRODUCT_OVERVIEW'),
			},
			{
				label: 'Edit Payment Details',
				key: 'EDIT_PAYMENT_DETAILS',
				icon: renaderIcon('EDIT_PAYMENT_DETAILS'),
			},
			{
				label: 'Edit Account Details',
				key: 'EDIT_ACCOUNT_DETAILS',
				icon: renaderIcon('EDIT_ACCOUNT_DETAILS'),
			},
			/* {
				label: 'Extend Free Trial',
				key: 'EXTEND_FREE_TRIAL',
				icon: renaderIcon('EXTEND_FREE_TRIAL'),
			}, */
			// { label: 'Delete', key: 'DELETE', icon: renaderIcon('DELETE') },
			// { label: 'Suspend', key: 'SUSPEND', icon: renaderIcon('SUSPEND') },
		],
		SUSPENDED: [
			{
				label: 'View Product Overview',
				key: 'VIEW_PRODUCT_OVERVIEW',
				icon: renaderIcon('VIEW_PRODUCT_OVERVIEW'),
			},
			{
				label: 'Edit Account Details',
				key: 'EDIT_ACCOUNT_DETAILS',
				icon: renaderIcon('EDIT_ACCOUNT_DETAILS'),
			},
			// { label: 'Delete', key: 'DELETE', icon: renaderIcon('DELETE') }
		],
	};

	const statusLabel = (status) => {
		switch (status) {
			case 'ACTIVE':
				return 'Active';
				break;
			case 'AWAITING-PAYMENT-DETAILS':
				return 'Awaiting for payment details';
				break;
			case 'CANCELLED':
				return 'Cancel';
				break;
			case 'ERROR':
				return 'Error';
				break;
			case 'ACTIVE-PAYMENT-FAILED':
				return 'Payment failed';
				break;
			case 'PENDING':
				return 'Pending';
				break;
			case 'SUSPENDED':
				return 'Suspended';
				break;
			case 'TERMINATED':
				return 'Terminated';
				break;
			default:
				return status;
				break;
		}
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleAnchorElClick = (id, status, event: React.MouseEvent<HTMLButtonElement>) => {
		event.persist();
		event.preventDefault();
		setAction(
			actionItems[status].map((item) => ({
				...item,
				id,
			}))
		);
		setAnchorEl(event.currentTarget);
	};

	const createSortHandler = (property: string, sortable: boolean) => (event: React.MouseEvent<unknown>) => {
		if (sortable) {
			const isInc = orderBy === property && order === 'inc';
			setOrder(isInc ? 'desc' : 'inc');
			sortTenants(property);
		}
	};

	const handleMenuAction = (type, alias) => {
		if (type === 'VIEW_PRODUCT_OVERVIEW') {
			handleView(alias);
		} else if (type === 'EDIT_ACCOUNT_DETAILS') {
			handleEditAccountDetails(alias);
		} else if (type === 'EDIT_PAYMENT_DETAILS') {
			handleEditPaymentDetails(alias);
		} else if (type === 'EXTEND_FREE_TRIAL') {
			console.log('EXTEND_FREE_TRIAL');
		} else if (type === 'DELETE') {
			handleDelete(alias);
		} else if (type === 'SUSPEND') {
			console.log('SUSPEND');
		} else if (type === 'SUBSCRIBE_AGAIN') {
			handleSubscribeAgain(alias);
		}
		setAnchorEl(null);
	};

	const handleViewAction = (alias, status) => {
		if (status !== 'ERROR' && status !== 'PENDING' && status !== 'TERMINATED') {
			handleView(alias);
		}
	};

	const statusTooltip = (status) => {
		return (
			<Tooltip title={`${statusLabel(status)} status`} placement="top" className={'tooltip'}>
				<Button
					sx={{
						minWidth: '16px',
						padding: 0,
					}}
				>
					{renaderIcon(status)}
				</Button>
			</Tooltip>
		);
	};

	const renderMenu = (id, status, actions, addMargin = false) => {
		return (
			<>
				{status === 'ERROR' || status === 'PENDING' || status === 'TERMINATED' ? (
					<Box display="flex"></Box>
				) : (
					<Box className={'render-menu'} display="flex" mr={addMargin ? 1 : 0}>
						<IconButton
							edge="start"
							color="inherit"
							aria-label="open detail"
							aria-haspopup="true"
							onClick={(event) => handleAnchorElClick(id, status, event)}
						>
							<MoreHorizIcon />
						</IconButton>
						<StyledMenu
							id={'menu'}
							anchorEl={anchorEl}
							keepMounted
							open={Boolean(anchorEl)}
							onClose={handleMenuClose}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
						>
							{actions.map((action) => {
								return (
									<MenuItem
										key={`${action.key}-${action.id}`}
										onClick={() => handleMenuAction(action.key, action.id)}
									>
										{renaderIcon(action.key)}
										{action.label}
									</MenuItem>
								);
							})}
						</StyledMenu>
					</Box>
				)}
			</>
		);
	};

	const bodyBuilder = (refreshing: boolean) => {
		const myArr = Array.from(
			{
				length: 10,
			},
			() => ''
		);
		if (!refreshing) {
			return listData.map(
				({ organisationName, alias, product, admin, status, createdAt, region, currency }) => {
					return {
						items: [
							{
								size: {
									xxlSize: '22%',
									xlSize: '15%',
									lgSize: '16%',
									mdSize: '200px',
									smSize: '200px',
								},
								shouldHaveMinWidth: true,
								alignment: 'flex-start',
								content: (
									<>
										<Box
											sx={{
												maxWidth: '95%',
												overflow: 'hidden',
												whiteSpace: 'nowrap',
												display: 'flex',
												flexDirection: 'column',
											}}
											onClick={() => handleViewAction(alias, status)}
										>
											<Typography
												sx={{
													fontSize: '14px',
													lineHeight: '16px',
													color: '#4F4F4F',
													overflow: 'hidden',
													textOverflow: 'ellipsis',
													'&:hover': {
														cursor: 'pointer',
														color: '#470D82',
														overflow: 'visible',
														whiteSpace: 'normal',
														overflowWrap: 'break-word',
													},
												}}
											>
												{organisationName}
											</Typography>
										</Box>
									</>
								),
							},
							{
								size: {
									xxlSize: '15%',
									xlSize: '15%',
									lgSize: '15%',
									mdSize: '126px',
									smSize: '126px',
								},
								shouldHaveMinWidth: true,
								divider: true,
								alignment: 'flex-start',
								content: (
									<>
										<Box
											sx={{
												maxWidth: '95%',
												overflow: 'hidden',
												whiteSpace: 'nowrap',
												display: 'flex',
												flexDirection: 'column',
											}}
										>
											<Typography
												sx={{
													fontSize: '14px',
													lineHeight: '16px',
													color: '#4F4F4F',
													overflow: 'hidden',
													textOverflow: 'ellipsis',
													'&:hover': {
														overflow: 'visible',
														whiteSpace: 'normal',
														overflowWrap: 'break-word',
													},
												}}
											>
												{alias}
											</Typography>
										</Box>
									</>
								),
							},
							{
								size: {
									xxlSize: '12%',
									xlSize: '13%',
									lgSize: '15%',
									mdSize: '165px',
									smSize: '165px',
								},
								shouldHaveMinWidth: true,
								divider: true,
								alignment: 'flex-start',
								content: (
									<>
										<Box
											sx={{
												maxWidth: '95%',
												display: 'flex',
												flexDirection: 'row',
												overflow: 'hidden',
												whiteSpace: 'nowrap',
												alignItems: 'center',
											}}
										>
											{renaderIcon(product.code)}
											<Typography
												sx={{
													fontSize: '14px',
													lineHeight: '16px',
													color: '#515170',
													marginLeft: '8px',
													overflow: 'hidden',
													textOverflow: 'ellipsis',
													'&:hover': {
														overflow: 'visible',
														whiteSpace: 'normal',
														overflowWrap: 'break-word',
													},
												}}
											>
												{product.name}
											</Typography>
										</Box>
									</>
								),
							},
							{
								size: {
									xxlSize: '20%',
									xlSize: '23%',
									lgSize: '21%',
									mdSize: '130px',
									smSize: '130px',
								},
								shouldHaveMinWidth: true,
								divider: true,
								alignment: 'flex-start',
								content: (
									<>
										<Box
											sx={{
												maxWidth: '95%',
												overflow: 'hidden',
												whiteSpace: 'nowrap',
												display: 'flex',
												flexDirection: 'column',
											}}
										>
											<Typography
												sx={{
													fontSize: '14px',
													lineHeight: '16px',
													color: '#4F4F4F',
													overflow: 'hidden',
													textOverflow: 'ellipsis',
													'&:hover': {
														overflow: 'visible',
														whiteSpace: 'normal',
														overflowWrap: 'break-word',
													},
												}}
											>
												{admin}
											</Typography>
										</Box>
									</>
								),
							},
							{
								size: {
									xxlSize: '15%',
									xlSize: '17%',
									lgSize: '16%',
									mdSize: '100px',
									smSize: '100px',
								},
								shouldHaveMinWidth: true,
								divider: true,
								alignment: 'flex-start',
								content: (
									<>
										<Box
											sx={{
												width: '100%',
												maxWidth: '95%',
												display: 'flex',
												flexDirection: 'row',
												overflow: 'hidden',
												whiteSpace: 'nowrap',
												alignItems: 'center',
											}}
										>
											{statusTooltip(status)}
											<Typography
												sx={{
													fontSize: '14px',
													lineHeight: '16px',
													color: '#515170',
													marginLeft: '8px',
													overflow: 'hidden',
													textOverflow: 'ellipsis',
													'&:hover': {
														overflow: 'visible',
														whiteSpace: 'normal',
														overflowWrap: 'break-word',
													},
												}}
											>
												{statusLabel(status)}
											</Typography>
										</Box>
									</>
								),
							},
							{
								size: {
									xxlSize: '14%',
									xlSize: '14%',
									lgSize: '14%',
									mdSize: '130px',
									smSize: '130px',
								},
								shouldHaveMinWidth: true,
								divider: true,
								alignment: 'flex-start',
								content: (
									<>
										<Box
											sx={{
												maxWidth: '95%',
												overflow: 'hidden',
												whiteSpace: 'nowrap',
												display: 'flex',
												flexDirection: 'column',
											}}
										>
											<Typography
												sx={{
													fontSize: '14px',
													lineHeight: '16px',
													color: '#4F4F4F',
													overflow: 'hidden',
													textOverflow: 'ellipsis',
													'&:hover': {
														overflow: 'visible',
														whiteSpace: 'normal',
														overflowWrap: 'break-word',
													},
												}}
											>
												{createdAt}
											</Typography>
										</Box>
									</>
								),
							},
							{
								size: {
									xxlSize: '50px',
									xlSize: '50px',
									lgSize: '50px',
									mdSize: '50px',
									smSize: '50px',
								},
								alignment: 'center',
								shouldHaveMinWidth: true,
								content: renderMenu(alias, status, actions, true),
							},
						],
						mobileItems: {
							firstRow: [
								{
									content: (
										<>
											<Box
												sx={{
													maxWidth: '95%',
													display: 'flex',
													whiteSpace: 'nowrap',
													flexDirection: 'column',
												}}
												onClick={() => handleViewAction(alias, status)}
											>
												<Typography
													sx={{
														fontSize: '14px',
														lineHeight: '16px',
														color: '#515170',
														overflow: 'hidden',
														textOverflow: 'ellipsis',
														'&:hover': {
															cursor: 'pointer',
															color: '#470D82',
														},
													}}
												>
													{organisationName}
												</Typography>
											</Box>
										</>
									),
								},
								{
									content: (
										<>
											<Box
												sx={{
													maxWidth: '95%',
													display: 'flex',
													whiteSpace: 'nowrap',
													flexDirection: 'column',
												}}
											>
												<Typography
													sx={{
														fontSize: '14px',
														lineHeight: '16px',
														color: '#515170',
														overflow: 'hidden',
														textOverflow: 'ellipsis',
													}}
												>
													{alias}
												</Typography>
											</Box>
										</>
									),
								},
								{
									disappearanceBreakPoint: {
										xs: true,
									},
									content: (
										<>
											<Box
												sx={{
													maxWidth: '95%',
													display: 'flex',
													flexDirection: 'row',
													overflow: 'hidden',
													whiteSpace: 'nowrap',
													alignItems: 'center',
												}}
											>
												{renaderIcon(product.code)}
												<Typography
													sx={{
														fontSize: '14px',
														lineHeight: '16px',
														color: '#515170',
														marginLeft: '8px',
														overflow: 'hidden',
														textOverflow: 'ellipsis',
													}}
												>
													{product.name}
												</Typography>
											</Box>
										</>
									),
								},
							],
							secondRow: [
								{
									content: (
										<>
											<Box
												sx={{
													maxWidth: '95%',
													display: 'flex',
													flexDirection: 'row',
													overflow: 'hidden',
													whiteSpace: 'nowrap',
													alignItems: 'center',
												}}
											>
												{statusTooltip(status)}
												<Typography
													sx={{
														fontSize: '14px',
														lineHeight: '16px',
														color: '#515170',
														marginLeft: '8px',
														overflow: 'hidden',
														textOverflow: 'ellipsis',
													}}
												>
													{statusLabel(status)}
												</Typography>
											</Box>
										</>
									),
								},
								{
									disappearanceBreakPoint: {
										xs: true,
									},
									content: (
										<>
											<Box
												sx={{
													maxWidth: '95%',
													display: 'flex',
													whiteSpace: 'nowrap',
													flexDirection: 'column',
												}}
											>
												<Typography
													sx={{
														fontSize: '14px',
														lineHeight: '16px',
														color: '#515170',
														overflow: 'hidden',
														textOverflow: 'ellipsis',
													}}
												>
													{admin}
												</Typography>
											</Box>
										</>
									),
								},
								{
									disappearanceBreakPoint: {
										xs: true,
									},
									content: (
										<>
											<Box
												sx={{
													maxWidth: '95%',
													display: 'flex',
													whiteSpace: 'nowrap',
													flexDirection: 'column',
												}}
											>
												<Typography
													sx={{
														fontSize: '14px',
														lineHeight: '16px',
														color: '#515170',
														overflow: 'hidden',
														textOverflow: 'ellipsis',
													}}
												>
													{createdAt}
												</Typography>
											</Box>
										</>
									),
								},
							],
							action: { content: renderMenu(alias, status, actions) },
						},
					};
				}
			);
		}
		const arr = myArr?.map((item, idx) => {
			return {
				id: idx,
				items: [
					{
						size: {
							xxlSize: '22%',
							xlSize: '15%',
							lgSize: '16%',
							mdSize: '200px',
							smSize: '200px',
						},
						shouldHaveMinWidth: true,
						alignment: 'flex-start',
						content: (
							<Box display="flex" width={'100%'}>
								{idx % 2 === 0 ? (
									<Skeleton variant="rectangular" sx={{ width: '80%', height: '16px' }} />
								) : (
									<Skeleton variant="rectangular" sx={{ width: '60%', height: '16px' }} />
								)}
							</Box>
						),
					},
					{
						size: {
							xxlSize: '15%',
							xlSize: '15%',
							lgSize: '15%',
							mdSize: '126px',
							smSize: '126px',
						},
						alignment: 'flex-start',
						shouldHaveMinWidth: true,
						divider: true,
						content: (
							<Box display="flex" width={'100%'}>
								{idx % 2 === 0 ? (
									<Skeleton variant="rectangular" sx={{ width: '80%', height: '16px' }} />
								) : (
									<Skeleton variant="rectangular" sx={{ width: '60%', height: '16px' }} />
								)}
							</Box>
						),
					},
					{
						size: {
							xxlSize: '12%',
							xlSize: '13%',
							lgSize: '15%',
							mdSize: '165px',
							smSize: '165px',
						},
						alignment: 'flex-start',
						shouldHaveMinWidth: true,
						divider: true,
						content: (
							<Box display="flex" width={'100%'}>
								<Skeleton sx={{ mr: '16px' }} variant="circular" width={16} height={16} />{' '}
								<Skeleton variant="rectangular" sx={{ width: '80%', height: '16px' }} />
							</Box>
						),
					},
					{
						size: {
							xxlSize: '20%',
							xlSize: '23%',
							lgSize: '21%',
							mdSize: '130px',
							smSize: '130px',
						},
						alignment: 'flex-start',
						shouldHaveMinWidth: true,
						divider: true,
						content: (
							<Box display="flex" width={'100%'}>
								{idx % 2 === 0 ? (
									<Skeleton variant="rectangular" sx={{ width: '80%', height: '16px' }} />
								) : (
									<Skeleton variant="rectangular" sx={{ width: '60%', height: '16px' }} />
								)}
							</Box>
						),
					},
					{
						size: {
							xxlSize: '15%',
							xlSize: '17%',
							lgSize: '16%',
							mdSize: '100px',
							smSize: '100px',
						},
						shouldHaveMinWidth: true,
						alignment: 'flex-start',
						divider: true,
						content: (
							<Box display="flex" width={'100%'}>
								<Skeleton sx={{ mr: '16px' }} variant="circular" width={16} height={16} />{' '}
								{idx % 2 === 0 ? (
									<Skeleton variant="rectangular" sx={{ width: '80%', height: '16px' }} />
								) : (
									<Skeleton variant="rectangular" sx={{ width: '60%', height: '16px' }} />
								)}
							</Box>
						),
					},
					{
						size: {
							xxlSize: '14%',
							xlSize: '14%',
							lgSize: '14%',
							mdSize: '130px',
							smSize: '130px',
						},
						shouldHaveMinWidth: true,
						alignment: 'flex-start',
						divider: true,
						content: (
							<Box display="flex" width={'100%'}>
								{idx % 2 === 1 ? (
									<Skeleton variant="rectangular" sx={{ width: '80%', height: '16px' }} />
								) : (
									<Skeleton variant="rectangular" sx={{ width: '60%', height: '16px' }} />
								)}
							</Box>
						),
					},
					{
						size: {
							xxlSize: '50px',
							xlSize: '50px',
							lgSize: '50px',
							mdSize: '50px',
							smSize: '50px',
						},
						alignment: 'center',
						shouldHaveMinWidth: true,
						content: (
							<Box display="flex">
								<Skeleton variant="circular" width={16} height={16} />
							</Box>
						),
					},
				],
				mobileItems: {
					firstRow: [
						{
							content: (
								<Box display="flex" width={'100%'} sx={{ padding: '12px 16px' }}>
									<Skeleton variant="rectangular" sx={{ width: '80%', height: '16px' }} />
								</Box>
							),
						},
						{
							content: (
								<Box display="flex" width={'100%'} sx={{ padding: '12px 16px' }}>
									<Skeleton variant="rectangular" sx={{ width: '80%', height: '16px' }} />
								</Box>
							),
						},
						{
							content: (
								<Box display="flex" width={'100%'} sx={{ padding: '12px 16px' }}>
									<Skeleton sx={{ mr: '16px' }} variant="circular" width={16} height={16} />{' '}
									<Skeleton variant="rectangular" sx={{ width: '100%', height: '16px' }} />
								</Box>
							),
						},
					],
					secondRow: [
						{
							content: (
								<Box display="flex" width={'100%'} sx={{ padding: '12px 16px' }}>
									<Skeleton variant="rectangular" sx={{ width: '80%', height: '16px' }} />
								</Box>
							),
						},
						{
							content: (
								<Box display="flex" width={'100%'} sx={{ padding: '12px 16px' }}>
									<Skeleton sx={{ mr: '16px' }} variant="circular" width={16} height={16} />{' '}
									<Skeleton variant="rectangular" sx={{ width: '100%', height: '16px' }} />
								</Box>
							),
						},
						{
							content: (
								<Box display="flex" width={'100%'} sx={{ padding: '12px 16px' }}>
									<Skeleton variant="rectangular" sx={{ width: '80%', height: '16px' }} />
								</Box>
							),
						},
					],
					action: {
						content: (
							<Box display="flex">
								<Skeleton variant="circular" width={16} height={16} />
							</Box>
						),
					},
				},
			};
		});
		return arr;
	};

	return (
		<Box className={classes.root}>
			<List
				headerData={headerData}
				orderBy={orderBy}
				order={order}
				bodyItems={bodyBuilder(loading)}
				showEmptyTable={showEmptyTable}
				rowHeight={'64px'}
				createSortHandler={createSortHandler}
			/>
		</Box>
	);
};

export default TenantList;
