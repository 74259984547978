import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Button, IconButton, Card, Box, CardHeader, CardActions } from '@mui/material';
import kycApiService from '../../service/kycApi.service';
import { setLoading, clearLoading, setNotification } from '../../store/common/actions';
import { _getCountryList, _getProducts } from '../../store/selectors';
import { NOTIFICATION_STATES } from 'shared_components/src/common/constants';
import { AddTenant, ProductPlan } from 'shared_components/src/components/tenants';
import { ConfirmDialog } from 'shared_components/src/components/common';
import { LeftArrowIcon, RightArrowIcon } from 'shared_components/src/common/icons';
import { BIOPASS, INVOICE_PARSER, KYC, VERIFY } from './icons';

const useStyles = makeStyles(() => ({
	wrapper: {
		'& .MuiPaper-root': {
			backgroundColor: 'transparent',
			display: 'flex',

			'& .title': {
				padding: '16px 0',

				'& .MuiCardHeader-title': {
					color: '#343451',
					fontSize: '26px',
					fontWeight: 600,
					lineHeight: '32px',
				},
			},

			'& .btn-back': {
				padding: 0,

				'&:hover': {
					color: '#8C07DD',
					background: `#F8EFFB`,

					'& svg': {
						fill: '#8C07DD',
					},
				},
				'&:active': {
					color: '#5D0DA1',
					background: '#F3E2F8',

					'& svg': {
						fill: '#5D0DA1',
					},
				},
			},
		},

		'& .tab-buttons': {
			display: 'flex',
			alignItems: 'center',
			gap: '16px',

			'@media screen and (max-width: 700px)': {
				display: 'none',
			},

			'& button': {
				color: '#470D82',
				borderRadius: '6px',
				textTransform: 'none',
				fontSize: '16px',
				lineHeight: '32px',

				'&:hover': {
					color: '#8C07DD',
					background: `#F8EFFB`,
				},
				'&:active': {
					color: '#5D0DA1',
					background: '#F3E2F8',
				},
			},

			'& .Mui-disabled': {
				color: '#9191AC',
			},
		},
	},
}));

const AddTenantPage = () => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const navigate = useNavigate();
	const countryList = _getCountryList();
	const productList = _getProducts();

	const [defaultRegion, setDefaultRegion] = useState({} as any);
	const [tabValue, setTabValue] = useState(1);
	const [regionAlias, setRegionAlias] = useState('');
	const [productPlanData, setProductPlanData] = useState({} as any);
	const [KYCProduct, setKYCProduct] = useState({} as any);
	const [cancelTenantCreationModal, setCancelTenantCreationModal] = useState(false);

	useEffect(() => {
		const product = productList
			.filter((product) => !['ADMIN', 'INVOICE-PARSER'].includes(product.code))
			?.map((product) => ({ ...product, icon: getProductIcon(product.code) }));
		setKYCProduct(product);
	}, [productList]);

	useEffect(() => {
		const { host = '' } = window.location;
		const alias = host?.split('.')[1] || 'au';
		setRegionAlias(alias);
	}, []);

	const tenantData = [
		{
			key: 'organisationName',
			type: 'text',
			label: 'Tenant Name',
			required: true,
			inputProps: {
				pattern: /^[a-zA-Z0-9\s]+$/i,
			},
		},
		{
			key: 'region',
			type: 'chip',
			label: 'Region',
			required: true,
		},
		{
			key: 'country',
			type: 'country',
			label: 'Country',
			required: true,
		},
		{
			key: 'alias',
			type: 'text',
			label: 'Tenant Alias',
			required: true,
			inputProps: {
				minLength: 3,
				maxLength: 25,
				noSpecialCharacters: true,
				lowerCase: true,
			},
		},
	];
	const ownerData = [
		{
			key: 'givenName',
			type: 'text',
			label: 'First Name',
			required: true,
			inputProps: {
				pattern: /^[a-zA-Z0-9\s]+$/i,
			},
		},
		{
			key: 'familyName',
			type: 'text',
			label: 'Last Name',
			required: true,
			inputProps: {
				pattern: /^[a-zA-Z0-9\s]+$/i,
			},
		},
		{
			key: 'email',
			type: 'email',
			label: 'Email',
			required: true,
		},
		{
			key: 'mobile',
			type: 'phone',
			label: 'Mobile',
			required: false,
		},
	];

	const getProductIcon = (code) => {
		switch (code) {
			case 'KYC':
				return <KYC />;
			case 'BIOPASS':
				return <BIOPASS />;
			case 'INVOICE-PARSER':
				return <INVOICE_PARSER />;
			case 'VERIFY':
				return <VERIFY />;
			default:
				return;
		}
	};

	const getRegions = (productCode) => {
		dispatch(setLoading());
		return new Promise((resolve) => {
			kycApiService
				.getRegions(productCode)
				.then((res: any) => {
					if (res?.regions?.length > 0) {
						const region = res?.regions.find((region) => region.alias === regionAlias);
						setDefaultRegion(region);
					} else {
						setDefaultRegion({});
					}
				})
				.catch((err: any) => {
					setDefaultRegion({});
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const displayNotificatoinSuccess = (msg: string) => {
		dispatch(
			setNotification({
				message: msg,
				type: NOTIFICATION_STATES.success,
			})
		);
	};

	const displayNotificatoinError = (msg: string) => {
		dispatch(
			setNotification({
				message: msg,
				type: NOTIFICATION_STATES.error,
			})
		);
	};

	const createNewTenant = (formData) => {
		const request = {
			alias: formData.alias,
			organisationName: formData.organisationName,
			country: {
				name: formData.country.name,
				code: formData.country.code,
			},
			region: {
				name: defaultRegion.name,
				code: defaultRegion.code,
			},
			owner: {
				givenName: formData.givenName,
				familyName: formData.familyName,
				name: formData.name,
				email: formData.email,
				phoneNumber: formData.mobile,
				address: formData.address,
			},
			// Includes product, plan and currency
			...productPlanData,
		};
		dispatch(setLoading());
		return new Promise(async (resolve, reject) => {
			kycApiService
				.createTenant(request)
				.then((res: any) => {
					dispatch(clearLoading());
					if (res.error) {
						displayNotificatoinError(res.error.message);
					} else {
						resolve({ status: 'success' });
						navigate('/tenant-management');
						displayNotificatoinSuccess('New tenant has successfully been created.');
					}
				})
				.catch((err: any) => {
					dispatch(clearLoading());
					reject({ status: 'error' });
					displayNotificatoinError('Tenant creation failed.');
				});
		});
	};

	const cancelTenantCreation = () => {
		navigate('/tenant-management');
	};

	const handleTabChange = (newValue: number) => {
		setTabValue(newValue);
	};

	const handleProductPageSubmit = ({ product, plan, currency }) => {
		setProductPlanData({
			product: {
				code: product.code,
				name: product.name,
				plan: plan.code,
			},
			currency: currency.code,
		});
		setTabValue(2);
		getRegions(product.code);
	};

	return (
		<Box className={classes.wrapper}>
			<Card elevation={0}>
				<CardActions>
					<IconButton
						className={'btn-back'}
						aria-label="go back"
						onClick={() => setCancelTenantCreationModal(true)}
					>
						<LeftArrowIcon width={36} height={36} />
					</IconButton>
				</CardActions>
				<CardHeader title="Adding New Tenant" className={'title'} />
			</Card>

			<div>
				<div className={'tab-buttons'}>
					<Button onClick={() => handleTabChange(1)}>Select Plan and Product</Button>
					<RightArrowIcon />
					<Button disabled={tabValue !== 2} onClick={() => handleTabChange(2)}>
						Add Information
					</Button>
				</div>
				{tabValue === 1 ? (
					<ProductPlan
						productPlanData={productPlanData}
						products={KYCProduct}
						handleSubmit={handleProductPageSubmit}
					/>
				) : (
					<AddTenant
						defaultRegion={defaultRegion}
						countries={countryList}
						ownerData={ownerData}
						tenantData={tenantData}
						setCancelTenantCreationModal={setCancelTenantCreationModal}
						handleTenantCreation={createNewTenant}
					/>
				)}
			</div>

			<ConfirmDialog
				title="Are you sure you want to cancel the tenant creation?"
				open={cancelTenantCreationModal}
				setOpen={setCancelTenantCreationModal}
				onConfirm={cancelTenantCreation}
				cancelLabel="No"
				confirmLabel="Yes"
			></ConfirmDialog>
		</Box>
	);
};

export default AddTenantPage;
