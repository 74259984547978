import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Dialog, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";

import {
  TruuthAccordion,
  TruuthAccordionSummary,
  TruuthAccordionDetails,
  TruuthButton,
  TruuthChip,
} from "shared_components/src/components/common";
import {
  VERIFIER_JOB_STATUS,
  VERIFIER_JOB_RESULT_STATUS_V2 as VERIFIER_JOB_RESULT_STATUS,
  VERIFIER_JOB_RESULT_STATUS_REASON,
} from "shared_components/src/service/models/manual-verification";
import { DownArrowIcon, CloseIcon } from "shared_components/src/common/icons";
import { STATUS } from "shared_components/src/service/models/verification";
import MVCheckResult from "shared_components/src/components/common/MVCheckResult";
import Status from "shared_components/src/components/common/Status";

import { _getVerifierJobState } from "../../store/selectors";
import MVImagesV2 from "./MVImagesV2";
import MVDocumentData from "./MVDocumentData";
import {
  DocumentInformation,
  Escalate,
  Pass,
  Fail,
  LinkingDocument,
  Reason,
} from "./svg";
import { REASONS } from "./constants/Reasons";
import KycApiService from "../../service/kycApi.service";

const DialogBox = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    boxShadow: "1px 1px 15px 0px #20203A14",
    borderRadius: "5px",
  },
}));

/**
 * Types
 */

/**
 * IProps
 */
interface IProps {
  completeJob: (data: any) => void;
  escalateJob: (jobId: string) => void;
}

function MVNameMatchV2({ completeJob, escalateJob }: IProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedButton, setSelectedButton] =
    useState<VERIFIER_JOB_RESULT_STATUS | null>(null);
  const [documentMetaData, setDocumentMetadata] = useState<any>({});
  const job = _getVerifierJobState();
  const jobDetailInput = job?.jobDetail?.input;

  const documents = jobDetailInput?.documents || [];
  const linkingDocuments = documents.filter(
    (doc) => doc.documentCategory === "LINKING"
  );
  const primaryDocuments = documents.filter(
    (doc) => doc.documentCategory !== "LINKING"
  );

  useEffect(() => {
    function getDocumentMetaData(doc) {
      if (doc.documentType && doc.documentClassificationCode) {
        KycApiService.getDocumentFields(
          doc.documentType,
          doc.documentClassificationCode
        )
          .then((res) => {
            setDocumentMetadata((prev) => ({
              ...prev,
              [doc.documentClassificationCode]: res.fields,
            }));
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }

    jobDetailInput?.documents.forEach((doc) => {
      getDocumentMetaData(doc);
    });
  }, [jobDetailInput]);

  const handleOpenModal = (buttonType: VERIFIER_JOB_RESULT_STATUS) => {
    setSelectedButton(buttonType);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedButton(null);
  };

  const handleEscalateJob = () => {
    if (!job?.jobId) {
      return;
    }

    escalateJob(job.jobId);
  };

  const handleCompleteJob = (
    status: VERIFIER_JOB_RESULT_STATUS,
    statusReason?: string
  ) => {
    if (!job?.jobId) {
      return;
    }

    const data: any = {
      jobId: job?.jobId,
      status: VERIFIER_JOB_STATUS.completed,
      jobDetail: {
        input: jobDetailInput || null,
        output: {},
      },
    };

    switch (status) {
      case VERIFIER_JOB_RESULT_STATUS.passed:
        data.jobDetail.output.status = VERIFIER_JOB_RESULT_STATUS.passed;
        data.jobDetail.output.statusReason =
          VERIFIER_JOB_RESULT_STATUS_REASON.matched;
        /* TODO: NEED TO ADD SUPPORTED DOCUMENT ID */
        break;
      case VERIFIER_JOB_RESULT_STATUS.failed:
        data.jobDetail.output.status = VERIFIER_JOB_RESULT_STATUS.failed;
        data.jobDetail.output.statusReason = statusReason
          ? statusReason
          : VERIFIER_JOB_RESULT_STATUS_REASON.notMatched;
        /* TODO: NEED TO ADD SUPPORTED DOCUMENT ID */
        break;

      default:
        break;
    }

    completeJob(data);
  };

  const renderReasons = () => {
    if (!selectedButton) {
      return null;
    }

    return REASONS["NAME_MATCH"][selectedButton].map((reason, index) => (
      <div key={`${index}${reason.key}`} style={{ margin: "0 0 16px 24px" }}>
        <TruuthChip
          code={reason.key}
          label={reason.text}
          selected={false}
          isMultipleSelection={false}
          variant="outlined"
          background="color"
          onClick={() => handleCompleteJob(selectedButton, reason.key)}
        />
      </div>
    ));
  };

  const renderLinkingDocuments = () => {
    return linkingDocuments.length > 0 ? (
      <Box
        sx={{
          borderBottom: "1px solid #EDEDF3",
          borderTop: "1px solid #EDEDF3",
          marginBottom: "2rem",
          paddingTop: "2rem",
        }}
      >
        <Box display="flex" alignItems="center">
          <LinkingDocument />
          <span
            style={{
              fontFamily: "Inter, sans-serif",
              fontWeight: 600,
              fontSize: "1rem",
              lineHeight: "1.5rem",
              color: "#515170",
              marginLeft: "10px",
            }}
          >
            Linking Documents
          </span>
        </Box>

        {linkingDocuments.map((doc) => {
          return (
            <TruuthAccordion
              sx={{ marginTop: "1.5rem" }}
              key={doc.documentClassificationCode}
            >
              <TruuthAccordionSummary
                id="document-header"
                expandIcon={<DownArrowIcon />}
                aria-controls="document-information"
                sx={{ padding: "8px 32px" }}
              >
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center">
                    <Typography
                      style={{
                        fontFamily: "Inter, sans-serif",
                        fontWeight: 600,
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#343451",
                      }}
                    >
                      {doc?.documentDisplayName || ""}
                    </Typography>
                    {doc?.documentClassificationCode && (
                      <span
                        style={{
                          width: "8px",
                          height: "8px",
                          borderRadius: "100%",
                          backgroundColor: "#D0D0DD",
                          display: "inline-block",
                          marginLeft: "16px",
                        }}
                      ></span>
                    )}
                    <Typography
                      style={{
                        fontFamily: "Inter, sans-serif",
                        fontWeight: 500,
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#515170",
                        marginLeft: "16px",
                      }}
                    >
                      {doc?.documentClassificationCode || ""}
                    </Typography>
                  </Box>
                  <Typography
                    style={{
                      marginTop: "4px",
                      fontWeight: 400,
                      fontFamily: "Inter, sans-serif",
                      lineHeight: "24px",
                      fontSize: "14px",
                      color: "#515170",
                      display: "block",
                    }}
                  >
                    {doc?.documentDescription || ""}
                  </Typography>
                </Box>
              </TruuthAccordionSummary>

              <TruuthAccordionDetails sx={{ padding: "24px 24px 24px 32px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <MVImagesV2
                      faceImage={jobDetailInput?.faceImage || ""}
                      documentImages={doc?.documentImages || []}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MVDocumentData
                      fieldsData={
                        documentMetaData[doc.documentClassificationCode]
                      }
                      OCRData={JSON.parse(JSON.stringify(doc?.textract || []))}
                      isDataEditable={false}
                    />
                  </Grid>
                </Grid>
              </TruuthAccordionDetails>
            </TruuthAccordion>
          );
        })}
      </Box>
    ) : null;
  };

  const renderDialogStatus = (status: STATUS | null) => {
    if (!status) {
      return;
    }

    let color = "";
    let stat = "";

    switch (status) {
      case "PASS":
      case "PASSED":
        (color = "#0A7552"), (stat = "Pass");
        break;
      case "FAIL":
      case "FAILED":
        (color = "#C6323B"), (stat = "Fail");
        break;
      case "WARNING":
        (color = "#97640C"), (stat = "Warning");
        break;
    }

    return (
      <span
        style={{
          color,
          fontFamily: "Inter, sans-serif",
          fontWeight: 600,
          fontSize: "12px",
          lineHeight: "24px",
          marginLeft: "8px",
        }}
      >
        {stat}
      </span>
    );
  };

  return (
    <Box sx={{ marginTop: "24px" }}>
      {renderLinkingDocuments()}

      <Box display="flex" alignItems="center">
        <DocumentInformation />
        <span
          style={{
            fontFamily: "Inter, sans-serif",
            fontWeight: 600,
            fontSize: "1rem",
            lineHeight: "1.5rem",
            color: "#515170",
            marginLeft: "10px",
          }}
        >
          Document Information
        </span>
      </Box>

      {primaryDocuments.map((doc) => {
        return (
          <TruuthAccordion
            sx={{ marginTop: "1.5rem" }}
            key={doc.documentClassificationCode}
          >
            <TruuthAccordionSummary
              id="document-header"
              expandIcon={<DownArrowIcon />}
              aria-controls="document-information"
              sx={{ padding: "8px 32px" }}
            >
              <Box display="flex" flexDirection="column">
                <Box display="flex" alignItems="center">
                  <Typography
                    style={{
                      fontFamily: "Inter, sans-serif",
                      fontWeight: 600,
                      fontSize: "14px",
                      lineHeight: "24px",
                      color: "#343451",
                    }}
                  >
                    {doc?.documentDisplayName || ""}
                  </Typography>
                  {doc?.documentClassificationCode && (
                    <span
                      style={{
                        width: "8px",
                        height: "8px",
                        borderRadius: "100%",
                        backgroundColor: "#D0D0DD",
                        display: "inline-block",
                        marginLeft: "16px",
                      }}
                    ></span>
                  )}
                  <Typography
                    style={{
                      fontFamily: "Inter, sans-serif",
                      fontWeight: 500,
                      fontSize: "14px",
                      lineHeight: "24px",
                      color: "#515170",
                      marginLeft: "16px",
                    }}
                  >
                    {doc?.documentClassificationCode || ""}
                  </Typography>
                </Box>
                <Typography
                  style={{
                    marginTop: "4px",
                    fontWeight: 400,
                    fontFamily: "Inter, sans-serif",
                    lineHeight: "24px",
                    fontSize: "14px",
                    color: "#515170",
                    display: "block",
                  }}
                >
                  {doc?.documentDescription || ""}
                </Typography>
              </Box>
            </TruuthAccordionSummary>

            <TruuthAccordionDetails sx={{ padding: "24px 24px 24px 32px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <MVImagesV2
                    // faceImage={jobDetailInput?.faceImage || ''}
                    documentImages={doc?.documentImages || []}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MVDocumentData
                    fieldsData={
                      documentMetaData[doc.documentClassificationCode]
                    }
                    OCRData={JSON.parse(JSON.stringify(doc?.textract || []))}
                    isDataEditable={false}
                  />
                </Grid>
              </Grid>
            </TruuthAccordionDetails>
          </TruuthAccordion>
        );
      })}

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          paddingTop: "24px",
          borderTop: "1px solid #EDEDF3",
        }}
      >
        <TruuthButton
          buttontype="tertiary"
          size="medium"
          startIcon={<Escalate />}
          onClick={handleEscalateJob}
          disabled={job?.escalated || false}
        >
          Escalate
        </TruuthButton>
        <Box>
          <TruuthButton
            buttontype="tertiary"
            size="medium"
            sx={{ marginLeft: "16px" }}
            startIcon={<Pass />}
            onClick={() => handleCompleteJob(VERIFIER_JOB_RESULT_STATUS.passed)}
          >
            Pass
          </TruuthButton>
          <TruuthButton
            buttontype="tertiary"
            size="medium"
            sx={{ marginLeft: "16px" }}
            startIcon={<Fail />}
            onClick={() => handleOpenModal(VERIFIER_JOB_RESULT_STATUS.failed)}
          >
            Fail
          </TruuthButton>
        </Box>
      </Box>

      <DialogBox open={modalOpen} onClose={closeModal} maxWidth="md">
        <div
          style={{
            display: "flex",
            backgroundColor: "#F0F0F4",
            padding: "16px 24px",
            borderBottom: "1px solid #DCDCE5",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              width: "100%",
              textAlign: "center",
              fontFamily: "Inter, sans-serif",
              fontSize: "18px",
              fontWeight: 600,
              lineHeight: "24px",
              color: "#20203A",
            }}
          >
            Submit Job
          </Typography>
          <IconButton
            aria-label="close"
            onClick={closeModal}
            style={{ padding: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <Box
          sx={{
            padding: "16px 0",
            borderBottom: "1px solid #DCDCE5",
            margin: "0 24px",
          }}
        >
          <Typography
            style={{
              fontFamily: "Inter, sans-serif",
              fontSize: "16px",
              lineHeight: "32px",
              color: "#515170",
              fontWeight: 400,
              display: "flex",
              alignItems: "center",
            }}
          >
            Job {job?.jobId}
            <b style={{ margin: "0 4px" }}>Name Match Check</b>
            is selected as
            <Status
              status={selectedButton || "PASS"}
              height={16}
              width={16}
            />{" "}
            {renderDialogStatus(selectedButton)}
          </Typography>
          <Typography
            style={{
              fontFamily: "Inter, sans-serif",
              fontSize: "16px",
              lineHeight: "32px",
              color: "#515170",
              fontWeight: 400,
            }}
          >
            Please select a reason to submit this job.
          </Typography>
          <Typography
            style={{
              fontFamily: "Inter, sans-serif",
              fontSize: "16px",
              lineHeight: "32px",
              color: "#515170",
              fontWeight: 400,
            }}
          >
            After selecting a reason, this job will be automatically submitted.
          </Typography>
        </Box>
        <Box sx={{ padding: "24px" }}>
          <Box display="flex" alignItems="center" sx={{ marginBottom: "16px" }}>
            <Reason />
            <Typography
              style={{
                fontFamily: "Inter, sans-serif",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#515170",
                fontWeight: 600,
                marginLeft: "10px",
              }}
            >
              Reason
            </Typography>
          </Box>
          {renderReasons()}
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          sx={{
            padding: "16px 24px",
            borderTop: "1px solid #EDEDF3",
          }}
        >
          <TruuthButton
            buttontype="tertiary"
            size="medium"
            onClick={closeModal}
          >
            Cancel
          </TruuthButton>
        </Box>
      </DialogBox>
    </Box>
  );
}

export default MVNameMatchV2;
