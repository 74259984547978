import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Dialog, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { isEmpty } from 'lodash';
import moment from 'moment';

import {
	TruuthAccordion,
	TruuthAccordionSummary,
	TruuthAccordionDetails,
	TruuthButton,
	TruuthChip,
} from 'shared_components/src/components/common';
import {
	VERIFIER_JOB_STATUS,
	VERIFIER_JOB_RESULT_STATUS_V2 as VERIFIER_JOB_RESULT_STATUS,
	VERIFIER_JOB_RESULT_STATUS_REASON,
} from 'shared_components/src/service/models/manual-verification';
import { DownArrowIcon, CloseIcon } from 'shared_components/src/common/icons';
import { STATUS } from 'shared_components/src/service/models/verification';
import MVCheckResult from 'shared_components/src/components/common/MVCheckResult';
import Status from 'shared_components/src/components/common/Status';

import KycApiService from '../../service/kycApi.service';
import { _getVerifierJobState } from '../../store/selectors';
import MVImagesV2 from './MVImagesV2';
import MVDocumentData from './MVDocumentData';
import { DocumentInformation, LinkingDocument, Escalate, Pass, Fail, Reason } from './svg';
import { REASONS } from './constants/Reasons';

const DialogBox = styled(Dialog)(() => ({
	'& .MuiDialog-paper': {
		boxShadow: '1px 1px 15px 0px #20203A14',
		borderRadius: '5px',
	},
}));

/**
 * Types
 */

/**
 * IProps
 */
interface IProps {
	completeJob: (data: any) => void;
	escalateJob: (jobId: string) => void;
}

function MVOcrV2({ completeJob, escalateJob }: IProps) {
	const [modalOpen, setModalOpen] = useState(false);
	const [selectedButton, setSelectedButton] = useState<VERIFIER_JOB_RESULT_STATUS | null>(null);
	const job = _getVerifierJobState();
	const jobDetailInput = job?.jobDetail?.input;

	const [fieldsData, setFieldsData] = useState([] as any);
	const [documentData, setDocumentData] = useState([] as any);
	const [OCRData, setOCRData] = useState([] as any);
	const [invalidFields, setInvalidFields] = useState({} as any);
	const [dateFormat, setDateFormat] = useState('' as any);

	const handleOpenModal = (buttonType: VERIFIER_JOB_RESULT_STATUS) => {
		setSelectedButton(buttonType);
		setModalOpen(true);
	};

	const closeModal = () => {
		setModalOpen(false);
		setSelectedButton(null);
	};

	const handleEscalateJob = () => {
		if (!job?.jobId) {
			return;
		}

		escalateJob(job.jobId);
	};

	useEffect(() => {
		const documentData = jobDetailInput?.documents[0] || [];
		if (documentData.documentType && documentData.documentClassificationCode) {
			KycApiService.getDocumentFields(
				documentData.documentType,
				documentData.documentClassificationCode
			)
				.then((res) => {
					setFieldsData(res.fields);
				})
				.catch((err) => {
					console.error(err);
				});
		}
	}, [jobDetailInput]);

	useEffect(() => {
		console.log('job', job);
		console.log('fieldsData', fieldsData);
		console.log('jobDetailInput', jobDetailInput);

		if (!isEmpty(jobDetailInput) && !isEmpty(fieldsData)) {
			const documentData = jobDetailInput?.documents[0] || [];
			setOCRData(JSON.parse(JSON.stringify(documentData?.textract || [])));
			const data = JSON.parse(JSON.stringify(documentData?.textract || []));
			data.forEach((element) => {
				element.originalValue = element.value;
				fieldsData.map((field) => {
					if (
						field.type === 'date' &&
						field.key === element.key &&
						field.promptForOptionalDay === true
					) {
						element.value = '';
					}
				});
			});

			console.log('data', data);
			setDocumentData(data);
		}
	}, [fieldsData, jobDetailInput]);

	const updateDocumentData = (field, value) => {
		setDocumentData((prev) => {
			let updated = false;
			const data = prev.map((element) => {
				if (field.key === element.key) {
					element.originalValue = OCRData.find((d) => d.key === field.key)?.value;
					element.value = value;
					updated = true;
				}
				return element;
			});
			if (!updated) {
				data.push({
					key: field.key,
					value: value,
					originalValue: OCRData.find((d) => d.key === field.key)?.value,
				});
			}
			return data;
		});
	};

	const validate = () => {
		let validationChain = {};
		fieldsData.forEach((field) => {
			const data = documentData.find((data) => data.key === field.key)?.value || '';

			if (field.mandatory && isEmpty(data)) {
				validationChain[field.key] = `${field.name} is required`;
			} else if (field.type === 'input') {
				if (!isEmpty(data) && data.length < field.minLength) {
					validationChain[
						field.key
					] = `${field.name} must be at least ${field.minLength} characters`;
				} else if (data.length > field.maxLength) {
					validationChain[
						field.key
					] = `${field.name} must be at most ${field.maxLength} characters`;
				}
			} else if (field.type === 'select' && isEmpty(field.items.find((item) => item.value === data))) {
				validationChain[field.key] = `Invalid ${field.name} value`;
			} else if (
				field.type === 'date' &&
				!isEmpty(data) &&
				(!isEmpty(dateFormat)
					? !moment(data, dateFormat, true).isValid()
					: !moment(data, field.format, true).isValid())
			) {
				validationChain[field.key] = `Invalid ${field.name}`;
			}
		});

		setInvalidFields(validationChain);

		if (isEmpty(validationChain)) {
			// Please proceed to submit OCR to API, use documentData object
			handleCompleteJob(VERIFIER_JOB_RESULT_STATUS.passed);
		}
	};

	const renderReasons = () => {
		if (!selectedButton) {
			return null;
		}

		return REASONS['OCR_VERIFICATION'][selectedButton].map((reason, index) => (
			<div key={`${index}${reason.key}`} style={{ margin: '0 0 16px 24px' }}>
				<TruuthChip
					code={reason.key}
					label={reason.text}
					selected={false}
					isMultipleSelection={false}
					variant='outlined'
					background='color'
					onClick={() => handleCompleteJob(selectedButton, reason.key)}
				/>
			</div>
		));
	};

	const handleCompleteJob = (status: VERIFIER_JOB_RESULT_STATUS, statusReason?: string) => {
		if (!job?.jobId) {
			return;
		}

		const data: any = {
			jobId: job?.jobId,
			status: VERIFIER_JOB_STATUS.completed,
			jobDetail: {
				input: jobDetailInput || null,
				output: {},
			},
		};

		switch (status) {
			case VERIFIER_JOB_RESULT_STATUS.passed:
				data.jobDetail.output.status = VERIFIER_JOB_RESULT_STATUS.passed;
				data.jobDetail.output.statusReason = VERIFIER_JOB_RESULT_STATUS_REASON.verified;
				data.jobDetail.output.score = 1;
				data.jobDetail.output.textract = documentData || [];
				data.jobDetail.output.documents = jobDetailInput?.documents || [];
				data.jobDetail.output.identityOwner = jobDetailInput?.identityOwner || {};
				break;
			case VERIFIER_JOB_RESULT_STATUS.failed:
				data.jobDetail.output.status = VERIFIER_JOB_RESULT_STATUS.failed;
				data.jobDetail.output.statusReason = statusReason
					? statusReason
					: VERIFIER_JOB_RESULT_STATUS_REASON.unreadable;
				data.jobDetail.output.score = 1;
				data.jobDetail.output.textract = documentData || [];
				data.jobDetail.output.documents = jobDetailInput?.documents || [];
				data.jobDetail.output.identityOwner = jobDetailInput?.identityOwner || {};
				break;
			default:
				break;
		}

		completeJob(data);
	};

	const renderDocSvg = () => {
		const primaryDoc = jobDetailInput.documents[0].documentCategory === 'PRIMARY';
		return (
			<>
				{primaryDoc ? <DocumentInformation /> : <LinkingDocument />}
				<span
					style={{
						fontFamily: 'Inter, sans-serif',
						fontWeight: 600,
						fontSize: '1rem',
						lineHeight: '1.5rem',
						color: '#515170',
						marginLeft: '10px',
					}}
				>
					{primaryDoc ? 'Document Information' : 'Linking Documents'}
				</span>
			</>
		);
	};

	const renderDialogStatus = (status: STATUS | null) => {
		if (!status) {
			return;
		}

		let color = '';
		let stat = '';

		switch (status) {
			case 'PASS':
			case 'PASSED':
				(color = '#0A7552'), (stat = 'Pass');
				break;
			case 'FAIL':
			case 'FAILED':
				(color = '#C6323B'), (stat = 'Fail');
				break;
			case 'WARNING':
				(color = '#97640C'), (stat = 'Warning');
				break;
		}

		return (
			<span
				style={{
					color,
					fontFamily: 'Inter, sans-serif',
					fontWeight: 600,
					fontSize: '12px',
					lineHeight: '24px',
					marginLeft: '8px',
				}}
			>
				{stat}
			</span>
		);
	};

	const handleDateFormat = (format) => {
		setDateFormat(format);
	};

	return (
		<Box sx={{ marginTop: '24px' }}>
			<Box display='flex' alignItems='center'>
				{renderDocSvg()}
			</Box>

			<TruuthAccordion key={'doc.documentId'} sx={{ marginTop: '1.5rem' }}>
				<TruuthAccordionSummary
					id='document-header'
					expandIcon={<DownArrowIcon />}
					aria-controls='document-information'
					sx={{ padding: '8px 32px' }}
				>
					<Box display='flex' flexDirection='column'>
						<Box display='flex' alignItems='center'>
							<Typography
								style={{
									fontFamily: 'Inter, sans-serif',
									fontWeight: 600,
									fontSize: '14px',
									lineHeight: '24px',
									color: '#343451',
								}}
							>
								{jobDetailInput?.documents[0]?.documentDisplayName || ''}
							</Typography>
							{jobDetailInput?.documents[0]?.documentClassificationCode && (
								<span
									style={{
										width: '8px',
										height: '8px',
										borderRadius: '100%',
										backgroundColor: '#D0D0DD',
										display: 'inline-block',
										marginLeft: '16px',
									}}
								></span>
							)}
							<Typography
								style={{
									fontFamily: 'Inter, sans-serif',
									fontWeight: 500,
									fontSize: '14px',
									lineHeight: '24px',
									color: '#515170',
									marginLeft: '16px',
								}}
							>
								{jobDetailInput?.documents[0]?.documentClassificationCode || ''}
							</Typography>
						</Box>
						<Typography
							style={{
								marginTop: '4px',
								fontWeight: 400,
								fontFamily: 'Inter, sans-serif',
								lineHeight: '24px',
								fontSize: '14px',
								color: '#515170',
								display: 'block',
							}}
						>
							{jobDetailInput?.documents[0]?.documentDescription || ''}
						</Typography>
					</Box>
				</TruuthAccordionSummary>

				<TruuthAccordionDetails sx={{ padding: '24px 24px 24px 32px' }}>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<MVImagesV2
								faceImage={jobDetailInput?.documents[0]?.faceImage || ''}
								documentImages={jobDetailInput?.documents[0]?.documentImages || []}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<MVDocumentData
								fieldsData={fieldsData}
								OCRData={OCRData}
								documentData={documentData}
								invalidFields={invalidFields}
								isDataEditable={true}
								handleDateFormat={handleDateFormat}
								setData={updateDocumentData}
							/>
						</Grid>
					</Grid>
				</TruuthAccordionDetails>
			</TruuthAccordion>

			<Box
				display='flex'
				alignItems='center'
				justifyContent='space-between'
				sx={{
					paddingTop: '24px',
					borderTop: '1px solid #EDEDF3',
				}}
			>
				<TruuthButton
					buttontype='tertiary'
					size='medium'
					startIcon={<Escalate />}
					onClick={handleEscalateJob}
					disabled={job?.escalated || false}
				>
					Escalate
				</TruuthButton>
				<Box>
					<TruuthButton
						buttontype='tertiary'
						size='medium'
						sx={{ marginLeft: '16px' }}
						startIcon={<Pass />}
						onClick={() => validate()}
					>
						Pass
					</TruuthButton>
					<TruuthButton
						buttontype='tertiary'
						size='medium'
						sx={{ marginLeft: '16px' }}
						startIcon={<Fail />}
						onClick={() => handleOpenModal(VERIFIER_JOB_RESULT_STATUS.failed)}
					>
						Fail
					</TruuthButton>
				</Box>
			</Box>

			<DialogBox open={modalOpen} onClose={closeModal} maxWidth='md'>
				<div
					style={{
						display: 'flex',
						backgroundColor: '#F0F0F4',
						padding: '16px 24px',
						borderBottom: '1px solid #DCDCE5',
						alignItems: 'center',
					}}
				>
					<Typography
						style={{
							width: '100%',
							textAlign: 'center',
							fontFamily: 'Inter, sans-serif',
							fontSize: '18px',
							fontWeight: 600,
							lineHeight: '24px',
							color: '#20203A',
						}}
					>
						Submit Job
					</Typography>
					<IconButton aria-label='close' onClick={closeModal} style={{ padding: 0 }}>
						<CloseIcon />
					</IconButton>
				</div>
				<Box
					sx={{
						padding: '16px 0',
						borderBottom: '1px solid #DCDCE5',
						margin: '0 24px',
					}}
				>
					<Typography
						style={{
							fontFamily: 'Inter, sans-serif',
							fontSize: '16px',
							lineHeight: '32px',
							color: '#515170',
							fontWeight: 400,
							display: 'flex',
							alignItems: 'center',
						}}
					>
						Job {job?.jobId}
						<b style={{ margin: '0 4px' }}>OCR Check</b>
						is selected as
						<Status status={selectedButton || 'PASS'} height={16} width={16} />{' '}
						{renderDialogStatus(selectedButton)}
					</Typography>
					<Typography
						style={{
							fontFamily: 'Inter, sans-serif',
							fontSize: '16px',
							lineHeight: '32px',
							color: '#515170',
							fontWeight: 400,
						}}
					>
						Please select a reason to submit this job.
					</Typography>
					<Typography
						style={{
							fontFamily: 'Inter, sans-serif',
							fontSize: '16px',
							lineHeight: '32px',
							color: '#515170',
							fontWeight: 400,
						}}
					>
						After selecting a reason, this job will be automatically submitted.
					</Typography>
				</Box>
				<Box sx={{ padding: '24px' }}>
					<Box display='flex' alignItems='center' sx={{ marginBottom: '16px' }}>
						<Reason />
						<Typography
							style={{
								fontFamily: 'Inter, sans-serif',
								fontSize: '16px',
								lineHeight: '24px',
								color: '#515170',
								fontWeight: 600,
								marginLeft: '10px',
							}}
						>
							Reason
						</Typography>
					</Box>
					{renderReasons()}
				</Box>
				<Box
					display='flex'
					alignItems='center'
					justifyContent='flex-end'
					sx={{
						padding: '16px 24px',
						borderTop: '1px solid #EDEDF3',
					}}
				>
					<TruuthButton buttontype='tertiary' size='medium' onClick={closeModal}>
						Cancel
					</TruuthButton>
				</Box>
			</DialogBox>
		</Box>
	);
}

export default MVOcrV2;
