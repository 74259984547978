import React from 'react';

export function Escalate() {
	return (
		<svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M6.99967 1.64732C4.05967 1.64732 1.64634 4.06065 1.64634 7.00065C1.64634 9.94065 4.05967 12.354 6.99967 12.354C9.93967 12.354 12.353 9.94065 12.353 7.00065C12.353 4.06065 9.93967 1.64732 6.99967 1.64732ZM6.99967 0.333984C10.693 0.333984 13.6663 3.30732 13.6663 7.00065C13.6663 10.694 10.693 13.6673 6.99967 13.6673C3.30634 13.6673 0.333008 10.694 0.333008 7.00065C0.333008 3.30732 3.30634 0.333984 6.99967 0.333984ZM8.25301 6.68732L9.66634 8.09398V4.33398H5.90634L7.31301 5.74732L3.99967 9.06732L4.93301 10.0007'
				fill='#70708F'
			/>
		</svg>
	);
}
