export const REASONS = {
	DOCUMENT_AUTHENTICITY: {
		FAIL: [
			{
				key: 'IMAGE_UNREADABLE',
				text: 'Image unreadable',
			},
			{
				key: 'MANUAL_CONFIDENCE_OVERRIDE',
				text: 'Manual confidence override',
			},
		],
		WARNING: [
			{
				key: 'MANUAL_CONFIDENCE_OVERRIDE',
				text: 'Manual confidence override',
			},
		],
		NOT_PERFORMED: [
			{
				key: 'NO_CHECK_AVAILABLE',
				text: 'No check available',
			},
			{
				key: 'CHECK_ERROR',
				text: 'Check error',
			},
		],
	},
	DVS: {
		FAIL: [
			{
				key: 'VALIDATION_ERROR',
				text: 'Document validation error',
			},
			{
				key: 'NOT_VERIFIED',
				text: 'No Match',
			},
		],
		NOT_PERFORMED: [
			{
				key: 'SERVER_ERROR',
				text: 'Service not available',
			},
		],
	},
	FACE_MATCH: {
		FAIL: [
			{
				key: 'IMAGE_UNREADABLE',
				text: 'Image unreadable',
			},
			{
				key: 'MANUAL_CONFIDENCE_OVERRIDE',
				text: 'Manual confidence override',
			},
		],
		WARNING: [
			{
				key: 'IMAGE_POOR_QUALITY',
				text: 'Image poor quality',
			},
			{
				key: 'MANUAL_CONFIDENCE_OVERRIDE',
				text: 'Manual confidence override',
			},
		],
	},
	OCR_VERIFICATION: {
		FAIL: [
			{
				key: 'UNREADABLE',
				text: 'Not readable',
			},
		],
	},
	NAME_MATCH: {
		FAIL: [
			{
				key: 'NOT_READABLE',
				text: 'Not readable',
			},
			{
				key: 'MANUAL_CONFIDENCE_OVERRIDE',
				text: 'Manual confidence override',
			},
		],
	},
};
