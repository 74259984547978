import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Dialog,
  Input,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { isEmpty } from "lodash";

import {
  TruuthAccordion,
  TruuthAccordionSummary,
  TruuthAccordionDetails,
  TruuthButton,
  TruuthChip,
} from "shared_components/src/components/common";
import {
  VERIFIER_JOB_STATUS,
  VERIFIER_JOB_RESULT_STATUS_V2 as VERIFIER_JOB_RESULT_STATUS,
  VERIFIER_JOB_RESULT_STATUS_REASON,
} from "shared_components/src/service/models/manual-verification";
import { DownArrowIcon, CloseIcon } from "shared_components/src/common/icons";

import KycApiService from "../../service/kycApi.service";
import { _getVerifierJobState } from "../../store/selectors";
import MVImagesV2 from "./MVImagesV2";
import MVDocumentData from "./MVDocumentData";
import {
  DocumentInformation,
  Escalate,
  NotPerformed,
  Pass,
  Fail,
  Reason,
} from "./svg";
import { REASONS } from "./constants/Reasons";
import Status from "shared_components/src/components/common/Status";
import { STATUS } from "shared_components/src/service/models/verification";

const DialogBox = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    boxShadow: "1px 1px 15px 0px #20203A14",
    borderRadius: "5px",
  },
}));

interface IProps {
  completeJob: (data: any) => void;
  escalateJob: (jobId: string) => void;
}

const MVDvsV2 = ({ completeJob, escalateJob }: IProps) => {
  const job = _getVerifierJobState();
  const jobDetailInput = job?.jobDetail?.input;
  const jobDetailOutput = job?.jobDetail?.output;

  const [fieldsData, setFieldsData] = useState([] as any);
  const [OCRData, setOCRData] = useState([] as any);
  const [selectedButton, setSelectedButton] =
    useState<VERIFIER_JOB_RESULT_STATUS | null>(null);
  const [passModalOpen, setPassModalOpen] = useState(false);
  const [failModalOpen, setFailModalOpen] = useState(false);
  const [referenceId, setReferenceId] = useState("");

  useEffect(() => {
    const documentData = jobDetailInput?.documents[0] || [];
    setOCRData(JSON.parse(JSON.stringify(documentData?.textract || [])));

    if (documentData.documentType && documentData.documentClassificationCode) {
      KycApiService.getDocumentFields(
        documentData.documentType,
        documentData.documentClassificationCode
      )
        .then((res) => {
          setFieldsData(res.fields);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [jobDetailInput]);

  const handleOpenModal = (buttonType: VERIFIER_JOB_RESULT_STATUS) => {
    setSelectedButton(buttonType);
    if (buttonType === VERIFIER_JOB_RESULT_STATUS.passed) {
      setPassModalOpen(true);
    } else if (buttonType === VERIFIER_JOB_RESULT_STATUS.failed) {
      setFailModalOpen(true);
    } else if (buttonType === VERIFIER_JOB_RESULT_STATUS.notPerformed) {
      setFailModalOpen(true);
    }
  };

  const closePassModal = () => {
    setPassModalOpen(false);
    setSelectedButton(null);
  };

  const closeFailModal = () => {
    setFailModalOpen(false);
    setSelectedButton(null);
  };

  const handleEscalateJob = () => {
    if (!job?.jobId) {
      return;
    }

    escalateJob(job.jobId);
  };

  const renderReasons = () => {
    if (
      !selectedButton ||
      (selectedButton !== VERIFIER_JOB_RESULT_STATUS.failed &&
        selectedButton !== VERIFIER_JOB_RESULT_STATUS.notPerformed)
    ) {
      return null;
    }

    return REASONS["DVS"][selectedButton].map((reason, index) => (
      <div key={`${index}${reason.key}`} style={{ margin: "0 0 16px 24px" }}>
        <TruuthChip
          code={reason.key}
          label={reason.text}
          selected={false}
          isMultipleSelection={false}
          variant="outlined"
          background="color"
          onClick={() => handleCompleteJob(selectedButton, reason.key)}
        />
      </div>
    ));
  };

  const handleCompleteJob = (
    status: VERIFIER_JOB_RESULT_STATUS,
    statusReason?: string
  ) => {
    if (!job?.jobId) {
      return;
    }

    const data: any = {
      jobId: job?.jobId,
      status: VERIFIER_JOB_STATUS.completed,
      jobDetail: {
        input: jobDetailInput || null,
        output: jobDetailOutput,
      },
    };

    switch (status) {
      case VERIFIER_JOB_RESULT_STATUS.passed:
        data.jobDetail.output.status = VERIFIER_JOB_RESULT_STATUS.passed;
        data.jobDetail.output.statusReason =
          VERIFIER_JOB_RESULT_STATUS_REASON.verified;
        data.jobDetail.output.sourceCheckProvider =
          jobDetailInput?.sourceCheckProvider;
        data.jobDetail.output.requestNumber = referenceId;
        data.jobDetail.output.documents = jobDetailInput?.documents || [];
        break;
      case VERIFIER_JOB_RESULT_STATUS.failed:
        data.jobDetail.output.status = VERIFIER_JOB_RESULT_STATUS.failed;
        data.jobDetail.output.statusReason = statusReason
          ? statusReason
          : VERIFIER_JOB_RESULT_STATUS_REASON.notMatched;
        data.jobDetail.output.sourceCheckProvider =
          jobDetailInput?.sourceCheckProvider;
        data.jobDetail.output.requestNumber = jobDetailInput?.requestNumber;
        data.jobDetail.output.documents = jobDetailInput?.documents || [];
        break;
      case VERIFIER_JOB_RESULT_STATUS.notPerformed:
        data.jobDetail.output.status = VERIFIER_JOB_RESULT_STATUS.notPerformed;
        data.jobDetail.output.statusReason = statusReason ? statusReason : "";
        data.jobDetail.output.sourceCheckProvider =
          jobDetailInput?.sourceCheckProvider;
        data.jobDetail.output.requestNumber = jobDetailInput?.requestNumber;
        data.jobDetail.output.documents = jobDetailInput?.documents || [];
        break;
      default:
        break;
    }

    completeJob(data);
  };

  const renderDialogStatus = (status: STATUS | null) => {
    if (!status) {
      return;
    }

    let color = "";
    let stat = "";

    switch (status) {
      case "PASS":
      case "PASSED":
        (color = "#0A7552"), (stat = "Pass");
        break;
      case "FAIL":
      case "FAILED":
        (color = "#C6323B"), (stat = "Fail");
        break;
      case "NOT_PERFORMED":
        (color = "#70708F"), (stat = "Not Performed");
        break;
    }

    return (
      <span
        style={{
          color,
          fontFamily: "Inter, sans-serif",
          fontWeight: 600,
          fontSize: "12px",
          lineHeight: "24px",
          marginLeft: "8px",
        }}
      >
        {stat}
      </span>
    );
  };

  return (
    <Box sx={{ marginTop: "24px" }}>
      <Box display="flex" alignItems="center">
        <DocumentInformation />
        <span
          style={{
            fontFamily: "Inter, sans-serif",
            fontWeight: 600,
            fontSize: "1rem",
            lineHeight: "1.5rem",
            color: "#515170",
            marginLeft: "10px",
          }}
        >
          Document Information
        </span>
      </Box>

      <TruuthAccordion key={"doc.documentId"} sx={{ marginTop: "1.5rem" }}>
        <TruuthAccordionSummary
          id="document-header"
          expandIcon={<DownArrowIcon />}
          aria-controls="document-information"
          sx={{ padding: "8px 32px" }}
        >
          <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="center">
              <Typography
                style={{
                  fontFamily: "Inter, sans-serif",
                  fontWeight: 600,
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#343451",
                }}
              >
                {jobDetailInput?.documents[0]?.documentDisplayName || ""}
              </Typography>
              {jobDetailInput?.documents[0]?.documentClassificationCode && (
                <span
                  style={{
                    width: "8px",
                    height: "8px",
                    borderRadius: "100%",
                    backgroundColor: "#D0D0DD",
                    display: "inline-block",
                    marginLeft: "16px",
                  }}
                ></span>
              )}
              <Typography
                style={{
                  fontFamily: "Inter, sans-serif",
                  fontWeight: 500,
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#515170",
                  marginLeft: "16px",
                }}
              >
                {jobDetailInput?.documents[0]?.documentClassificationCode || ""}
              </Typography>
            </Box>
            <Typography
              style={{
                marginTop: "4px",
                fontWeight: 400,
                fontFamily: "Inter, sans-serif",
                lineHeight: "24px",
                fontSize: "14px",
                color: "#515170",
                display: "block",
              }}
            >
              {jobDetailInput?.documents[0]?.documentDescription || ""}
            </Typography>
          </Box>
        </TruuthAccordionSummary>

        <TruuthAccordionDetails sx={{ padding: "24px 24px 24px 32px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <MVImagesV2
                documentImages={
                  jobDetailInput?.documents[0]?.documentImages || []
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MVDocumentData
                fieldsData={fieldsData}
                OCRData={OCRData}
                isDataEditable={false}
              />
            </Grid>
          </Grid>
        </TruuthAccordionDetails>
      </TruuthAccordion>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          paddingTop: "24px",
          borderTop: "1px solid #EDEDF3",
        }}
      >
        <TruuthButton
          buttontype="tertiary"
          size="medium"
          startIcon={<Escalate />}
          onClick={handleEscalateJob}
          disabled={job?.escalated || false}
        >
          Escalate
        </TruuthButton>
        <Box>
          <TruuthButton
            buttontype="tertiary"
            size="medium"
            sx={{ marginLeft: "16px" }}
            startIcon={<NotPerformed />}
            onClick={() =>
              handleOpenModal(VERIFIER_JOB_RESULT_STATUS.notPerformed)
            }
          >
            Not Performed
          </TruuthButton>
          <TruuthButton
            buttontype="tertiary"
            size="medium"
            sx={{ marginLeft: "16px" }}
            startIcon={<Pass />}
            onClick={() => handleOpenModal(VERIFIER_JOB_RESULT_STATUS.passed)}
          >
            Pass
          </TruuthButton>
          <TruuthButton
            buttontype="tertiary"
            size="medium"
            sx={{ marginLeft: "16px" }}
            startIcon={<Fail />}
            onClick={() => handleOpenModal(VERIFIER_JOB_RESULT_STATUS.failed)}
          >
            Fail
          </TruuthButton>
        </Box>
      </Box>

      <DialogBox open={failModalOpen} onClose={closeFailModal} maxWidth="md">
        <div
          style={{
            display: "flex",
            backgroundColor: "#F0F0F4",
            padding: "16px 24px",
            borderBottom: "1px solid #DCDCE5",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              width: "100%",
              textAlign: "center",
              fontFamily: "Inter, sans-serif",
              fontSize: "18px",
              fontWeight: 600,
              lineHeight: "24px",
              color: "#20203A",
            }}
          >
            Submit Job
          </Typography>
          <IconButton
            aria-label="close"
            onClick={closeFailModal}
            style={{ padding: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <Box
          sx={{
            padding: "16px 0",
            borderBottom: "1px solid #DCDCE5",
            margin: "0 24px",
          }}
        >
          <Typography
            style={{
              fontFamily: "Inter, sans-serif",
              fontSize: "16px",
              lineHeight: "32px",
              color: "#515170",
              fontWeight: 400,
              display: "flex",
              alignItems: "center",
            }}
          >
            Job {job?.jobId}
            <b style={{ margin: "0 4px" }}>Official Source Check</b>
            is selected as
            <Status
              status={selectedButton || "FAIL"}
              height={16}
              width={16}
            />{" "}
            {renderDialogStatus(selectedButton || "FAIL")}
          </Typography>
          <Typography
            style={{
              fontFamily: "Inter, sans-serif",
              fontSize: "16px",
              lineHeight: "32px",
              color: "#515170",
              fontWeight: 400,
            }}
          >
            Please select a reason to submit this job.
          </Typography>
          <Typography
            style={{
              fontFamily: "Inter, sans-serif",
              fontSize: "16px",
              lineHeight: "32px",
              color: "#515170",
              fontWeight: 400,
            }}
          >
            After selecting a reason, this job will be automatically submitted.
          </Typography>
        </Box>
        <Box sx={{ padding: "24px" }}>
          <Box display="flex" alignItems="center" sx={{ marginBottom: "16px" }}>
            <Reason />
            <Typography
              style={{
                fontFamily: "Inter, sans-serif",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#515170",
                fontWeight: 600,
                marginLeft: "10px",
              }}
            >
              Reason
            </Typography>
          </Box>
          {renderReasons()}
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          sx={{
            padding: "16px 24px",
            borderTop: "1px solid #EDEDF3",
          }}
        >
          <TruuthButton
            buttontype="tertiary"
            size="medium"
            onClick={closeFailModal}
          >
            Cancel
          </TruuthButton>
        </Box>
      </DialogBox>

      <DialogBox open={passModalOpen} onClose={closePassModal} maxWidth="md">
        <div
          style={{
            display: "flex",
            backgroundColor: "#F0F0F4",
            padding: "16px 24px",
            borderBottom: "1px solid #DCDCE5",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              width: "100%",
              textAlign: "center",
              fontFamily: "Inter, sans-serif",
              fontSize: "18px",
              fontWeight: 600,
              lineHeight: "24px",
              color: "#20203A",
            }}
          >
            Submit Job
          </Typography>
          <IconButton
            aria-label="close"
            onClick={closePassModal}
            style={{ padding: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <Box
          sx={{
            padding: "16px 0",
            borderBottom: "1px solid #DCDCE5",
            margin: "0 24px",
          }}
        >
          <Typography
            style={{
              fontFamily: "Inter, sans-serif",
              fontSize: "16px",
              lineHeight: "32px",
              color: "#515170",
              fontWeight: 400,
              display: "flex",
              alignItems: "center",
            }}
          >
            Job {job?.jobId}
            <b style={{ margin: "0 4px" }}> Official Source Check </b>
            is selected as
            <Status status={selectedButton || "PASS"} height={16} width={16} />
            {renderDialogStatus(selectedButton || "PASS")}
          </Typography>
          <Typography
            style={{
              fontFamily: "Inter, sans-serif",
              fontSize: "16px",
              lineHeight: "32px",
              color: "#515170",
              fontWeight: 400,
            }}
          >
            Please submit the external service reference number to move to to
            next job.
          </Typography>
        </Box>
        <Box sx={{ padding: "24px" }}>
          <Input
            style={{
              marginRight: "16px",
              padding: "4px 16px",
              minWidth: "336px",
            }}
            id={`reference_id_input`}
            type={"text"}
            placeholder={"External service reference number"}
            value={referenceId}
            onChange={(event) => setReferenceId(event.target.value)}
          ></Input>
          <TruuthButton
            buttontype="secondary"
            size="medium"
            onClick={() => handleCompleteJob(VERIFIER_JOB_RESULT_STATUS.passed)}
            disabled={isEmpty(referenceId) || referenceId.length > 256}
          >
            Submit
          </TruuthButton>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          sx={{
            padding: "16px 24px",
            borderTop: "1px solid #EDEDF3",
          }}
        >
          <TruuthButton
            buttontype="tertiary"
            size="medium"
            onClick={closePassModal}
          >
            Cancel
          </TruuthButton>
        </Box>
      </DialogBox>
    </Box>
  );
};

export default MVDvsV2;
