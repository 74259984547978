import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty, map } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Plan } from 'shared_components/src/components/tenants';
import { TENANT_PLAN, TENANT_STATUS } from 'shared_components/src/service/models/tenant';
import { getImageUrl } from 'shared_components/src/common/utils';
import { getCookie } from 'shared_components/src/service/common.service';
import { updateMenu } from '../../store/menu/actions';
import { _getSelectedTenant, _getTenantPlan, _getProducts } from '../../store/selectors';
import { MENU } from '../../common/routes/menu';
import kycApiService from '../../service/kycApi.service';
import { setTenantPlan } from '../../store/tenant/actions';
import { setLoading, clearLoading } from '../../store/common/actions';

const PlanPage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const featureFlags = useFlags();
	const selectedTenant = _getSelectedTenant();
	const tenantPlan = _getTenantPlan();
	const tenantCookie = useMemo(() => {
		return getCookie('selectedTenant') ? JSON.parse(getCookie('selectedTenant')) : {};
	}, []);
	const [images, setImages] = useState([] as any);
	const [addOns, setAddOns] = useState([] as any);
	const [subscriptionEndDate, setSubscriptionEndDate] = useState('');

	const cancelSubscriptionFlag = useMemo(() => {
		return featureFlags['cancel-subscription'];
	}, [featureFlags]);

	const hideCancelSubscription = useMemo(() => {
		return cancelSubscriptionFlag ? selectedTenant?.status === TENANT_STATUS.CANCELLED : true;
	}, [cancelSubscriptionFlag, selectedTenant]);

	const hideChangePlan = useMemo(() => {
		return selectedTenant?.status === TENANT_STATUS.CANCELLED;
	}, [selectedTenant]);

	useEffect(() => {
		dispatch(updateMenu(MENU.plan));
	}, [dispatch]);

	useEffect(() => {
		const getTenantProducts = (tenant) => {
			dispatch(setLoading());
			return new Promise((resolve) => {
				kycApiService
					.getTenantProducts(tenant?.alias, tenant?.product?.code)
					.then((res: any) => {
						if (res?.features) {
							setAddOnsData(res.features);
						}
					})
					.catch((err: any) => {
						console.log('plan features error', err);
					})
					.finally(() => {
						dispatch(clearLoading());
						return resolve({ status: 'success' });
					});
			});
		};

		if (tenantCookie?.product?.plan !== TENANT_PLAN.PLAN_FREE) {
			getTenantProducts(tenantCookie);
		}
	}, [dispatch, tenantCookie]);

	useEffect(() => {
		const getPlanDetails = (productCode: string, planCode: string) => {
			dispatch(setLoading());
			return new Promise((resolve) => {
				kycApiService
					.getTenantPlanInfo(productCode, planCode)
					.then((res: any) => {
						if (!res.error) {
							dispatch(setTenantPlan(res));
							fetchPlanDescriptionImages(planCode);
						}
					})
					.catch((err: any) => {
						console.log('getPlanDetails err', err);
					})
					.finally(() => {
						dispatch(clearLoading());
						return resolve({ status: 'success' });
					});
			});
		};

		if (isEmpty(tenantPlan)) {
			if (!isEmpty(selectedTenant)) {
				getPlanDetails(selectedTenant?.product?.code, selectedTenant?.product?.plan);
			} else {
				getPlanDetails(tenantCookie?.product?.code, tenantCookie?.product?.plan);
			}
		} else {
			fetchPlanDescriptionImages(`${tenantPlan?.code}`);
		}
	}, [dispatch, selectedTenant, tenantCookie.product.code, tenantCookie.product.plan, tenantPlan]);

	useEffect(() => {
		const getBillingInfo = (alias) => {
			dispatch(setLoading());
			return new Promise((resolve) => {
				kycApiService
					.getTenantBilling(alias)
					.then((res: any) => {
						if (!res.error) {
							setSubscriptionEndDate(res.expireAt);
						}
					})
					.catch((err: any) => {
						console.log('getBillingInfo err', err);
					})
					.finally(() => {
						dispatch(clearLoading());
						return resolve({ status: 'success' });
					});
			});
		};

		if (selectedTenant.alias) {
			getBillingInfo(selectedTenant.alias);
		}
	}, [dispatch, selectedTenant.alias]);

	const setAddOnsData = (features) => {
		const products = map(features, (feature, key) => ({ key, ...feature }));
		const addOns = products.filter((product) => product.type === 'addOns');
		setAddOns(addOns);
	};

	const fetchPlanDescriptionImages = (planCode) => {
		if (planCode === TENANT_PLAN.PLAN_FREE) {
			setImages(() => [getImageUrl('PLAN_FREE-1.png'), getImageUrl('PLAN_FREE-2.png')]);
		} else {
			setImages(() => [getImageUrl(`${planCode}.png`)]);
		}
	};

	const handleChangePlan = () => {
		navigate(`/tenant/${tenantCookie.alias}/plan/change-plan`);
	};

	const handleAddOnSetting = () => {
		navigate(`/tenant/${tenantCookie.alias}/add-ons`);
	};

	const handleCancelSubcription = () => {
		navigate(`/tenant/${tenantCookie.alias}/plan/cancel-subscription`);
	};

	return (
		<>
			<Plan
				planData={tenantPlan}
				productCode={selectedTenant?.product?.code || tenantCookie?.product?.code}
				images={images}
				addOns={addOns}
				currency={tenantCookie.currency}
				subscriptionEndDate={subscriptionEndDate}
				hideCancelSubscription={hideCancelSubscription}
				hideChangePlan={hideChangePlan}
				handleChangePlan={handleChangePlan}
				handleCancelSubcription={handleCancelSubcription}
				handleAddOnSetting={handleAddOnSetting}
			/>
		</>
	);
};

export default PlanPage;
