/**
 * Tenant List
 */
export const SET_TENANT_LIST = 'tenant/SET_TENANT_LIST' as const;
export const SET_TENANT_ALIAS = 'tenant/SET_TENANT_ALIAS' as const;
export const SET_ACCOUNT_DETAILS_MODE = 'tenant/SET_ACCOUNT_DETAILS_MODE' as const;
export const SET_TENANT_PAGE_INDEX = 'tenant/SET_TENANT_PAGE_INDEX' as const;
export const SET_TENANTS_PER_PAGE = 'tenant/SET_TENANTS_PER_PAGE' as const;

/**
 * Tenant Filter
 */
export const SET_TENANT_FILTER = 'tenant/SET_TENANT_FILTER' as const;
export const SET_TENANT_KYCSTATE = 'tenant/SET_TENANT_KYCSTATE' as const;

export const SET_SELECTED_TENANT = 'tenant/SET_SELECTED_TENANT' as const;
export const SET_TENANT_PLAN = 'tenant/SET_TENANT_PLAN' as const;
export const SET_TENANT_COUNTRY_LIST = 'tenant/SET_TENANT_COUNTRY_LIST' as const;
