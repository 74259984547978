export const MENU = {
	accountDetails: 'account-details',
	accountOverview: 'account-overview',
	addOns: 'add-ons',
	addTenant: 'add-tenant',
	billingPayment: 'billing-payment',
	documentTemplate: 'document-template',
	features: 'features',
	home: 'home',
	manualVerify: 'manual-verification',
	myProfile: 'my-profile',
	plan: 'plan',
	productConfiguration: 'product-configuration',
	integration: 'integration',
	productOverview: 'product-overview',
	subcriptionManagement: 'subscription-management',
	tenants: 'tenant-management',
	verificaton: 'verification',
	viewUsers: 'view-users',
};
