import React, { useState, useEffect, useLayoutEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';

import {
	ContentText,
	VerifierLayout,
	VerifierOcrExtract,
	VerifierFaceMismatch,
	VerifierAuthenticity,
	VerifierNameMismatch,
	VerifierDVS,
	DynamicVerifierOcrExtract,
} from 'shared_components/src/components';
import { NOTIFICATION_STATES } from 'shared_components/src/common/constants';

import { clearLoading, setLoading, setNotification, setJobState } from '../../store/actions';
import { _getVerifierJobState } from '../../store/selectors';

import VerifierFirstTimeStartPage from './VerifierFirstTimeStartPage';

import KycApiService from '../../service/kycApi.service';

import {
	VERIFIER_JOB_STATUS,
	VERIFIER_JOB_TYPES,
	VERIFIER_JOB_RESULT_STATUS,
	IVerifierJobState,
	IOcrMetaData,
} from 'shared_components/src/service/models/manual-verification';

/*
 * Styles
 */

/**
 * IProps
 */

interface IProps {
	setTenant: React.Dispatch<React.SetStateAction<string>>;
}

/**
 * Main Component
 */
const ManualVerificationPage = ({ setTenant }: IProps) => {
	const dispatch = useDispatch();

	const jobState = _getVerifierJobState();

	const [isInited, setIsInited] = useState(false);
	const [jobQueue, setJobQueue] = useState<Array<string>>([]);
	const [documentFields, setDocumentFields] = useState<IOcrMetaData | null>(null);
	const featureFlags = useFlags();

	const loadJobQueue = () => {
		setJobQueue([]);
	};

	useEffect(() => {
		if (isInited) {
			loadJobQueue();
		}
	}, [isInited]);

	useLayoutEffect(() => {
		setIsInited(true);
	}, []);

	useEffect(() => {
		const jobInput = jobState?.jobDetail?.input;

		if (jobInput?.documentClassificationCode) {
			getDocumentFields();
		}
	}, [jobState?.jobId]);

	function getDocumentFields() {
		const jobInput = jobState?.jobDetail?.input;

		if (jobInput?.documentClassificationCode) {
			dispatch(setLoading());

			KycApiService.getDocumentFields(jobInput?.documentType, jobInput?.documentClassificationCode)
				.then((res) => {
					dispatch(clearLoading());
					setDocumentFields(res);
				})
				.catch((err) => {
					dispatch(clearLoading());
					console.dir(err);
				});
		}
	}

	const handleSave = (data) => {
		setCompleteJob(data);
	};

	const setCompleteJob = (data) => {
		if (jobState) {
			dispatch(setLoading());
			KycApiService.completeJobV2(data, jobState.jobId, featureFlags['MV2-CompleteJob'] || false)
				.then((res) => {
					if (jobState?.jobType === VERIFIER_JOB_TYPES.dvs) {
						if (data?.jobDetail?.output?.status === VERIFIER_JOB_RESULT_STATUS.failed) {
							dispatch(
								setNotification({
									type: NOTIFICATION_STATES.success,
									message: 'Official Source Check Failed',
								})
							);
						} else {
							dispatch(
								setNotification({
									type: NOTIFICATION_STATES.success,
									message: 'Official Source Check Passed',
								})
							);
						}
					} else if (res.error) {
						console.dir('errr => ', res.error);
						dispatch(
							setNotification({
								type: NOTIFICATION_STATES.error,
								message: 'Job Completion is failed.',
							})
						);
					} else {
						dispatch(
							setNotification({
								type: NOTIFICATION_STATES.success,
								message: 'Verification Saved',
							})
						);
					}
				})
				.catch((err) => {
					console.dir('errr => ', err);
					dispatch(
						setNotification({
							type: NOTIFICATION_STATES.error,
							message: 'Job Completion is failed.',
						})
					);
				})
				.finally(() => {
					dispatch(clearLoading());
					dispatch(
						setJobState({
							...jobState,
							status: VERIFIER_JOB_STATUS.completed,
						})
					);
					setTenant('');
				});
		}
	};

	const handleApprove = (data) => {
		setCompleteJob(data);
	};

	const handleReject = (data) => {
		setCompleteJob(data);
	};

	const handleChangeSecurity = () => {};

	/**
	 * Get document images
	 */
	const getImageData = (id: string) => {
		return new Promise((resolve) => {
			dispatch(setLoading());
			KycApiService.getImageDataFromS3(id)
				.then(async (res) => {
					try {
						const blobToBase64 = (blob) => {
							const reader = new FileReader();
							reader.readAsDataURL(blob);
							return new Promise((reslve) => {
								reader.onloadend = () => {
									reslve(reader.result);
								};
							});
						};
						if (res) {
							const imgData = await blobToBase64(res);
							resolve(imgData);
						} else {
							resolve('');
						}
					} catch (e) {
						resolve('');
					}
				})
				.catch((err) => {
					resolve('');
				})
				.finally(() => {
					dispatch(clearLoading());
				});
		});
	};

	const curVerificationPage = useMemo(() => {
		let _curVerificationPage = <></>;

		if (jobState && jobState.status === VERIFIER_JOB_STATUS.inProgress) {
			switch (jobState.jobType) {
				case VERIFIER_JOB_TYPES.ocr:
					if (jobState?.jobDetail?.input?.documentClassificationCode) {
						_curVerificationPage = (
							<DynamicVerifierOcrExtract
								handleSave={handleSave}
								jobDetail={jobState?.jobDetail}
								jobId={jobState.jobId}
								handleReject={handleReject}
								getImageData={getImageData}
								ocrMetadata={documentFields}
							/>
						);
						break;
					} else {
						_curVerificationPage = (
							<VerifierOcrExtract
								handleSave={handleSave}
								jobDetail={jobState?.jobDetail}
								jobId={jobState.jobId}
								handleReject={handleReject}
								getImageData={getImageData}
							/>
						);
						break;
					}

				case VERIFIER_JOB_TYPES.faceMatch:
					_curVerificationPage = (
						<VerifierFaceMismatch
							jobDetail={jobState?.jobDetail}
							handleReject={handleReject}
							jobId={jobState.jobId}
							handleApprove={handleApprove}
							getImageData={getImageData}
						/>
					);
					break;

				case VERIFIER_JOB_TYPES.authenticity:
					_curVerificationPage = (
						<VerifierAuthenticity
							jobDetail={jobState?.jobDetail}
							jobId={jobState.jobId}
							handleReject={handleReject}
							handleApprove={handleApprove}
							handleChange={handleChangeSecurity}
							getImageData={getImageData}
						/>
					);
					break;

				case VERIFIER_JOB_TYPES.nameMatch:
					_curVerificationPage = (
						<VerifierNameMismatch
							jobDetail={jobState?.jobDetail}
							jobId={jobState.jobId}
							handleReject={handleReject}
							handleApprove={handleApprove}
							getImageData={getImageData}
						/>
					);
					break;

				case VERIFIER_JOB_TYPES.dvs:
					_curVerificationPage = (
						<VerifierDVS
							handleSave={handleSave}
							jobDetail={jobState?.jobDetail}
							jobId={jobState.jobId}
							handleReject={handleReject}
							getImageData={getImageData}
						/>
					);
					break;

				default:
					_curVerificationPage = <ContentText>No verification stat</ContentText>;
			}
		} else {
			_curVerificationPage = <VerifierFirstTimeStartPage handleStart={() => {}} />;
		}

		return _curVerificationPage;
	}, [jobState, documentFields?.classificationCode]);

	return (
		<VerifierLayout
			timeout={0}
			jobNumber={jobState?.jobId}
			jobType={jobState?.jobType}
			jobDetail={jobState?.jobDetail}
			isStartPage={!jobState || jobState.status !== VERIFIER_JOB_STATUS.inProgress}
		>
			{curVerificationPage}
		</VerifierLayout>
	);
};

export default ManualVerificationPage;
