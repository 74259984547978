import { EmptyPage } from 'shared_components/src/components/common';
import { AUTH_ROLE } from 'shared_components/src/common/constants';

import {
	AddTenantPage,
	DocumentListPage,
	Home,
	LoginPage,
	ManualVerificationPage,
	ManualVerification,
	PlansPage,
	TemplateDetailPage,
	TenantLayout,
	TenantsPage,
	TestTemplatePage,
	SubscriptionManagement,
	VerificationDetailPage,
	VerificationPage,
	UserDetailPage,
	UsersPage,
	IDPResponse,
	VerificationDetailPageV2,
	VerificationDetails,
} from '../../pages';

const routes = [
	// No required authentication
	{
		path: '/',
		component: EmptyPage,
		hasAuth: false,
		exact: true,
		hasFlag: false,
		flag: '',
	},
	{
		path: '/login',
		component: LoginPage,
		hasAuth: false,
		exact: true,
		hasFlag: false,
		flag: '',
	},
	{
		path: '/idp/sso',
		component: EmptyPage,
		hasAuth: false,
		exact: true,
		hasFlag: false,
		flag: '',
	},
	{
		path: '/idp/response',
		component: IDPResponse,
		hasAuth: false,
		exact: true,
		hasFlag: false,
		flag: '',
	},
	// Subscripton
	{
		path: '/subscription-management',
		component: SubscriptionManagement,
		hasAuth: true,
		exact: true,
		hasFlag: false,
		flag: '',
	},
	// Home
	{
		path: '/home',
		component: Home,
		hasAuth: true,
		exact: true,
		hasFlag: false,
		flag: '',
		roles: [AUTH_ROLE.admin, AUTH_ROLE.owner, AUTH_ROLE.verifier, AUTH_ROLE.docMgr],
	},
	// Tenants
	{
		path: '/tenant-management',
		component: TenantsPage,
		hasAuth: true,
		exact: true,
		hasFlag: false,
		flag: '',
		roles: [AUTH_ROLE.admin, AUTH_ROLE.owner],
	},
	{
		path: '/tenant/add-tenant',
		component: AddTenantPage,
		hasAuth: true,
		exact: true,
		hasFlag: false,
		flag: '',
		roles: [AUTH_ROLE.admin, AUTH_ROLE.owner],
	},
	{
		path: '/tenant/:tenantAlias/product-overview',
		component: TenantLayout,
		hasAuth: true,
		exact: true,
		hasFlag: false,
		flag: '',
		roles: [AUTH_ROLE.admin, AUTH_ROLE.owner],
	},
	{
		path: '/tenant/:tenantAlias/account-overview',
		component: TenantLayout,
		hasAuth: true,
		exact: true,
		hasFlag: false,
		flag: '',
		roles: [AUTH_ROLE.admin, AUTH_ROLE.owner],
	},
	{
		path: '/tenant/:tenantAlias/account-details',
		component: TenantLayout,
		hasAuth: true,
		exact: true,
		hasFlag: false,
		flag: '',
		roles: [AUTH_ROLE.admin, AUTH_ROLE.owner],
	},
	// Verification
	{
		path: '/tenant/:tenantAlias/verification',
		component: TenantLayout,
		hasAuth: true,
		exact: true,
		hasFlag: false,
		flag: '',
		roles: [AUTH_ROLE.admin, AUTH_ROLE.owner],
	},
	{
		path: '/tenant/:tenantAlias/verification-detail/:verId',
		component: VerificationDetails,
		hasAuth: true,
		exact: true,
		hasFlag: false,
		flag: '',
		roles: [AUTH_ROLE.admin, AUTH_ROLE.owner],
	},
	{
		path: '/tenant/:tenantAlias/product-configuration',
		component: TenantLayout,
		hasAuth: true,
		exact: true,
		hasFlag: false,
		flag: '',
		roles: [AUTH_ROLE.admin, AUTH_ROLE.owner],
	},
	{
		path: '/tenant/:tenantAlias/integration',
		component: TenantLayout,
		hasAuth: true,
		exact: true,
		hasFlag: false,
		flag: '',
		roles: [AUTH_ROLE.admin, AUTH_ROLE.owner],
	},
	{
		path: '/tenant/:tenantAlias/features',
		component: TenantLayout,
		hasAuth: true,
		exact: true,
		hasFlag: false,
		flag: '',
		roles: [AUTH_ROLE.admin, AUTH_ROLE.owner],
	},
	{
		path: '/tenant/:tenantAlias/add-ons',
		component: TenantLayout,
		hasAuth: true,
		exact: true,
		hasFlag: false,
		flag: '',
		roles: [AUTH_ROLE.admin, AUTH_ROLE.owner],
	},
	{
		path: '/tenant/:tenantAlias/billing-payment',
		component: TenantLayout,
		hasAuth: true,
		exact: true,
		hasFlag: false,
		flag: '',
		roles: [AUTH_ROLE.admin, AUTH_ROLE.owner],
	},
	{
		path: '/tenant/:tenantAlias/plan',
		component: TenantLayout,
		hasAuth: true,
		exact: true,
		hasFlag: false,
		flag: '',
		roles: [AUTH_ROLE.admin, AUTH_ROLE.owner],
	},
	{
		path: '/tenant/:tenantAlias/plan/change-plan',
		component: TenantLayout,
		hasAuth: true,
		exact: true,
		hasFlag: false,
		flag: '',
		roles: [AUTH_ROLE.admin, AUTH_ROLE.owner],
	},
	{
		path: '/tenant/:tenantAlias/plan/cancel-subscription',
		component: PlansPage,
		hasAuth: true,
		exact: true,
		hasFlag: false,
		flag: '',
		roles: [AUTH_ROLE.admin, AUTH_ROLE.owner],
	},
	{
		path: '/tenant/:tenantAlias/plan/subscribe',
		component: PlansPage,
		hasAuth: true,
		exact: true,
		hasFlag: false,
		flag: '',
		roles: [AUTH_ROLE.admin, AUTH_ROLE.owner],
	},
	// User
	{
		path: '/tenant/:tenantAlias/view-users',
		component: UsersPage,
		hasAuth: true,
		exact: true,
		hasFlag: false,
		flag: '',
		roles: [AUTH_ROLE.admin, AUTH_ROLE.owner],
	},
	{
		path: '/tenant/:alias/user-detail/:type/:verId',
		component: UserDetailPage,
		hasAuth: true,
		exact: true,
		hasFlag: false,
		flag: '',
		roles: [AUTH_ROLE.admin, AUTH_ROLE.owner],
	},
	// Document
	{
		path: '/document-template',
		component: DocumentListPage,
		hasAuth: true,
		exact: true,
		hasFlag: false,
		flag: '',
		roles: [AUTH_ROLE.admin, AUTH_ROLE.docMgr, AUTH_ROLE.owner],
	},
	{
		path: '/document-template/test',
		component: TestTemplatePage,
		hasAuth: true,
		exact: true,
		hasFlag: false,
		flag: '',
		roles: [AUTH_ROLE.admin, AUTH_ROLE.docMgr, AUTH_ROLE.owner],
	},
	{
		path: '/document-template/create',
		component: TemplateDetailPage,
		hasAuth: true,
		exact: true,
		hasFlag: false,
		flag: '',
		roles: [AUTH_ROLE.admin, AUTH_ROLE.docMgr, AUTH_ROLE.owner],
	},
	{
		path: '/document-template/:type/:tmpId',
		component: TemplateDetailPage,
		hasAuth: true,
		exact: true,
		hasFlag: false,
		flag: '',
		roles: [AUTH_ROLE.admin, AUTH_ROLE.owner],
	},
	// Manual Verify
	{
		path: '/manual-verification',
		component: ManualVerification,
		hasAuth: true,
		exact: true,
		hasFlag: false,
		flag: '',
		roles: [AUTH_ROLE.admin, AUTH_ROLE.verifier],
	},
	// Account Settings
	/* {
        path: '/account-settings',
        component: AccountSettings,
        hasAuth: true,
        exact: true,
    }, */
];

export default routes;
