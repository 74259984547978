import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getCookie } from 'shared_components/src/service/common.service';

import { gotoStartPage } from '../../common/utils';
import { _getAuthRoles } from '../../store/selectors';

import VerificationDetailsV1 from './VerificationDetailPage';
import VerificationDetailsV2 from './VerificationDetailV2';

/**
 * MAIN COMPONENT
 */
const VerificationDetails = () => {
	const featureFlags = useFlags();
	const navigate = useNavigate();
	const selectedTenant = getCookie('selectedTenant') ? JSON.parse(getCookie('selectedTenant')) : {};

	const [_, setTenantAlias] = useState('');
	const authRoles = _getAuthRoles();

	const isDocumentProofingEnabled = featureFlags['document-proofing-feature'];

	useEffect(() => {
		if (selectedTenant?.alias === undefined) {
			handleGotoStartPage();
		} else {
			setTenantAlias(selectedTenant?.alias);
		}
	}, []);

	const handleGotoStartPage = () => {
		gotoStartPage(navigate, authRoles);
	};

	if (isDocumentProofingEnabled === true) {
		return <VerificationDetailsV2 />;
	} else if (isDocumentProofingEnabled === false) {
		return <VerificationDetailsV1 isDocumentProofingEnabled={isDocumentProofingEnabled} />;
	} else return null;
};

export default VerificationDetails;
