import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Grid, CardContent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getCookie } from 'shared_components/src/service/common.service';
import {
	TenantManagement,
	ManualVerification,
	DcoumentManagement,
	AddIcon,
	InfoOutlineIcon,
} from 'shared_components/src/common/icons';
import { GENERAL_COOKIES, AUTH_ROLE } from 'shared_components/src/common/constants';
import { updateMenu } from '../../store/menu/actions';
import { MENU } from '../../common/routes/menu';

const useStyles = makeStyles(() => ({
	'home-container': {
		'& .cards-wrapper': {
			'& .title': {
				color: '#20203A',
				fontSize: '16px',
				fontWeight: 600,
				lineHeight: '40px',
			},

			'& .subTitle': {
				color: '#333333',
				fontSize: '16px',
				fontWeight: 400,
				lineHeight: '24px',
			},
		},

		'& .card-item': {
			width: '362px',
			padding: '0',
			margin: '4px 24px 24px 4px',

			'& .MuiCardContent-root': {
				padding: '0',
			},

			'@media screen and (max-width: 600px)': {
				width: '100%',
			},
		},

		'& .cardButton': {
			width: '100%',
			border: '1px solid #E8E8E8',
			cursor: 'pointer',
			display: 'flex',
			padding: '24px',
			minHeight: '200px',
			textAlign: 'left',
			borderRadius: '8px',
			backgroundColor: '#fff',

			'&:hover': {
				border: '1px solid #B3B3C6',
				boxShadow: '1px 1px 15px 4px #20203A1A',
			},

			'&:active': {
				border: '1px solid #470D82',
				boxShadow: '1px 1px 10px 2px #470D8226',
			},

			'& .svg-wrapper': {
				height: '100%',
				padding: '16px',
				borderRadius: '50%',
				marginRight: '16px',

				'@media screen and (max-width: 600px)': {
					display: 'none',
				},
			},

			'& .subAction': {
				color: '#470D82',
				border: 'none',
				margin: '8px 16px 16px',
				display: 'flex',
				padding: '4px 8px',
				maxWidth: '160px',
				background: '#fff',
				alignItems: 'center',
				borderRadius: '4px',
				flexDirection: 'row',

				'&:hover': {
					color: '#8C07DD',
					background: '#F8EFFB',

					'& svg': {
						fill: '#8C07DD',
					},
				},

				'&:active': {
					color: '#5D0DA1',
					background: '#F3E2F8',

					'& svg': {
						fill: '#5D0DA1',
					},
				},
			},
		},
	},
}));

const Home = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const roles = getCookie(GENERAL_COOKIES.userRoles);

	const [showTenantManagement, setShowTenantManagement] = useState(false);
	const [showDocManagement, setShowDocManagement] = useState(false);
	const [showManualVerification, setShowManualVerification] = useState(false);

	useEffect(() => {
		dispatch(updateMenu(MENU.home));
	}, []);

	useEffect(() => {
		const role = roles.split(',');
		const admin = role.includes(AUTH_ROLE.admin);
		const owner = role.includes(AUTH_ROLE.owner);
		const docMgr = role.includes(AUTH_ROLE.docMgr);
		const verifier = role.includes(AUTH_ROLE.verifier);
		setShowTenantManagement(admin || owner);
		setShowDocManagement(admin || docMgr);
		setShowManualVerification(admin || verifier);
	}, [roles]);

	const homeItems = [
		{
			key: 'TENANT_MANAGEMENT',
			icon: <TenantManagement fill={'white'} />,
			iconBackgroundColor: '#14B8A6',
			title: 'TENANT MANAGEMENT',
			subActionTitle: 'Add Tenant',
			subActionIcon: <AddIcon fill={'#470D82'} />,
			subTitle: 'To select and manage tenant from this section',
			show: showTenantManagement,
		},
		{
			key: 'DOCUMENT_MANAGEMENT',
			icon: <DcoumentManagement fill={'white'} />,
			iconBackgroundColor: '#2D9CDB',
			title: 'DOCUMENT MANAGEMENT',
			subActionTitle: 'Add Document',
			subActionIcon: <AddIcon fill={'#470D82'} />,
			subTitle: 'To manage and define document from this section',
			show: showDocManagement,
		},
		{
			key: 'MANUAL_VERIFICATION',
			icon: <ManualVerification fill={'white'} />,
			iconBackgroundColor: '#EB5757',
			title: 'MANUAL VERIFICATION',
			subActionTitle: 'View Job List',
			subActionIcon: <InfoOutlineIcon fill={'#470D82'} />,
			subTitle: 'You can choose to view only suspicious activities',
			show: showManualVerification,
		},
	];

	const handleSelection = (type) => {
		if (type === 'TENANT_MANAGEMENT') {
			navigate('/tenant-management');
		} else if (type === 'DOCUMENT_MANAGEMENT') {
			navigate('/document-template');
		} else if (type === 'MANUAL_VERIFICATION') {
			navigate('/manual-verification');
		}
	};

	const handleSubActionSelection = (e, type) => {
		e.stopPropagation();
		if (type === 'TENANT_MANAGEMENT') {
			navigate(`/tenant/add-tenant`);
		} else if (type === 'DOCUMENT_MANAGEMENT') {
			console.log('Add new document');
		} else if (type === 'MANUAL_VERIFICATION') {
			console.log('view MV job list');
		}
	};

	return (
		<Grid container className={classes['home-container']}>
			{homeItems.map(
				(item) =>
					item.show && (
						<Grid item className="cards-wrapper" key={item.key}>
							<CardContent className="card-item">
								<button
									id={`CARD_${item.key}`}
									className="cardButton"
									onClick={() => handleSelection(item.key)}
								>
									<div
										className="svg-wrapper"
										style={{
											border: `1px solid ${item.iconBackgroundColor}`,
											backgroundColor: item.iconBackgroundColor,
										}}
									>
										{item.icon}
									</div>
									<div style={{ textAlign: 'left' }}>
										<div className="title">{item.title}</div>
										<button
											className="subAction"
											onClick={(e) => handleSubActionSelection(e, item.key)}
										>
											<div style={{ margin: '3px 8px 0' }}>{item.subActionIcon}</div>
											<div style={{ fontWeight: 500, lineHeight: '24px' }}>
												{item.subActionTitle}
											</div>
										</button>
										<div className="subTitle">{item.subTitle}</div>
									</div>
								</button>
							</CardContent>
						</Grid>
					)
			)}
		</Grid>
	);
};

export default Home;
