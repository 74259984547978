import * as ActionTypes from './actionTypes';
import { ITenantDocument } from 'shared_components/src/service/models/tenant';
import { TENANT_MODE } from 'shared_components/src/service/models/tenant';

export const setTenants = (productCode: string, list: ITenantDocument[]) => ({
	type: ActionTypes.SET_TENANT_LIST,
	payload: {
		[productCode]: list,
	},
});

export const setTenantAlias = (id: string) => ({
	type: ActionTypes.SET_TENANT_ALIAS,
	payload: id,
});

export const setTenantFilter = (filter: string) => ({
	type: ActionTypes.SET_TENANT_FILTER,
	payload: filter,
});

export const setTenantKycState = (kycState: string) => ({
	type: ActionTypes.SET_TENANT_KYCSTATE,
	payload: kycState,
});

export const setSelectedTenant = (tenant: any) => ({
	type: ActionTypes.SET_SELECTED_TENANT,
	payload: tenant,
});

export const setTenantPlan = (plan: any) => ({
	type: ActionTypes.SET_TENANT_PLAN,
	payload: plan,
});

export const setCountryList = (countryList: any) => ({
	type: ActionTypes.SET_TENANT_COUNTRY_LIST,
	payload: countryList,
});

export const setAccountDetailsMode = (mode: string) => ({
	type: ActionTypes.SET_ACCOUNT_DETAILS_MODE,
	payload: mode,
});

export const setTenantsPageIndex = (index: number) => ({
	type: ActionTypes.SET_TENANT_PAGE_INDEX,
	payload: index,
});

export const setTenantsPerPage = (value: number) => ({
	type: ActionTypes.SET_TENANTS_PER_PAGE,
	payload: value,
});

export type TenantAction =
	| ReturnType<typeof setTenants>
	| ReturnType<typeof setTenantAlias>
	| ReturnType<typeof setTenantFilter>
	| ReturnType<typeof setTenantKycState>
	| ReturnType<typeof setSelectedTenant>
	| ReturnType<typeof setTenantPlan>
	| ReturnType<typeof setCountryList>
	| ReturnType<typeof setTenantsPageIndex>
	| ReturnType<typeof setTenantsPerPage>
	| ReturnType<typeof setAccountDetailsMode>;
