import React, { useState } from 'react';
import {
	Box,
	Dialog,
	FormControl,
	FormHelperText,
	Skeleton,
	styled,
	TextField,
	Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MV_CONFIDENCE_THRESHOLD } from 'shared_components/src/common/constants';
import CommonAutocomplete from 'shared_components/src/components/common/CommonAutocomplete';
import { TruuthChip } from 'shared_components/src/components/common';

const useStyles = makeStyles(() => ({
	root: {
		'& .MuiFormControl-root': {
			width: '180px',
		},

		'& .MuiFormLabel-root': {
			'&.Mui-error': {
				color: '#d32f2f',
			},
		},

		'& .MuiInputLabel-root.Mui-focused': {
			color: '#8C07DD',
		},

		'& .MuiInput-root:after': {
			borderBottom: '2px solid #8C07DD',
		},

		'& .Mui-error': {
			'&.MuiInput-root:after': {
				borderBottom: '2px solid #d32f2f',
			},
		},

		'& .MuiInputBase-input': {
			fontSize: '16px',
			lineHeight: '24px',
			color: '#343451',
		},

		'& .MuiFormHelperText-root': {
			'&.Mui-error': {
				fontWeight: 400,
				fontSize: '12px',
				lineHeight: '16px',
				color: '#C6323B',
				marginTop: '4px',
			},
		},

		'& .datePicker': {
			'&.error': {
				'& .MuiFormLabel-root': {
					color: '#d32f2f',
				},
			},

			'&:not(error)': {
				'& .MuiFormLabel-root': {
					color: '#70708F',

					'&.Mui-focused': {
						color: '#8C07DD',
					},
				},

				'& .MuiOutlinedInput-notchedOutline': {
					borderBottom: '1px solid #767676',
				},
			},

			'& .MuiFormLabel-root': {
				marginLeft: '-12px',
			},

			'& .MuiInputBase-input': {
				paddingLeft: 0,
				paddingBottom: '4px',
			},

			'& .MuiInputBase-root': {
				'&:hover': {
					'& .MuiOutlinedInput-notchedOutline': {
						borderWidth: '2px',
					},
				},
			},

			'& .Mui-focused': {
				'& .MuiOutlinedInput-notchedOutline': {
					borderBottom: '2px solid #8C07DD',
				},
			},

			'& .MuiOutlinedInput-notchedOutline': {
				border: 'none',
				borderRadius: 0,
				borderBottom: '1px solid #767676',
			},
		},
	},
}));

const Data = styled('div')({
	width: '100%',
});

const DataRow = styled('div')({
	display: 'flex',
	alignItems: 'center',
	borderBottom: '1px solid #EDEDF3',
	fontFamily: 'Inter, sans-serif',
	'&:last-of-type, &:first-of-type': {
		borderBottom: 'none',
	},
});

const DataField = styled(Box)({
	padding: '12px 0',
	fontFamily: 'Inter, sans-serif',
	width: '100%',
	marginBottom: 'auto',

	'& span': {
		fontSize: '14px',
		lineHeight: '24px',
		color: '#70708F',
	},
	'& p': {
		fontWeight: 600,
		fontSize: '16px',
		lineHeight: '24px',
		color: '#343451',
		margin: 0,
	},
});

const DialogBox = styled(Dialog)(() => ({
	'& .MuiDialog-paper': {
		boxShadow: '1px 1px 15px 0px #20203A14',
		borderRadius: '5px',
		minWidth: '480px',
	},
}));

/**
 * IProps
 */
interface IProps {
	fieldsData: any;
	OCRData: any;
	documentData?: any;
	isDataEditable?: boolean;
	handleDateFormat?: any;
	invalidFields?: any;
	setData?: any;
}

const MVDocumentData = ({
	fieldsData,
	OCRData,
	documentData,
	isDataEditable,
	handleDateFormat,
	invalidFields,
	setData,
}: IProps) => {
	const classes = useStyles();
	const threshold = MV_CONFIDENCE_THRESHOLD ? Number(MV_CONFIDENCE_THRESHOLD) * 100 : 0;
	const [dateFormat, setDateFormat] = useState('YYYY-MM-DD');
	const [modalOpen, setModalOpen] = useState(false);
	const [formatUpdated, setFormatUpdated] = useState(false);
	const [formats, setFormats] = useState(['YYYY-MM-DD', 'YYYY-MM']);
	const [views, setViews] = useState(['year', 'month', 'day'] as any);

	const handleChange = (field, value) => {
		setData(field, value);
	};

	const handleDateChange = (field, value) => {
		handleChange(field, dayjs(value).format(dateFormat));
	};

	const closeModal = () => {
		setModalOpen(false);
	};

	const handleDateFieldSelection = (field) => {
		if (!formatUpdated && field.promptForOptionalDay) {
			setModalOpen(true);
		}
	};

	const updateDateFormat = (format) => {
		setFormatUpdated(true);
		setModalOpen(false);
		setDateFormat(format);
		handleDateFormat(format);
		if (format.length < 10) {
			setViews(['month', 'year']);
		}
	};

	const renderInputFields = (field, data) => {
		const error = invalidFields[field.key] ? true : false;
		if (field.type === 'select') {
			console.log('field', field);
			return (
				<FormControl variant='standard' sx={{ width: '100%' }} error={error}>
					<CommonAutocomplete
						id={`${field.key}_input`}
						label={`${field.name}`}
						options={field.items}
						getOptionLabel={(option) => typeof option === 'object' ? option.label : option}
						// getOptionSelected={(option, value) => option.value === value.value}
						onChange={(event, newValue) => {
							console.log('newValue', newValue);
							handleChange(field, newValue ? newValue.value : '');
						}}
						value={field.items.find(item => item.value === data?.['value']) || null}
						required={field.mandatory}
						error={invalidFields[field.key] ? true : false}
					/>
					<FormHelperText>{invalidFields[field.key]}</FormHelperText>
				</FormControl>
			);
		} else if (field.type === 'date') {
			return field.promptForOptionalDay && !formatUpdated ? (
				<TextField
					id={`${field.key}_input`}
					type={'text'}
					label={field.name}
					required={field.mandatory}
					variant='standard'
					className='field-style'
					value={data?.['value']}
					onClick={(event) => handleDateFieldSelection(field)}
					error={error}
					helperText={invalidFields[field.key]}
				></TextField>
			) : (
				<FormControl variant='standard' sx={{ width: '100%' }} error={error}>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							className={`datePicker ${invalidFields[field.key] ? 'error' : ''}`}
							label={`${field.name} ${field.mandatory ? '*' : ''}`}
							value={data?.['value'] ? dayjs(data?.['value']) : null}
							format={dateFormat}
							views={views}
							onChange={(newValue) => handleDateChange(field, newValue)}
						/>
					</LocalizationProvider>
					<FormHelperText>{invalidFields[field.key]}</FormHelperText>
				</FormControl>
			);
		} else {
			return (
				<TextField
					id={`${field.key}_input`}
					type={field.type}
					label={field.name}
					required={field.mandatory}
					variant='standard'
					className='field-style'
					value={data?.['value']}
					onChange={(event) => handleChange(field, event.target.value)}
					error={error}
					helperText={invalidFields[field.key]}
				></TextField>
			);
		}
	};

	const renderDocumentData = () => {
		if (fieldsData.length > 0) {
			return fieldsData?.map((field) => {
				const OCRFieldData = OCRData.find((d) => d.key === field.key);
				const DocumentFieldData = documentData.find((d) => d.key === field.key);
				const confidence = Number(OCRFieldData?.['confidence'] || 0) * 100;
				return (
					<DataRow key={field.key}>
						<DataField>{renderInputFields(field, DocumentFieldData)}</DataField>
						<DataField>
							<span style={{ fontFamily: 'Inter, sans-serif' }}>{field.name}</span>
							<p style={{ fontFamily: 'Inter, sans-serif' }}>
								{OCRFieldData?.['value'] || '-'}
							</p>
						</DataField>
						<div style={{ minWidth: '70px', marginBottom: 'auto' }}>
							<p
								style={{
									fontWeight: 600,
									color: threshold <= confidence ? '#20203A' : '#FFF',
									border: `1px solid ${threshold <= confidence ? '#E1E1EA' : '#9B272E'}`,
									backgroundColor: threshold <= confidence ? '#E1E1EA' : '#9B272E',
									borderRadius: '4px',
									padding: '4px 6px',
									width: 'fit-content',
								}}
							>
								{confidence.toFixed(2) + '%' || '-'}
							</p>
						</div>
					</DataRow>
				);
			});
		}
		return <Skeleton animation='wave' variant='rectangular' width={500} height={300} />;
	};

	const renderDocumentDisplayData = () => {
		if (fieldsData.length > 0) {
			return fieldsData?.map((field) => {
				const OCRFieldData = OCRData.find((d) => d.key === field.key);
				return (
					<DataRow key={field.key} style={{ maxWidth: '200px' }}>
						<DataField>
							<span style={{ fontFamily: 'Inter, sans-serif' }}>{field.name}</span>
							<p style={{ fontFamily: 'Inter, sans-serif' }}>
								{OCRFieldData?.['value'] || '-'}
							</p>
						</DataField>
					</DataRow>
				);
			});
		}
		return <Skeleton animation='wave' variant='rectangular' width={500} height={300} />;
	};

	return (
		<Data className={classes.root}>
			{isDataEditable ? (
				<>
					<DataRow>
						<DataField>
							<div style={{ color: '#70708F', fontSize: '16px' }}>Document Data</div>
						</DataField>
						<DataField>
							<div style={{ color: '#70708F', fontSize: '16px' }}>OCR Data</div>
						</DataField>
						<div style={{ minWidth: '70px' }}></div>
					</DataRow>
					{renderDocumentData()}
				</>
			) : (
				<>
					<DataRow>
						<DataField>
							<div style={{ color: '#70708F', fontSize: '16px' }}>Document Data</div>
						</DataField>
					</DataRow>
					{renderDocumentDisplayData()}
				</>
			)}

			<DialogBox open={modalOpen} onClose={closeModal} maxWidth='md'>
				<div
					style={{
						display: 'flex',
						backgroundColor: '#F0F0F4',
						padding: '16px 24px',
						borderBottom: '1px solid #DCDCE5',
						alignItems: 'center',
						marginBottom: '24px',
					}}
				>
					<Typography
						style={{
							width: '100%',
							textAlign: 'center',
							fontFamily: 'Inter, sans-serif',
							fontSize: '16px',
							fontWeight: 400,
							lineHeight: '24px',
							color: '#20203A',
						}}
					>
						Please select the date format
					</Typography>
				</div>
				{formats.map((format, index) => (
					<div key={`${index}${format}`} style={{ margin: '0 0 16px 24px' }}>
						<TruuthChip
							code={format}
							label={format}
							selected={false}
							isMultipleSelection={false}
							variant='outlined'
							background='color'
							onClick={() => updateDateFormat(format)}
						/>
					</div>
				))}
			</DialogBox>
		</Data>
	);
};

export default MVDocumentData;

MVDocumentData.defaultProps = {
	fieldsData: [],
	OCRData: [],
	documentData: [],
	isDataEditable: false,
	invalidFields: [],
	setData: null,
};
