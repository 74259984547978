import React, { useState, useLayoutEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  Typography,
  Box,
  Stack,
  Autocomplete,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import {
  TruuthChip,
  TruuthButton,
} from "shared_components/src/components/common";
import { VERIFIER_JOB_TYPES } from "shared_components/src/service/models/manual-verification";
import { _getTenants } from "../../store/selectors";
import { RootState } from "../../store/rootReducer";
import { MV_FILTERS } from "./ManualVerification";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiFormLabel-root": {
      "&.Mui-error": {
        color: "#d32f2f",
      },
    },

    "& .MuiInputLabel-root.Mui-focused": {
      color: "#8C07DD",
    },

    "& .MuiInput-root:after": {
      borderBottom: "2px solid #8C07DD",
    },

    "& .Mui-error": {
      "&.MuiInput-root:after": {
        borderBottom: "2px solid #d32f2f",
      },
    },

    "& .MuiInputBase-input": {
      color: "#343451",
      fontSize: "16px",
      lineHeight: "24px",
    },

    "& .MuiFormHelperText-root": {
      "&.Mui-error": {
        color: "#C6323B",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "16px",
        marginTop: "4px",
      },
    },

    "& .datePicker": {
      "&.error": {
        "& .MuiFormLabel-root": {
          color: "#d32f2f",
        },
      },

      "& .MuiFormLabel-root": {
        marginLeft: "-12px",
      },

      "& .MuiInputBase-input": {
        paddingLeft: 0,
        paddingBottom: "4px",
      },

      "& .MuiInputBase-root": {
        "&:hover": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderWidth: "2px",
          },
        },
      },

      "& .Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderBottom: "2px solid #8C07DD",
        },
      },

      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
        borderRadius: 0,
        borderBottom: "1px solid #767676",
      },
    },
  },
}));

const JOB_STATE: {
  code: MV_FILTERS["escalationStatus"];
  label: string;
}[] = [
  {
    code: "ALL",
    label: "All Jobs",
  },
  {
    code: "NOT_ESCALATED",
    label: "Normal Jobs",
  },
  {
    code: "ESCALATED",
    label: "Escalated Jobs",
  },
];

const JOB_TYPES = [
  {
    label: "Document Authenticity",
    value: VERIFIER_JOB_TYPES.authenticity,
  },
  {
    label: "Name Match",
    value: VERIFIER_JOB_TYPES.nameMatch,
  },
  {
    label: "Official Source Check",
    value: VERIFIER_JOB_TYPES.dvs,
  },
  {
    label: "Face Match",
    value: VERIFIER_JOB_TYPES.faceMatch,
  },
  {
    label: "Document OCR",
    value: VERIFIER_JOB_TYPES.ocr,
  },
];

/**
 * PROPS
 */
interface IProps {
  fetchJobs: (filters: MV_FILTERS) => void;
}

export default function MVJobFilterV2({ fetchJobs }: IProps) {
  const classes = useStyles();
  const [jobTypes, setJobTypes] = useState<VERIFIER_JOB_TYPES[]>([]);
  const [escalationStatus, setEscalationStatus] =
    useState<MV_FILTERS["escalationStatus"]>("NOT_ESCALATED");
  const [selectedTenants, setSelectedTenants] = useState<string[]>([]);

  const tenants =
    useSelector((state: RootState) => state.tenant.tenants)["KYC"] || [];
  const allTenants = useMemo(() => {
    return tenants.map((t) => ({ label: t.organisationName, value: t.alias }));
  }, [tenants]);

  useLayoutEffect(() => {
    try {
      const storedJobTypes = localStorage.getItem("jobTypes");
      const storedEscalationStatus = localStorage.getItem("escalationStatus");
      const storedSelectedTenants = localStorage.getItem("selectedTenants");

      if (storedJobTypes) {
        setJobTypes(JSON.parse(storedJobTypes));
      }
      if (storedEscalationStatus) {
        setEscalationStatus(
          storedEscalationStatus as MV_FILTERS["escalationStatus"]
        );
      }
      if (storedSelectedTenants) {
        setSelectedTenants(JSON.parse(storedSelectedTenants));
      }
    } catch (error) {
      console.error("Error retrieving filter data from local storage:", error);
    }
  }, []);

  const handleFilterApply = () => {
    try {
      localStorage.setItem("jobTypes", JSON.stringify(jobTypes));
    } catch (error) {
      console.error("Error saving jobTypes to local storage:", error);
    }

    try {
      localStorage.setItem("escalationStatus", escalationStatus as string);
    } catch (error) {
      console.error("Error saving escalationStatus to local storage:", error);
    }

    try {
      localStorage.setItem("selectedTenants", JSON.stringify(selectedTenants));
    } catch (error) {
      console.error("Error saving selectedTenants to local storage:", error);
    }

    const filterData = {
      jobTypes: jobTypes.map((jobType) => jobType),
      escalationStatus: escalationStatus,
      tenants: selectedTenants,
    };

    fetchJobs(filterData);
  };

  const resetFilters = () => {
    // Set initial values for state
    setJobTypes([]);
    setEscalationStatus("NOT_ESCALATED");
    setSelectedTenants([]);

    // Remove items from local storage
    try {
      localStorage.removeItem("jobTypes");
      localStorage.removeItem("escalationStatus");
      localStorage.removeItem("selectedTenants");
    } catch (error) {
      console.error("Error removing filter data from local storage:", error);
    }
  };

  return (
    <Box
      sx={{
        padding: "16px 24px",
        background: "white",
        border: "1px solid #E8E8E8",
        borderRadius: "6px",
      }}
      className={classes.root}
    >
      <Typography
        sx={{
          fontFamily: "Inter, sans-serif",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "24px",
          color: "#343451",
        }}
      >
        Filter by
      </Typography>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          flexWrap: "wrap",
          marginTop: "8px",
          paddingBottom: "16px",
          borderBottom: "1px solid #EDEDF3",
        }}
      >
        {JOB_STATE.map((jobType) => (
          <TruuthChip
            code={jobType.code as string}
            label={jobType.label as string}
            key={jobType.code as string}
            selected={escalationStatus === jobType.code}
            isMultipleSelection={false}
            variant="outlined"
            background="color"
            onClick={() => setEscalationStatus(jobType.code)}
          />
        ))}
      </Stack>
      <Stack
        direction={"row"}
        spacing={2}
        alignItems="center"
        sx={{ marginTop: "16px" }}
      >
        <FormControl sx={{ width: "240px" }} variant="standard">
          <InputLabel sx={{ textAlign: "left" }}>
            {jobTypes.length > 0 ? "Job Type" : "Select job types"}
          </InputLabel>

          <Select
            multiple
            value={jobTypes}
            onChange={(event) => {
              setJobTypes(event.target.value as VERIFIER_JOB_TYPES[]);
            }}
            renderValue={(selected) => (
              <Typography
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {selected
                  .map((value) => {
                    const option = JOB_TYPES.find((jt) => jt.value === value);
                    return option ? option.label : "";
                  })
                  .join(", ")}
              </Typography>
            )}
          >
            {JOB_TYPES.map((jobType) => (
              <MenuItem
                key={jobType.value}
                value={jobType.value}
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#F0ECF5",
                  },
                }}
              >
                {jobType.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ width: "240px" }} variant="standard">
          <InputLabel>
            {selectedTenants.length > 0 ? "Tenant" : "Select tenants"}
          </InputLabel>
          <Select
            multiple
            value={selectedTenants}
            onChange={(event) => {
              setSelectedTenants(event.target.value as string[]);
            }}
            renderValue={(selected) => (
              <Typography
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {selected
                  .map((value) => {
                    const option = allTenants.find(
                      (tenant) => tenant.value === value
                    );
                    return option ? option.label : "";
                  })
                  .join(", ")}
              </Typography>
            )}
          >
            {allTenants.map((tenant) => (
              <MenuItem
                key={tenant.value}
                value={tenant.value}
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#F0ECF5",
                  },
                }}
              >
                {tenant.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box flexGrow={1}></Box>

        <TruuthButton
          buttontype="secondary"
          size="medium"
          onClick={resetFilters}
        >
          Reset
        </TruuthButton>
        <TruuthButton
          buttontype="secondary"
          size="medium"
          onClick={handleFilterApply}
        >
          Apply
        </TruuthButton>
      </Stack>

      <SelectedTenantsChips
        tenants={selectedTenants}
        jobTypes={jobTypes}
        allTenants={allTenants}
        onDelete={(item, type) => {
          if (type === "jobTypes") {
            setJobTypes((types) => types.filter((i) => i !== item));
          } else {
            setSelectedTenants((tenants) => tenants.filter((i) => i !== item));
          }
        }}
      />
    </Box>
  );
}

function SelectedTenantsChips({
  tenants,
  jobTypes,
  allTenants,
  onDelete,
}: {
  tenants: string[];
  jobTypes: any[];
  allTenants: { label: string; value: string }[];
  onDelete: (index: string, type: string) => void;
}) {
  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      spacing={1}
      sx={{ marginTop: "16px" }}
    >
      {[...jobTypes, ...tenants].map((item) => {
        const label = jobTypes.includes(item)
          ? JOB_TYPES.find((jt) => jt.value === item)?.label
          : allTenants.find((t) => t.value === item)?.label;
        return (
          <Chip
            key={item}
            label={label}
            // isDeletable={true}
            onDelete={() =>
              onDelete(item, jobTypes.includes(item) ? "jobTypes" : "tenants")
            }
            sx={{
              whiteSpace: "nowrap",
              mb: 1,
              "&.MuiButtonBase-root.MuiChip-root": {
                color: "#470D82",
                background: `linear-gradient(0deg, #F0F0F4, #F0F0F4), linear-gradient(0deg, #E1E1EA, #E1E1EA) !important`,
                border: `1px solid #470D82`,
                "& svg": {
                  "& path": {
                    fill: "#470D82",
                  },
                },
              },
            }}
          />
        );
      })}
    </Stack>
  );
}
