import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { updateMenu } from '../../store/menu/actions';
import { MENU } from '../../common/routes/menu';
import { BillingAndPayment, Chargebee, ConfirmDialog } from 'shared_components/src/components/tenants';
import { NOTIFICATION_STATES } from 'shared_components/src/common/constants';
import { _getCountryList, _getTenantPlan } from '../../store/selectors';
import { setSelectedTenant } from '../../store/tenant/actions';
import kycApiService from '../../service/kycApi.service';
import { getCookie } from 'shared_components/src/service/common.service';
import { setLoading, clearLoading, setNotification } from '../../store/common/actions';

const PAYMENT_TYPE = {
	OFFLINE: 'OFFLINE',
	CARD: 'CARD',
};

const BillingAndPaymentPage = () => {
	const dispatch = useDispatch();
	const countryList = _getCountryList();
	const plan = _getTenantPlan();
	const navigate = useNavigate();
	const tenantData = getCookie('selectedTenant') ? JSON.parse(getCookie('selectedTenant')) : {};

	const [showCardPopup, setShowCardPopup] = useState(false);
	const [tenantAlias, setTenantAlias] = useState('');
	const [billingSystem, setBillingSystem] = useState('');
	const [paymentMethod, setPaymentMethod] = useState('');
	const [billingData, setBillingData] = useState({} as any);
	const [paymentData, setPaymentData] = useState({} as any);
	const [updateFailed, setUpdateFailed] = useState(false);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

	useEffect(() => {
		dispatch(updateMenu(MENU.billingPayment));
	}, [dispatch]);

	const getBillingInfo = React.useCallback(
		(alias) => {
			dispatch(setLoading());
			return new Promise((resolve) => {
				kycApiService
					.getTenantBilling(alias)
					.then((res: any) => {
						if (!res.error) {
							setBillingData(res.billingAddress);
							setPaymentData(res.paymentCard);
							setBillingSystem(res.billingSystem);
							setPaymentMethod(res.paymentMethod);
						}
					})
					.catch((err: any) => {
						console.log('getBillingInfo err', err);
					})
					.finally(() => {
						dispatch(clearLoading());
						return resolve({ status: 'success' });
					});
			});
		},
		[dispatch]
	);

	useEffect(() => {
		setTenantAlias(tenantData?.alias);
		getBillingInfo(tenantData?.alias);
	}, [getBillingInfo, tenantData.alias]);

	const offlinePayment = useMemo(() => {
		return paymentMethod === PAYMENT_TYPE.OFFLINE;
	}, [paymentMethod]);

	const updateBillingInfo = (data) => {
		setUpdateFailed(false);
		dispatch(setLoading());
		return new Promise((resolve) => {
			kycApiService
				.updateTenantBilling(data)
				.then((res: any) => {
					if (!res.error) {
						setBillingData(res);
						displayNotificatoinSuccess('Billing Details successfully updated.');
					} else {
						setUpdateFailed(true);
						displayNotificatoinError('Failed to update Billing Details.');
					}
				})
				.catch((err: any) => {
					setUpdateFailed(true);
					displayNotificatoinError('Failed to update Billing Details.');
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const updateCardDetails = (tokenId) => {
		const request = {
			productCode: tenantData?.product?.code || 'KYC',
			planCode: plan.code,
			currencyCode: tenantData.currency,
			paymentMethod,
			paymentCard: {
				cardTokenId: tokenId,
			},
		};
		updatePaymentDetails('CREDIT_CARD', request);
	};

	const handleOfflinePayment = () => {
		const request = {
			productCode: tenantData?.product?.code || 'KYC',
			planCode: plan.code,
			currencyCode: tenantData.currency,
			paymentMethod,
		};
		updatePaymentDetails('OFFLINE_OR_CARD', request);
		setOpenConfirmDialog(false);
	};

	const updatePaymentDetails = (type, request) => {
		dispatch(setLoading());
		return new Promise((resolve) => {
			kycApiService
				.updateCardDetails(tenantAlias, request)
				.then((res: any) => {
					if (!res.error) {
						getTenant(tenantAlias);
						if (type === 'OFFLINE_OR_CARD') {
							setPaymentMethod(request.paymentMethod);
							if (request.paymentMethod === PAYMENT_TYPE.OFFLINE) {
								displayNotificatoinSuccess(
									`Enable other payment method successful. Status will be updated accordingly.`
								);
								setPaymentData({});
							} else {
								displayNotificatoinSuccess(`Disable other payment method successful.
								Status is updated to awaiting for payment`);
							}
						} else {
							setPaymentData(res);
							displayNotificatoinSuccess('Card Details successfully updated.');
						}
					} else {
						if (type === 'OFFLINE_OR_CARD') {
							displayNotificatoinError('Failed to update Payment Method.');
						} else {
							displayNotificatoinError('Failed to update Card Details.');
						}
					}
				})
				.catch((err: any) => {
					if (type === 'OFFLINE_OR_CARD') {
						displayNotificatoinError('Failed to update Payment Method.');
					} else {
						displayNotificatoinError('Failed to update Card Details.');
					}
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const getTenant = (alias) => {
		dispatch(setLoading());
		return new Promise((resolve) => {
			kycApiService
				.getTenant(alias)
				.then((res: any) => {
					if (!res.error) {
						dispatch(setSelectedTenant(res));
					}
				})
				.catch((err: any) => {
					// setTenant({});
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const setOfflinePayment = (value) => {
		setPaymentMethod(value ? PAYMENT_TYPE.OFFLINE : PAYMENT_TYPE.CARD);
		setOpenConfirmDialog(true);
	};

	const closeDialog = () => {
		setPaymentMethod(paymentMethod === PAYMENT_TYPE.OFFLINE ? PAYMENT_TYPE.CARD : PAYMENT_TYPE.OFFLINE);
		setOpenConfirmDialog(false);
	};

	const displayNotificatoinSuccess = (msg: string) => {
		dispatch(
			setNotification({
				message: msg,
				type: NOTIFICATION_STATES.success,
			})
		);
	};

	const displayNotificatoinError = (msg: string) => {
		dispatch(
			setNotification({
				message: msg,
				type: NOTIFICATION_STATES.error,
			})
		);
	};

	const updateBilllingDetails = (billingAddress) => {
		setBillingData(billingAddress);
		updateBillingInfo({
			tenantAlias,
			productCode: tenantData?.product?.code || 'KYC',
			planCode: plan.code,
			currencyCode: tenantData.currency,
			billingSystem,
			billingAddress,
		});
	};

	const onCardSubmit = (data) => {
		setShowCardPopup(false);
		updateCardDetails(data.token);
	};

	const addCardDetails = () => {
		dispatch(setLoading());
		setShowCardPopup(true);
	};

	const onComponentReady = () => {
		dispatch(clearLoading());
	};

	const handleChargebeeError = () => {
		setShowCardPopup(false);
		displayNotificatoinError('Please enter valid Card Number, Expiry and CVV.');
	};

	if (plan?.salesChannel === 'AWS') {
		navigate('/tenant/:tenantAlias/account-overview');
	}

	return (
		<>
			<BillingAndPayment
				countryList={countryList}
				billingData={billingData}
				paymentData={paymentData}
				updateFailed={updateFailed}
				offlinePayment={offlinePayment}
				disableCardButtons={offlinePayment}
				portal='ADMIN'
				setOfflinePayment={setOfflinePayment}
				mandatoryFields={['organisationName', 'addressLine1', 'postCode', 'city', 'country']}
				updateBilllingData={updateBilllingDetails}
				addCard={addCardDetails}
			/>

			{showCardPopup && (
				<Chargebee
					open={showCardPopup}
					onComponentReady={onComponentReady}
					handleError={handleChargebeeError}
					onClose={() => setShowCardPopup(false)}
					onSubmit={onCardSubmit}
				/>
			)}

			<ConfirmDialog
				open={openConfirmDialog}
				titleImageType={'WARNING'}
				title={'Warning'}
				subTitle={
					offlinePayment
						? 'Are you sure you want to enable other payment method ?'
						: 'Are you sure you want to disable other payment method ?'
				}
				subTitle2={!offlinePayment && 'Your status will be updated as Awaiting for payment'}
				cancelButtonLabel={'No'}
				submitButtonLabel={'Yes'}
				onClose={closeDialog}
				onConfirm={handleOfflinePayment}
			/>
		</>
	);
};

export default BillingAndPaymentPage;
