import React, { useState, useEffect, useLayoutEffect, useMemo } from 'react';
import { styled, Box } from '@mui/material';

import { OutlinedInfoIcon } from 'shared_components/src/common/icons';
import { IIdenityOwnerDetails } from 'shared_components/src/service/models/verification';

/**
 * Styles
 */
const List = styled('ul')({
	fontFamily: 'Inter, sans-serif',
	color: '#515170',
	// width: '88%',
	// margin: '0 auto',
	margin: 0,
	// padding: '0rem 1rem',
	fontSize: '14px',
	lineHeight: '30px',
	':last-of-type': {
		borderBottom: 'none',
	},
	'& li': {
		fontFamily: 'Inter, sans-serif',
		'& span': {
			fontFamily: 'Inter, sans-serif',
			fontWeight: 600,
		},
	},
});

/**
 * Props
 */
interface IVerificationReferenceInformationProps {
	jobId: string;
	verificationId: string;
	tenantName: string;
	identityOwner: IIdenityOwnerDetails;
}

function VerificationReferenceInformation({
	jobId,
	verificationId,
	tenantName,
	identityOwner,
}: IVerificationReferenceInformationProps) {
	return (
		<Box display='flex' flexDirection='column'>
			<Box display='flex' alignItems='center'>
				<OutlinedInfoIcon />
				<span
					style={{
						fontFamily: 'Inter, sans-serif',
						fontWeight: 600,
						fontSize: '1rem',
						lineHeight: '1.5rem',
						color: '#515170',
						marginLeft: '10px',
					}}
				>
					Verification Reference Information
				</span>
			</Box>
			<Box sx={{ marginTop: '1rem' }} display='flex' alignItems='center'>
				<OutlinedInfoIcon fill='none' />
				<List sx={{ marginLeft: '10px', borderLeft: '4px solid #F0F0F4', lineHeight: '28px' }}>
					<li>
						Job ID: <span>{jobId}</span>
					</li>
					<li>
						Verification ID: <span>{verificationId}</span>
					</li>
					<li>
						Tenant Name: <span>{tenantName}</span>
					</li>
					<li>Identity Owner Information</li>
					<List sx={{ lineHeight: '28px' }}>
						<li>
							Given Name:{' '}
							<span>{identityOwner.givenName || identityOwner.firstName || '-'}</span>
						</li>
						<li>
							Middle Name: <span>{identityOwner.middleName || '-'}</span>
						</li>
						<li>
							Family Name:{' '}
							<span>{identityOwner.familyName || identityOwner.lastName || '-'}</span>
						</li>
						<li>
							Date of Birth: <span>{identityOwner.dateOfBirth || '-'}</span>
						</li>
					</List>
				</List>
			</Box>
		</Box>
	);
}

export default VerificationReferenceInformation;
