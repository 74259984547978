import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { map, cloneDeep, isEmpty } from 'lodash';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';

import { getCookie } from 'shared_components/src/service/common.service';
import { AddOns, DetailDialog } from 'shared_components/src/components/tenants';
import { NOTIFICATION_STATES } from 'shared_components/src/common/constants';

import kycApiService from '../../service/kycApi.service';
import { _getSelectedTenant } from '../../store/selectors';
import { setLoading, clearLoading, setNotification } from '../../store/common/actions';
import { updateMenu } from '../../store/menu/actions';
import { MENU } from '../../common/routes/menu';

const AddOnsPage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const featureFlags = useFlags();
	const ldClient = useLDClient();

	const selectedTenant = _getSelectedTenant();
	const [initialProductData, setInitialProductData] = useState([] as any);
	const [addOns, setAddOns] = useState([] as any);
	const [openThresholdModal, setOpenThresholdModal] = useState(false);
	const [dialogData, setDialogData] = useState({} as any);
	const [tenant, setTenant] = useState({} as any);

	useEffect(() => {
		dispatch(updateMenu(MENU.addOns));
	}, []);

	useEffect(() => {
		if (isEmpty(selectedTenant)) {
			const tenantData = JSON.parse(getCookie('selectedTenant'));
			getTenant(tenantData?.alias);
		} else {
			ldClient && ldClient.identify({ key: selectedTenant?.alias });
			setTenant(selectedTenant);
		}
	}, [selectedTenant]);

	useEffect(() => {
		if (!isEmpty(tenant)) {
			getTenantProducts();
		}
	}, [tenant]);

	const getTenant = async (alias) => {
		dispatch(setLoading());
		try {
			const res = await kycApiService.getTenant(alias);
			if (!res.error) {
				setTenant(res);
			}
		} catch (err) {
			console.error('Error fetching tenant:', err);
		} finally {
			dispatch(clearLoading());
		}
	};

	const getTenantProducts = async () => {
		dispatch(setLoading());
		try {
			const res = await kycApiService.getTenantProducts(tenant?.alias, tenant?.product?.code);
			if (res?.features) {
				setAddOnsData(res.features);
				setInitialProductData(res);
				if (res.features.repeatUserFraudCheck?.instance?.instanceId) {
					await getRUFCInstance(res.features.repeatUserFraudCheck.instance.instanceId);
				}
			} else {
				setAddOns([]);
			}
		} catch (err) {
			console.error('Error fetching tenant products:', err);
			setAddOns([]);
		} finally {
			dispatch(clearLoading());
		}
	};

	const getRUFCInstance = async (instanceId) => {
		if (!instanceId) return;

		try {
			const instanceRes = await kycApiService.getRUFCInstance(instanceId);
			const { fields: PIIFields } = instanceRes.identityOwner;
			const { fields: documentFields } = instanceRes.idDocument;

			setAddOns((prevAddOns) =>
				prevAddOns.map((addOn) =>
					addOn.key === 'repeatUserFraudCheck' ? { ...addOn, PIIFields, documentFields } : addOn
				)
			);
		} catch (error) {
			console.error('Error fetching RUFC instance:', error);
		}
	};

	const updateTenantAddOns = (data) => {
		console.log('saving data', data);
		dispatch(setLoading());
		return new Promise((resolve) => {
			kycApiService
				.updateTenantProducts(tenant?.alias, tenant?.product?.code, data)
				.then((res: any) => {
					if (res?.features) {
						setAddOnsData(res.features);
						setInitialProductData(res);
						displayNotificatoinSuccess('Add Ons successfully updated.');
					} else {
						displayNotificatoinError('Failed to update Add Ons.');
						setAddOnsData(initialProductData.features);
					}
				})
				.catch((err: any) => {
					displayNotificatoinError('Failed to update Add Ons.');
					setAddOnsData(initialProductData.features);
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const displayNotificatoinSuccess = (msg: string) => {
		dispatch(
			setNotification({
				message: msg,
				type: NOTIFICATION_STATES.success,
			})
		);
	};

	const displayNotificatoinError = (msg: string) => {
		dispatch(
			setNotification({
				message: msg,
				type: NOTIFICATION_STATES.error,
			})
		);
	};

	const setAddOnsData = (features) => {
		const products = map(features, (feature, key) => ({ key, ...feature }));
		const addOns = products
			.filter((product) => product.type === 'addOns')
			.map((addOn) => {
				addOn.isSelectable = addOn.key !== 'extendedDataRetention';
				addOn.hideCurrency = addOn.key !== 'currentAddressProofing';
				addOn.isDetailsButtonRequired =
					addOn.key !== 'extendedDataRetention' &&
					(addOn.key !== 'repeatUserFraudCheck' ||
						(featureFlags['RUFC-Settings'] === true && addOn.key === 'repeatUserFraudCheck'));

				if (addOn.key === 'businessVerification' || addOn.key === 'currentAddressProofing') {
					addOn.editablePerVerification = true;
				} else if (addOn.key === 'sanctionsAndPepVerification') {
					addOn.matchThresholdRequired = true;
					addOn.editablePerVerification = true;
				} else if (addOn.key === 'authoritativeVerification') {
					addOn.MVCheckRequired = true;
				}
				return addOn;
			});

		setAddOns(addOns);
	};

	const saveAddOns = () => {
		const data = cloneDeep(initialProductData);
		addOns.forEach((addOn) => {
			data.features[addOn.key].required = addOn.required;
			if (addOn.key === 'businessVerification' || addOn.key === 'currentAddressProofing') {
				data.features[addOn.key].requiredOverridable = addOn.requiredOverridable;
			} else if (addOn.key === 'sanctionsAndPepVerification') {
				data.features[addOn.key].requiredOverridable = addOn.requiredOverridable;
				data.features[addOn.key].threshold = addOn.threshold;
			} else if (addOn.key === 'authoritativeVerification') {
				data.features.authoritativeVerification.manualRequired = addOn.manualRequired;
			} else if (addOn.key === 'repeatUserFraudCheck') {
				data.features.repeatUserFraudCheck.instance.parameters = addOn.instance.parameters;
			}
		});
		updateTenantAddOns(data);
	};

	const cancelAddOnsUpdate = () => {
		const curPath = window.location.pathname;
		const redirectPath = curPath.substring(4).replace('add-ons', 'account-details');
		navigate(redirectPath);
	};

	const handleShowDetails = (type) => {
		updateDialogData(type);
		setOpenThresholdModal(true);
	};

	const updateDialogData = (type) => {
		const product = addOns.find((addOn) => type === addOn.key);
		setDialogData(product);
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>, type) => {
		const value = event.target.checked;
		updateAddOns(type, value);
	};

	const handleAddButtonAction = (type) => {
		updateAddOns(type, true);
	};

	const handleCancelButtonAction = (type) => {
		updateAddOns(type, false);
	};

	const updateAddOns = (type, value) => {
		setAddOns((prev) => {
			const addOns = prev.map((addOn) => {
				if (type === addOn.key) {
					addOn.required = value;
					if (type === 'authoritativeVerification') {
						if (!value) {
							addOn.manualRequired = false;
						}
					} else if (
						addOn.key === 'sanctionsAndPepVerification' ||
						addOn.key === 'businessVerification' ||
						addOn.key === 'currentAddressProofing'
					) {
						if (!value) {
							addOn.requiredOverridable = false;
						}
					}
				}
				return addOn;
			});
			return addOns;
		});
	};

	const handleDialogSave = ({ modalFor, addOnOverridable, isMVRequired, threshold, RUFCData = {} }) => {
		setAddOns((prev) => {
			const addOns = prev.map((addOn) => {
				if (modalFor === addOn.key) {
					addOn.requiredOverridable = addOnOverridable;

					if (addOn.key === 'authoritativeVerification') {
						addOn.manualRequired = isMVRequired;
					} else if (addOn.key === 'sanctionsAndPepVerification') {
						addOn.threshold = threshold / 100;
					} else if (addOn.key === 'repeatUserFraudCheck') {
						addOn.instance = {
							parameters: {
								...RUFCData,
							},
						};
					}
				}
				return addOn;
			});
			return addOns;
		});
		setOpenThresholdModal(false);
	};

	const handleDetailDialogClose = () => {
		setOpenThresholdModal(false);
	};

	return (
		<>
			<AddOns
				addOns={addOns}
				defaultCurrency={tenant?.currency}
				handleChange={handleChange}
				handleShowDetails={handleShowDetails}
				handleAddButtonAction={handleAddButtonAction}
				handleCancelButtonAction={handleCancelButtonAction}
				saveAddOns={saveAddOns}
				cancelAddOnsUpdate={cancelAddOnsUpdate}
			/>
			<DetailDialog
				open={openThresholdModal}
				type='addOns'
				checked={dialogData.required}
				editablePerVerification={dialogData.editablePerVerification}
				matchThresholdRequired={dialogData.matchThresholdRequired}
				thresholdLabel={'Match threshold'}
				thresholdValue={Number(`${dialogData.threshold * 100}`)}
				MVCheckRequired={dialogData.MVCheckRequired}
				manualRequired={dialogData.manualRequired}
				editableChecked={dialogData.requiredOverridable}
				modalFor={dialogData.key}
				title={dialogData.name}
				subTitle={dialogData.description}
				RUFCParameters={dialogData?.instance?.parameters || {}}
				RUFCFields={{
					PIIFields: dialogData?.PIIFields || [],
					documentFields: dialogData?.documentFields || [],
				}}
				handleSave={handleDialogSave}
				handleClose={handleDetailDialogClose}
			/>
		</>
	);
};

export default AddOnsPage;
