import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { getCookie, setCookie } from 'shared_components/src/service/common.service';
import { ProductConfiguration, APIDialog, WebhooksDialog } from 'shared_components/src/components/tenants';
import { NOTIFICATION_STATES } from 'shared_components/src/common/constants';

import kycApiService from '../../service/kycApi.service';
import { _getSelectedTenant } from '../../store/selectors';
import { setLoading, clearLoading, setNotification } from '../../store/common/actions';
import { updateMenu } from '../../store/menu/actions';
import { MENU } from '../../common/routes/menu';

const ProductConfigurationPage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const featureFlags = useFlags();
	const selectedTenant = _getSelectedTenant();
	const [showProductConfiguration, setShowProductConfiguration] = useState(false);
	const [initialSettings, setInitialSettings] = useState([] as any);
	const [initialUIConfigurations, setInitialUIConfigurations] = useState([] as any);
	const [initialIntegrations, setInitialIntegrations] = useState({
		APIKeys: {},
		webhooks: {},
	} as any);
	const [IPSettings, setIPSettings] = useState([] as any);
	const [dataSettings, setDataSettings] = useState({} as any);
	const [UISettings, setUISettings] = useState({
		channelSettings: {},
		whiteLabelSettings: {},
	} as any);
	const [accessTokens, setAccessTokens] = useState({} as any);
	const [integrations, setIntegrations] = useState({
		APIKeys: {},
		webhooks: {},
	} as any);
	const [APIDialogData, setAPIDialogData] = useState({} as any);
	const [webhookDialogData, setWebhookDialogData] = useState({} as any);
	const [refreshSettings, setRefreshSettings] = useState(false);
	const [initializeAPIDialog, setInitializeAPIDialog] = useState(false);
	const [initializeWebhookDialog, setInitializeWebhookDialog] = useState(false);
	const [openAPIDialog, setOpenAPIDialog] = useState(false);
	const [openWebhooksDialog, setOpenWebhooksDialog] = useState(false);
	const [tenant, setTenant] = useState({} as any);
	const [APIDialogDisabled, setAPIDialogDisabled] = useState(false);
	const [proofingTags, setProofingTags] = useState([] as any);
	const [proofingTag, setProofingTag] = useState('IDENTITY_PROOFING');

	const isV2Enabled = Boolean(featureFlags['document-proofing-feature']);

	useEffect(() => {
		dispatch(updateMenu(MENU.productConfiguration));
	}, []);

	useEffect(() => {
		if (isEmpty(selectedTenant)) {
			const tenantData = JSON.parse(getCookie('selectedTenant'));
			getTenant(tenantData?.alias);
		} else {
			setupTenantData(selectedTenant);
		}
	}, [selectedTenant]);

	useEffect(() => {
		if (isV2Enabled && !isEmpty(tenant)) {
			getProofingFrameworks(proofingTag);
		}
	}, [proofingTags, tenant]);

	const setupTenantData = (tenant) => {
		setTenant(tenant);
		const { configurations } = tenant || [];
		setInitialUIConfigurations(tenant);
		if (!isEmpty(configurations)) {
			setShowProductConfiguration(true);
			const portalUIsettings = configurations.find((el) => el.channel === 'Mobile') || {};
			setUISettings((prev) => ({
				...prev,
				whiteLabelSettings: portalUIsettings,
			}));
			getTenantSettings(tenant);
			if (isV2Enabled) {
				getProofingTags();
			}
			getIntegrations(tenant);
		} else {
			setShowProductConfiguration(false);
		}
	};

	const getTenant = (alias) => {
		dispatch(setLoading());
		return new Promise((resolve) => {
			kycApiService
				.getTenant(alias)
				.then((res: any) => {
					if (!res.error) {
						setupTenantData(res);
					}
				})
				.catch((err: any) => {
					// setTenant({});
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const getIntegrations = (tenant) => {
		return new Promise((resolve) => {
			kycApiService
				.getTenantIntegrations(tenant?.alias)
				.then((res: any) => {
					if (!res.error) {
						setIntegrations((prev) => ({
							...prev,
							APIKeys: res,
						}));
						setInitialIntegrations((prev) => ({
							...prev,
							APIKeys: res,
						}));
					}
				})
				.catch((err: any) => {
					setIntegrations((prev) => ({
						...prev,
						APIKeys: {},
					}));
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const getTenantSettings = (tenant) => {
		dispatch(setLoading());
		return new Promise((resolve) => {
			kycApiService
				.getTenantProducts(tenant?.alias, tenant?.product?.code)
				.then((res: any) => {
					if (!res.error) {
						setSettingsData(res);
						setInitialSettings(res);
					}
				})
				.catch((err: any) => {
					// setSettingsData([]);
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const getProofingTags = () => {
		dispatch(setLoading());
		return new Promise((resolve) => {
			kycApiService
				.getProofingTags()
				.then((res: any) => {
					if (!res.error) {
						const tags = res.map((tag) => ({ display: tag.name, value: tag.code }));
						setProofingTags(tags);
					}
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const getProofingFrameworks = (proofingTag) => {
		dispatch(setLoading());
		return new Promise((resolve) => {
			kycApiService
				.getProofingFrameworks(tenant?.alias, proofingTag)
				.then((res: any) => {
					if (!res.error) {
						setIPSettings(res);
					}
				})
				.catch((err: any) => {
					console.error('get Proofing Frameworks error', err);
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const updateChannelSettings = (request) => {
		dispatch(setLoading());
		return new Promise(async (resolve, reject) => {
			kycApiService
				.updateTenant(tenant?.alias, request)
				.then((res: any) => {
					if (res.error) {
						displayNotificatoinError('Failed to update Product Configuration.');
					} else {
						displayNotificatoinSuccess('Product Configuration successfully updated.');
					}
				})
				.catch((err: any) => {
					reject({ status: 'error' });
					displayNotificatoinError('Failed to update Product Configuration.');
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const updateProofingFrameworks = (data) => {
		dispatch(setLoading());
		return new Promise((resolve) => {
			kycApiService
				.updateProofingFrameworks(tenant?.alias, {
					proofingTag,
					proofingFrameworks: data,
				})
				.then((res: any) => {
					if (!res.error) {
						setIPSettings(res);
						displayNotificatoinSuccess('Product Configuration successfully updated.');
					}
				})
				.catch((err: any) => {
					displayNotificatoinError('Failed to update Product Configuration.');
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const updateProductConfiguration = (data, showAlert = true) => {
		dispatch(setLoading());
		return new Promise((resolve) => {
			kycApiService
				.updateTenantProducts(tenant?.alias, tenant?.product?.code, data)
				.then((res: any) => {
					if (!res.error) {
						setSettingsData(res);
						setInitialSettings(res);
						setRefreshSettings(true);
						setTimeout(() => setRefreshSettings(false), 150);
						if (showAlert) {
							displayNotificatoinSuccess('Product Configuration successfully updated.');
						}
					} else {
						if (showAlert) {
							displayNotificatoinError('Failed to update Product Configuration.');
						}
					}
				})
				.catch((err: any) => {
					if (showAlert) {
						displayNotificatoinError('Failed to update Product Configuration.');
					}
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const createNewAPI = (data) => {
		dispatch(setLoading());
		return new Promise((resolve) => {
			kycApiService
				.createNewAPIIntegrations(tenant?.alias, data)
				.then((res: any) => {
					if (!res.error) {
						setAccessTokens(res);
						setAPIDialogDisabled(true);
						displayNotificatoinSuccess('API Key successfully created.');
						getIntegrations(tenant);
					} else {
						displayNotificatoinError('Failed to create API Key.');
					}
				})
				.catch((err: any) => {
					setAccessTokens({});
					displayNotificatoinError('Failed to create API Key.');
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const updateAPI = (data) => {
		const requestData = {
			...data,
			status: APIDialogData.status,
			accessKey: APIDialogData.accessKey,
		};
		dispatch(setLoading());
		return new Promise((resolve) => {
			kycApiService
				.updateAPIIntegrations(tenant?.alias, requestData)
				.then((res: any) => {
					if (!res.error) {
						displayNotificatoinSuccess('API Key successfully updated.');
						getIntegrations(tenant);
						clearAPIDiaglog();
					} else {
						displayNotificatoinError('Failed to update API Key.');
					}
				})
				.catch((err: any) => {
					setAccessTokens({});
					displayNotificatoinError('Failed to update API Key.');
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const deleteAPI = () => {
		dispatch(setLoading());
		return new Promise((resolve) => {
			kycApiService
				.deleteAPIIntegrations(tenant?.alias, APIDialogData.accessKey)
				.then((res: any) => {
					if (!res.error) {
						displayNotificatoinSuccess('API Key successfully deleted.');
						getIntegrations(tenant);
						clearAPIDiaglog();
					} else {
						displayNotificatoinError('Failed to deleted API Key.');
					}
				})
				.catch((err: any) => {
					displayNotificatoinError('Failed to deleted API Key.');
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const updateWebhook = (data) => {
		initialSettings.callBack = data;
		updateProductConfiguration(initialSettings);
		setOpenWebhooksDialog(false);
	};

	const setSettingsData = (settings) => {
		setDataSettings(settings.dataRetentionPolicy);
		if (!isV2Enabled) {
			setIPSettings(settings.identityProofing);
		}
		setUISettings((prev) => ({
			...prev,
			channelSettings: settings.identitySubmissionChannel,
		}));
		setIntegrations((prev) => ({
			...prev,
			webhooks: settings.callBack,
		}));
	};

	const saveProductConfiguration = (settingType, data) => {
		if (isV2Enabled && settingType === 'IPSettings') {
			updateProofingFrameworks(data);
		} else if (settingType === 'IPSettings') {
			initialSettings.identityProofing = data;
			updateProductConfiguration(initialSettings);
		} else if (settingType === 'dataSettings') {
			initialSettings.dataRetentionPolicy = {
				completedVerifications: { period: data.completedVerifications },
				incompletedVerifications: { period: data.incompletedVerifications },
			};
			updateProductConfiguration(initialSettings);
		} else if (settingType === 'UISettings') {
			initialSettings.identitySubmissionChannel = data.channelSettings;
			initialUIConfigurations.configurations.forEach((configuration) => {
				const { style, logo } = data.whiteLabelSettings;
				if (!isEmpty(logo)) {
					configuration.logo = logo;
				}

				if (configuration.channel === 'Portal') {
					const { light: main, dark } = style.primaryColor;
					configuration.style.primaryColor = { main, dark };
				} else {
					configuration.style.primaryColor = style.primaryColor;
				}
				return configuration;
			});
			updateChannelSettings(initialUIConfigurations);
			updateProductConfiguration(initialSettings, false);
		}
	};

	const handleNewAPI = () => {
		setOpenAPIDialog(true);
		initializeAPIPopup();
	};

	const clearAPIDiaglog = () => {
		setAPIDialogDisabled(false);
		setOpenAPIDialog(false);
		setAccessTokens({});
		setAPIDialogData({});
		initializeAPIPopup();
	};

	const initializeAPIPopup = () => {
		setInitializeAPIDialog(true);
		setTimeout(() => setInitializeAPIDialog(false), 150);
	};

	const clearWebhookDialog = () => {
		setWebhookDialogData({});
		setOpenWebhooksDialog(false);
	};

	const handleNewWebhook = () => {
		setOpenWebhooksDialog(true);
		initializeWebhookPopup();
	};

	const initializeWebhookPopup = () => {
		setInitializeWebhookDialog(true);
		setTimeout(() => setInitializeWebhookDialog(false), 150);
	};

	const displayNotificatoinSuccess = (msg: string) => {
		dispatch(
			setNotification({
				message: msg,
				type: NOTIFICATION_STATES.success,
			})
		);
	};

	const displayNotificatoinError = (msg: string) => {
		dispatch(
			setNotification({
				message: msg,
				type: NOTIFICATION_STATES.error,
			})
		);
	};

	const handleShowDetails = (type, key) => {
		if (type === 'API') {
			const data = integrations.APIKeys.items.find((item) => item.accessKey === key);
			setAPIDialogData(data);
			setAccessTokens({ accessKey: key });
			setOpenAPIDialog(true);
		} else {
			const data = integrations.webhooks;
			setWebhookDialogData(data);
			setOpenWebhooksDialog(true);
		}
	};

	const selectedProofingTag = (tag) => {
		getProofingFrameworks(tag);
		setProofingTag(tag);
	};

	return (
		<>
			<ProductConfiguration
				isV2Enabled={isV2Enabled}
				showProductConfiguration={showProductConfiguration}
				proofingTags={proofingTags}
				proofingTag={proofingTag}
				selectedProofingTag={selectedProofingTag}
				IPSettings={IPSettings}
				dataSettings={dataSettings}
				portalUIsettings={UISettings}
				integrationSettings={integrations}
				refreshSettings={refreshSettings}
				showVerificationUserJourney={true}
				handleNewWebhook={handleNewWebhook}
				handleNewAPI={handleNewAPI}
				saveProductConfiguration={saveProductConfiguration}
				handleShowDetails={handleShowDetails}
			/>
			<APIDialog
				open={openAPIDialog}
				title={'API Keys'}
				subTitle={
					'Truuth offers both end to end API’s such as identity verification and API’s for more simple use cases including individual steps within our identity verification process.'
				}
				initializeDialog={initializeAPIDialog}
				dialogData={APIDialogData}
				accessTokens={accessTokens}
				disabled={APIDialogDisabled}
				closeDialog={clearAPIDiaglog}
				createNewAPI={createNewAPI}
				handleDelete={deleteAPI}
				handleUpdate={updateAPI}
			/>
			<WebhooksDialog
				open={openWebhooksDialog}
				title={'Webhooks'}
				subTitle={
					'When the identity verification is complete an HTTP POST request is sent to your specified callback URL containing an application/JSON formatted string with the verification result in the body.'
				}
				initializeDialog={initializeWebhookDialog}
				dialogData={webhookDialogData}
				closeDialog={clearWebhookDialog}
				createNewWebhook={updateWebhook}
				handleUpdate={updateWebhook}
			/>
		</>
	);
};

export default ProductConfigurationPage;
