import React from 'react';

export function Fail() {
	return (
		<svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M8.72634 4.33398L6.99967 6.06065L5.27301 4.33398L4.33301 5.27398L6.05967 7.00065L4.33301 8.72732L5.27301 9.66732L6.99967 7.94065L8.72634 9.66732L9.66634 8.72732L7.93967 7.00065L9.66634 5.27398L8.72634 4.33398ZM6.99967 0.333984C3.31301 0.333984 0.333008 3.31398 0.333008 7.00065C0.333008 10.6873 3.31301 13.6673 6.99967 13.6673C10.6863 13.6673 13.6663 10.6873 13.6663 7.00065C13.6663 3.31398 10.6863 0.333984 6.99967 0.333984ZM6.99967 12.334C4.05967 12.334 1.66634 9.94065 1.66634 7.00065C1.66634 4.06065 4.05967 1.66732 6.99967 1.66732C9.93967 1.66732 12.333 4.06065 12.333 7.00065C12.333 9.94065 9.93967 12.334 6.99967 12.334Z'
				fill='#70708F'
			/>
		</svg>
	);
}
