import React from 'react';

export function Pass() {
	return (
		<svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M10.0597 4.05398L5.66634 8.44732L3.27301 6.06065L2.33301 7.00065L5.66634 10.334L10.9997 5.00065L10.0597 4.05398ZM6.99967 0.333984C3.31967 0.333984 0.333008 3.32065 0.333008 7.00065C0.333008 10.6807 3.31967 13.6673 6.99967 13.6673C10.6797 13.6673 13.6663 10.6807 13.6663 7.00065C13.6663 3.32065 10.6797 0.333984 6.99967 0.333984ZM6.99967 12.334C4.05301 12.334 1.66634 9.94732 1.66634 7.00065C1.66634 4.05398 4.05301 1.66732 6.99967 1.66732C9.94634 1.66732 12.333 4.05398 12.333 7.00065C12.333 9.94732 9.94634 12.334 6.99967 12.334Z'
				fill='#70708F'
			/>
		</svg>
	);
}
