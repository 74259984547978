import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, Typography, styled } from '@mui/material';
import ImageViewer from 'react-simple-image-viewer';

import { clearLoading, setLoading } from '../../store/actions';
import KycApiService from '../../service/kycApi.service';

/**
 * STYLES
 */
const Container = styled(Box)({
	'& h3': {
		color: '#515170',
		fontSize: '18px',
		lineHeight: '24px',
		marginBottom: '1.5rem',
		fontWeight: 400,
		fontFamily: 'Inter, sans-serif',
	},
});

const ImageContainer = styled('div')({
	position: 'relative',
	width: '80%',

	'&:hover': {
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		cursor: 'pointer',

		'& img': {
			filter: 'brightness(30%)',
		},

		'& img:first-of-type': {
			display: 'inline',
			filter: 'brightness(100%)',
			zIndex: 100,
		},
	},
});

const Text = styled(Typography)({
	margin: 0,
	padding: 0,
	color: '#515170',
	fontWeight: 400,
	fontSize: '14px',
	fontFamily: 'Inter, sans-serif',
	marginBottom: '1rem',
	lineHeight: '24px',
});

const Image = styled('img')({
	marginBottom: '2px',
	textAlign: 'right',
});

/**
 * TYPES
 */
interface IProps {
	faceImage?: string;
	documentImages: string[];
	label?: boolean;
	header?: string;
}

/**
 * MAIN COMPONENT
 */
const MVImagesV2 = ({ faceImage, documentImages, label = false, header }: IProps) => {
	const dispatch = useDispatch();

	const [currentImage, setCurrentImage] = useState(0);
	const [isViewerOpen, setIsViewerOpen] = useState(false);
	const [images, setImages] = useState<string[]>([]);
	const face = faceImage ? `data:image/png;base64,${faceImage}` : '';

	useEffect(() => {
		documentImages.map((image, index) => {
			getImageData(image).then((res: string) => {
				if (res) {
					setImages((prev) => (index === 0 ? [res, ...prev] : [...prev, res]));
				}
			});
		});
	}, [documentImages.length]);

	/**
	 * Get document images
	 */
	const getImageData = (url: string): Promise<string> => {
		return new Promise((resolve) => {
			// dispatch(setLoading());
			KycApiService.getImageDataFromS3(url)
				.then(async (res) => {
					try {
						const blobToBase64 = (blob) => {
							const reader = new FileReader();
							reader.readAsDataURL(blob);
							return new Promise((reslve) => {
								reader.onloadend = () => {
									reslve(reader.result);
								};
							});
						};
						if (res) {
							const imgData = (await blobToBase64(res)) as string;
							resolve(imgData);
						} else {
							resolve('');
						}
					} catch (e) {
						resolve('');
					}
				})
				.catch((err) => {
					resolve('');
				})
				.finally(() => {
					dispatch(clearLoading());
				});
		});
	};

	const openImageViewer = useCallback((index) => {
		setCurrentImage(index);
		setIsViewerOpen(true);
	}, []);

	const closeImageViewer = () => {
		setCurrentImage(0);
		setIsViewerOpen(false);
	};

	const handleLinkClick = (url: string) => {
		window.open(`${window.location.hostname}/kyc/image/${url}`, '_blank', 'height=700,width=700');
	};

	return (
		<Container>
			<Typography
				style={{
					fontFamily: 'Inter, sans-serif',
					fontSize: '14px',
					lineHeight: '24px',
					fontWeight: 500,
					color: '#515170',
					marginBottom: '8px',
				}}
			>
				{header || 'Captured Images'}
			</Typography>
			{images.map((src, index) => (
				<React.Fragment key={src}>
					<ImageContainer key={src} onClick={() => openImageViewer(index)}>
						<Image
							src={`data:image/svg+xml,%3Csvg width='96' height='96' viewBox='0 0 96 96' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1829_16429)'%3E%3Cpath d='M60 12L69.2 21.2L57.64 32.68L63.32 38.36L74.8 26.8L84 36V12H60ZM12 36L21.2 26.8L32.68 38.36L38.36 32.68L26.8 21.2L36 12H12V36ZM36 84L26.8 74.8L38.36 63.32L32.68 57.64L21.2 69.2L12 60V84H36ZM84 60L74.8 69.2L63.32 57.64L57.64 63.32L69.2 74.8L60 84H84V60Z' fill='white' fill-opacity='0.6'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1829_16429'%3E%3Crect width='96' height='96' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E`}
							width='100%'
							key={`zoom-${index}`}
							alt={'zoom out icon'}
							sx={{
								position: 'absolute',
								top: '50%',
								left: '50%',
								transform: 'translate(-50%, -50%)',
								width: 96,
								height: 96,
								display: 'none',
							}}
						/>
						<Image src={src} width='100%' key={index} alt={'document image'} />
					</ImageContainer>
					<Box
						display='flex'
						justifyContent={src && label ? 'space-between' : 'flex-end'}
						alignItems='center'
						sx={{ width: '80%' }}
					>
						{src && label && <Text>{`${index === 0 ? 'Front' : 'Back'} Image`}</Text>}
						<Text
							sx={{
								color: '#470D82',
								cursor: 'pointer',
								'&:hover': {
									textDecoration: 'underline',
								},
							}}
							onClick={() => handleLinkClick(documentImages[index])}
						>
							Click to open
						</Text>
					</Box>
				</React.Fragment>
			))}
			{face && (
				<React.Fragment>
					<ImageContainer onClick={() => openImageViewer(images.length)}>
						<Image
							src={`data:image/svg+xml,%3Csvg width='96' height='96' viewBox='0 0 96 96' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1829_16429)'%3E%3Cpath d='M60 12L69.2 21.2L57.64 32.68L63.32 38.36L74.8 26.8L84 36V12H60ZM12 36L21.2 26.8L32.68 38.36L38.36 32.68L26.8 21.2L36 12H12V36ZM36 84L26.8 74.8L38.36 63.32L32.68 57.64L21.2 69.2L12 60V84H36ZM84 60L74.8 69.2L63.32 57.64L57.64 63.32L69.2 74.8L60 84H84V60Z' fill='white' fill-opacity='0.6'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1829_16429'%3E%3Crect width='96' height='96' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E`}
							width='100%'
							key={`zoom-${face}`}
							alt={'zoom out icon'}
							sx={{
								position: 'absolute',
								top: '50%',
								left: '50%',
								transform: 'translate(-50%, -50%)',
								width: 96,
								height: 96,
								display: 'none',
							}}
						/>
						<Image
							src={face}
							width='100%'
							key={2}
							style={{ marginBottom: '2px' }}
							alt='face image'
						/>
					</ImageContainer>
					<Text>Face scan image - Click to zoom in</Text>
				</React.Fragment>
			)}
			{isViewerOpen && (
				<ImageViewer
					src={[...images]}
					currentIndex={currentImage}
					disableScroll={true}
					closeOnClickOutside={true}
					onClose={closeImageViewer}
					backgroundStyle={{
						backgroundColor: 'rgba(0,0,0,0.9)',
						zIndex: 9999,
					}}
				/>
			)}
		</Container>
	);
};
export default MVImagesV2;
