import React from 'react';

export function Warning() {
	return (
		<svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M6.33301 9.00065H7.66634V10.334H6.33301V9.00065ZM6.33301 3.66732H7.66634V7.66732H6.33301V3.66732ZM6.99301 0.333984C3.31301 0.333984 0.333008 3.32065 0.333008 7.00065C0.333008 10.6807 3.31301 13.6673 6.99301 13.6673C10.6797 13.6673 13.6663 10.6807 13.6663 7.00065C13.6663 3.32065 10.6797 0.333984 6.99301 0.333984ZM6.99967 12.334C4.05301 12.334 1.66634 9.94732 1.66634 7.00065C1.66634 4.05398 4.05301 1.66732 6.99967 1.66732C9.94634 1.66732 12.333 4.05398 12.333 7.00065C12.333 9.94732 9.94634 12.334 6.99967 12.334Z'
				fill='#70708F'
			/>
		</svg>
	);
}
