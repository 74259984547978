import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { setSelectedTenant, setTenantAlias, setAccountDetailsMode } from '../../store/tenant/actions';
import { _getSelectedTenant, _getCountryList, _getAccountDetailsMode } from '../../store/selectors';
import kycApiService from '../../service/kycApi.service';
import { getCookie, setCookie } from 'shared_components/src/service/common.service';
import { AccountDetails } from 'shared_components/src/components/tenants';
import { NOTIFICATION_STATES } from 'shared_components/src/common/constants';
import { TENANT_MODE, TENANT_PLAN, TENANT_STATUS } from 'shared_components/src/service/models/tenant';
import {
	ProductConfiguration,
	Features,
	AddOns,
	BillingPayment,
	PlanIcon,
} from 'shared_components/src/common/icons';
import { setLoading, clearLoading, setNotification } from '../../store/common/actions';
import { updateMenu } from '../../store/menu/actions';
import { MENU } from '../../common/routes/menu';

const AccountDetailsPage = () => {
	const selectedTenant = _getSelectedTenant();
	const mode = _getAccountDetailsMode();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const countryList = _getCountryList();

	const [tenant, setTenant] = useState({} as any);

	useEffect(() => {
		if (isEmpty(selectedTenant)) {
			const tenantData = JSON.parse(getCookie('selectedTenant'));
			setTenantAlias(tenantData?.alias);
			getTenant(tenantData?.alias);
		} else {
			setTenantAlias(selectedTenant?.alias);
			setTenant(selectedTenant);
		}
	}, [selectedTenant]);

	const getTenant = (alias) => {
		dispatch(setLoading());
		return new Promise((resolve) => {
			kycApiService
				.getTenant(alias)
				.then((res: any) => {
					if (!res.error) {
						setTenant(res);
						const selectedTenant = {
							alias: res.alias,
							organisationName: res.organisationName,
							product: res.product,
							currency: res.currency,
						};
						dispatch(setSelectedTenant(res));
						setCookie('selectedTenant', JSON.stringify(selectedTenant));
					}
				})
				.catch((err: any) => {
					// setTenant({});
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const handleUpdateTenantDetails = (request) => {
		// TODO: Get the data from selected product
		if (isEmpty(request.currency)) {
			request.currency = 'AUD';
		}
		if (isEmpty(request.product)) {
			request.product = {
				code: 'KYC',
				name: 'KYC',
				plan: TENANT_PLAN.PLAN_FOUNDATION,
			};
		}

		dispatch(setLoading());
		return new Promise(async (resolve, reject) => {
			kycApiService
				.updateTenant(request.alias, request)
				.then((res: any) => {
					dispatch(clearLoading());
					if (res.error) {
						displayNotificatoinError(res.error.message);
						dispatch(setAccountDetailsMode(TENANT_MODE.EDIT));
					} else {
						const selectedTenant = {
							alias: res.alias,
							organisationName: res.organisationName,
							product: res.product,
							currency: res.currency,
						};
						dispatch(setSelectedTenant(res));
						setCookie('selectedTenant', JSON.stringify(selectedTenant));
						resolve({ status: 'success' });
						displayNotificatoinSuccess('Tenant successfully updated.');
						dispatch(setAccountDetailsMode(TENANT_MODE.VIEW));
					}
				})
				.catch((err: any) => {
					dispatch(clearLoading());
					reject({ status: 'error' });
					displayNotificatoinError('Tenant updation failed.');
					dispatch(setAccountDetailsMode(TENANT_MODE.EDIT));
				});
		});
	};

	const showSubscribeButton = useMemo(() => {
		return tenant.status === TENANT_STATUS.CANCELLED;
	}, [tenant]);

	const handleSubscription = () => {
		navigate(`/tenant/${tenant.alias}/plan/subscribe`);
	};

	const displayNotificatoinSuccess = (msg: string) => {
		dispatch(
			setNotification({
				message: msg,
				type: NOTIFICATION_STATES.success,
			})
		);
	};

	const displayNotificatoinError = (msg: string) => {
		dispatch(
			setNotification({
				message: msg,
				type: NOTIFICATION_STATES.error,
			})
		);
	};

	useEffect(() => {
		dispatch(updateMenu(MENU.accountDetails));
	}, []);

	return (
		<>
			<AccountDetails
				modeData={mode}
				tenant={tenant}
				countryList={countryList}
				showSubscribeButton={showSubscribeButton}
				handleSubscription={handleSubscription}
				updateTenantDetails={handleUpdateTenantDetails}
			></AccountDetails>
		</>
	);
};

export default AccountDetailsPage;
