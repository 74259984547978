import React from 'react';
import { Grid, Box } from '@mui/material';

import { IIdenityOwnerDetails } from 'shared_components/src/service/models/verification';
import { VERIFIER_JOB_TYPES } from 'shared_components/src/service/models/manual-verification';

import { _getVerifierJobState } from '../../store/selectors';

import VerificationReferenceInformation from './VerificationReferenceInformation';
import MVChecksResult from './MVChecksResult';
import MVAuthenticityV2 from './MVAuthenticityV2';
import MVFaceMatchV2 from './MVFaceMatchV2';
import MVOcrV2 from './MVOcrV2';
import MVNameMatchV2 from './MVNameMatchV2';
import MVDvsV2 from './MVDvsV2';
import ProviderInfo from './ProviderInfo';
import DVSAdditionalInfo from './DVSAdditionalInfo';

/**
 * Props
 */
interface IProps {
	completeJob: (data: any) => void;
	escalateJob: (jobId: string) => void;
}

export default function MVJobLayout({ completeJob, escalateJob }: IProps) {
	const job = _getVerifierJobState();
	const jobDetailInput = job?.jobDetail?.input;

	const renderJobDetails = (data) => {
		switch (job?.jobType) {
			case VERIFIER_JOB_TYPES.authenticity:
				return (
					<Grid item xs={12} md={12}>
						<MVAuthenticityV2 completeJob={completeJob} escalateJob={escalateJob} />
					</Grid>
				);
			case VERIFIER_JOB_TYPES.faceMatch:
				return (
					<Grid item xs={12} md={12}>
						<MVFaceMatchV2 completeJob={completeJob} escalateJob={escalateJob} />
					</Grid>
				);
			case VERIFIER_JOB_TYPES.ocr:
				return (
					<Grid item xs={12} md={12}>
						<MVOcrV2 completeJob={completeJob} escalateJob={escalateJob} />
					</Grid>
				);
			case VERIFIER_JOB_TYPES.nameMatch:
				return (
					<Grid item xs={12} md={12}>
						<MVNameMatchV2 completeJob={completeJob} escalateJob={escalateJob} />
					</Grid>
				);
			case VERIFIER_JOB_TYPES.dvs:
				return (
					<Grid item xs={12} md={12}>
						<MVDvsV2 completeJob={completeJob} escalateJob={escalateJob} />
					</Grid>
				);
			default:
				return null;
		}
	};

	const renderCheckName = (jobType: VERIFIER_JOB_TYPES) => {
		switch (jobType) {
			case VERIFIER_JOB_TYPES.authenticity:
				return 'Authenticity';
			case VERIFIER_JOB_TYPES.faceMatch:
				return 'Face Match';
			case VERIFIER_JOB_TYPES.ocr:
				return 'Data Extraction';
			case VERIFIER_JOB_TYPES.nameMatch:
				return 'Name Match';
			case VERIFIER_JOB_TYPES.dvs:
				return 'Official Source';
			default:
				return '';
		}
	};

	const renderJobName = (jobType: VERIFIER_JOB_TYPES) => {
		switch (jobType) {
			case VERIFIER_JOB_TYPES.authenticity:
				return 'Authenticity Check';
			case VERIFIER_JOB_TYPES.faceMatch:
				return 'Face Match';
			case VERIFIER_JOB_TYPES.ocr:
				return 'OCR Check';
			case VERIFIER_JOB_TYPES.nameMatch:
				return 'Name Match';
			case VERIFIER_JOB_TYPES.dvs:
				return 'Official Source Check';
			default:
				return '';
		}
	};

	const DVSStatusMessages = () => {
		if (job?.jobType === VERIFIER_JOB_TYPES.dvs) {
			const reasons = {
				NOT_VERIFIED: {
					key: 'NOT_VERIFIED',
					text: 'No Match',
				},
				SERVER_ERROR: {
					key: 'SERVER_ERROR',
					text: 'Service not available',
				},
				VALIDATION_ERROR: {
					key: 'VALIDATION_ERROR',
					text: 'Document validation error',
				},
			};
			const data = reasons[jobDetailInput?.statusReason] || {};
			return [{ type: jobDetailInput?.statusReason, name: data.text || '' }];
		}
		return [];
	};

	if (!job) {
		return null;
	}

	return (
		<Box sx={{ border: '1px solid #E8E8E8', borderRadius: '6px 6px 0 0' }}>
			<div
				style={{
					width: '100%',
					backgroundColor: '#E8E8EE',
					padding: '8px 16px',
					borderRadius: '6px 6px 0 0',
				}}
			>
				<span
					style={{
						fontFamily: 'Inter, sans-serif',
						fontWeight: 500,
						fontSize: '1rem',
						lineHeight: '1.5rem',
						color: '#20203A',
						display: 'block',
					}}
				>
					{`Assigned Job ${job.jobId} : ${renderJobName(job.jobType)}`}
				</span>
			</div>
			<Grid
				container
				// spacing={1}
				style={{ padding: '1.5rem', margin: 0, background: 'white', overflow: 'hidden' }}
			>
				<Grid
					container
					// spacing={1}
					style={{ borderBottom: '1px solid #EDEDF3', paddingBottom: '24px' }}
				>
					<Grid item xs={12} md={6}>
						<VerificationReferenceInformation
							jobId={job.jobId}
							verificationId={jobDetailInput?.verificationId || ''}
							tenantName={jobDetailInput?.tenantName || ''}
							identityOwner={(jobDetailInput?.identityOwner as IIdenityOwnerDetails) || {}}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<MVChecksResult
							checkName={renderCheckName(job.jobType)}
							checkScore={jobDetailInput?.score || jobDetailInput?.similarity}
							checkStatus={jobDetailInput?.status}
							subChecks={jobDetailInput?.subChecks || DVSStatusMessages() || []}
						/>
						{job.jobType === VERIFIER_JOB_TYPES.dvs && (
							<ProviderInfo
								sourceCheckProvider={jobDetailInput?.sourceCheckProvider}
								requestNumber={jobDetailInput?.requestNumber}
							/>
						)}
					</Grid>
				</Grid>
				{job.jobType === VERIFIER_JOB_TYPES.dvs && (
					<DVSAdditionalInfo detail={jobDetailInput?.detail} />
				)}
				{renderJobDetails(jobDetailInput)}
			</Grid>
		</Box>
	);
}
