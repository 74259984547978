import React from "react";
import { Box, IconButton, Grid, InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { makeStyles } from "@mui/styles";
import { TruuthButton } from "shared_components/src/components/common";
import { RefreshIcon } from "shared_components/src/common/icons";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: "16px",

    "& .MuiButton-root": {
      backgroundColor: "transparent !important",
    },

    "& .refresh-wrapper": {
      "& svg": {
        width: "24px !important",
        height: "24px !important",
      },
    },

    "@media screen and (max-width: 425px)": {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },

  userSearch: {
    width: "448px",
    display: "flex",
    border: `1px solid #E1E1EA`,
    backgroundColor: "#fff",
    borderRadius: "6px",

    "& .MuiButtonBase-root": {
      padding: "8px",
    },

    "& .MuiInputBase-root": {
      width: "100%",
      paddingRight: "16px",
    },

    "@media screen and (max-width: 425px)": {
      width: "100%",

      "& .MuiInputBase-root": {
        fontSize: "14px",

        "& input:placeholder-shown": {
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
    },
  },

  userActions: {
    display: "flex",
    alignItems: "center",

    "& .MuiIconButton-root": {
      padding: "8px",

      "&:hover": {
        background: `#F8EFFB !important`,

        "& path": {
          fill: "#8C07DD",
        },
      },
      "&:active": {
        background: "#F3E2F8",
      },
    },
  },
}));

const TenantSearch = ({
  searchString,
  setSearchString,
  showAdvanceFilters,
  onSearch,
  toggleFilters,
  handleRefreshTable,
}) => {
  const classes = useStyles();

  const handleKeyEvent = (event) => {
    setSearchString(event.target.value);

    if (event.keyCode === 13) {
      onSearch(searchString);
    }
  };

  return (
    <Box className={classes.root}>
      <Grid item className={classes.userSearch}>
        <IconButton
          type="submit"
          aria-label="search"
          onClick={() => onSearch(searchString)}
        >
          <SearchIcon />
        </IconButton>
        <InputBase
          id="search-tenant"
          placeholder="Search by tenant name, tenant alias, admin... "
          inputProps={{ "aria-label": "search tenants" }}
          onKeyUp={handleKeyEvent}
        />
      </Grid>
      <Grid className={classes.userActions}>
        <TruuthButton
          buttontype="text"
          size="medium"
          onClick={() => toggleFilters(showAdvanceFilters)}
        >
          {!showAdvanceFilters ? "Advanced Filter" : "Close Advanced Filter"}
        </TruuthButton>
        <Grid className={"refresh-wrapper"}>
          <IconButton
            type="submit"
            aria-label="refresh"
            onClick={handleRefreshTable}
          >
            <RefreshIcon fill={"#470D82"} />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TenantSearch;
