import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { map, isEmpty } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { getCookie, setCookie } from 'shared_components/src/service/common.service';
import { Features, DetailDialog } from 'shared_components/src/components/tenants';
import { NOTIFICATION_STATES } from 'shared_components/src/common/constants';

import kycApiService from '../../service/kycApi.service';
import { _getSelectedTenant } from '../../store/selectors';
import { setLoading, clearLoading, setNotification } from '../../store/common/actions';
import { updateMenu } from '../../store/menu/actions';
import { MENU } from '../../common/routes/menu';

const FeaturesPage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const featureFlags = useFlags();
	const selectedTenant = _getSelectedTenant();
	const [initialProductData, setInitialProductData] = useState([] as any);
	const [products, setProducts] = useState([] as any);
	const [openThresholdModal, setOpenThresholdModal] = useState(false);
	const [dialogData, setDialogData] = useState({} as any);
	const [tenant, setTenant] = useState({} as any);

	const isV2Enabled = Boolean(featureFlags['document-proofing-feature']);

	useEffect(() => {
		dispatch(updateMenu(MENU.features));
	}, []);

	useEffect(() => {
		if (isEmpty(selectedTenant)) {
			const tenantData = getCookie('selectedTenant') ? JSON.parse(getCookie('selectedTenant')) : {};
			getTenant(tenantData?.alias);
		} else {
			setTenant(selectedTenant);
		}
	}, [selectedTenant]);

	useEffect(() => {
		if (!isEmpty(tenant)) {
			getTenantProducts();
		}
	}, [tenant]);

	const getTenant = (alias) => {
		dispatch(setLoading());
		return new Promise((resolve) => {
			kycApiService
				.getTenant(alias)
				.then((res: any) => {
					if (!res.error) {
						setTenant(res);
					}
				})
				.catch((err: any) => {
					setTenant({});
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const getTenantProducts = () => {
		dispatch(setLoading());
		return new Promise((resolve) => {
			kycApiService
				.getTenantProducts(tenant?.alias, tenant?.product?.code)
				.then((res: any) => {
					if (res?.features) {
						setProductData(res.features);
						setInitialProductData(res);
					} else {
						setProducts([]);
					}
				})
				.catch((err: any) => {
					setProducts([]);
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const updateTenantProducts = (data) => {
		dispatch(setLoading());
		return new Promise((resolve) => {
			kycApiService
				.updateTenantProducts(tenant?.alias, tenant?.product?.code, data)
				.then((res: any) => {
					if (res?.features) {
						displayNotificatoinSuccess('Features successfully updated.');
						setProductData(res.features);
						setInitialProductData(res);
					} else {
						displayNotificatoinError('Failed to update Features.');
					}
				})
				.catch((err: any) => {
					displayNotificatoinError('Failed to update Features.');
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const displayNotificatoinSuccess = (msg: string) => {
		dispatch(
			setNotification({
				message: msg,
				type: NOTIFICATION_STATES.success,
			})
		);
	};

	const displayNotificatoinError = (msg: string) => {
		dispatch(
			setNotification({
				message: msg,
				type: NOTIFICATION_STATES.error,
			})
		);
	};

	const setProductData = (features) => {
		const products = map(features, (feature, key) => ({ key, ...feature }));
		const filteredProducts = products.filter((product) => product.type === 'default');
		const faceScanCheck = filteredProducts.find((product) => product.key === 'faceScan');

		const productList = filteredProducts.map((product) => {
			if (product.key === 'ocr') {
				product.order = 1;
				product.MVCheckRequired = true;
				product.MVThresholdRequired = true;
				product.isSelectable = false;
				product.isDetailsButtonRequired = true;
				product.thresholdRequired = !isV2Enabled;
				product.thresholdLabel = 'Confidence Threshold';
				product.showMinMaxThreshold = isV2Enabled;
				product.reScanSubtitle =
					'Checks the quality of the extraction and asks the user to re-scan if it is low quality.';
			} else if (product.key === 'identityOwnerCheck') {
				product.order = 2;
				product.isSelectable = false;
				product.isDetailsButtonRequired = true;
				product.thresholdRequired = false;
				product.showMinMaxThreshold = false;
				product.editablePerVerification = true;
				product.requiredOverridable = product.requiredOverridable;
				product.selectedIdentityOwnerSource = product.sources.find((source) => source.default)?.code;
			} else if (product.key === 'authenticityVerification') {
				product.order = 3;
				product.MVCheckRequired = true;
				product.MVThresholdRequired = true;
				product.isSelectable = true;
				product.isDetailsButtonRequired = true;
				product.thresholdRequired = !isV2Enabled;
				product.showMinMaxThreshold = isV2Enabled;
				product.thresholdLabel = 'Confidence Threshold';
			} else if (product.key === 'faceScan') {
				product.order = 4;
				product.MVCheckRequired = false;
				product.MVThresholdRequired = false;
				product.isSelectable = true;
				product.isDetailsButtonRequired = true;
				product.thresholdRequired = false;
				product.reScanSubtitle =
					'Checks the quality of the selfie and asks the user to re-take if it is lost quality.';
			} else if (product.key === 'liveness') {
				product.order = 5;
				product.disabled = !faceScanCheck.required;
				product.MVCheckRequired = false;
				product.MVThresholdRequired = false;
				product.isSelectable = true;
				product.isDetailsButtonRequired = true;
				product.thresholdRequired = !isV2Enabled;
				product.showMinMaxThreshold = isV2Enabled;
				product.thresholdLabel = 'Liveness Threshold';
			} else if (product.key === 'faceMatch') {
				product.order = 6;
				product.disabled = !faceScanCheck.required;
				product.MVCheckRequired = true;
				product.MVThresholdRequired = true;
				product.isSelectable = true;
				product.isDetailsButtonRequired = true;
				product.thresholdRequired = !isV2Enabled;
				product.showMinMaxThreshold = isV2Enabled;
				product.thresholdLabel = 'Similarity Threshold';
			} else if (product.key === 'reviewEdit') {
				product.order = 7;
				product.MVCheckRequired = false;
				product.MVThresholdRequired = false;
				product.isSelectable = true;
				product.isDetailsButtonRequired = true;
				product.thresholdRequired = false;
				product.showMinMaxThreshold = true;
				product.thresholdLabel = 'Similarity Threshold';
			} else if (product.key === 'nameMatchVerification') {
				product.order = 8;
				product.isSelectable = true;
				product.MVCheckRequired = true;
				product.isDetailsButtonRequired = true;
			}

			return product;
		});

		setProducts(productList.sort((a, b) => a.order - b.order));
	};

	const updateProducts = (type, value) => {
		setProducts((prev) => {
			const products = prev.map((product) => {
				if (type === 'faceScan') {
					if (
						product.key === 'faceScan' ||
						product.key === 'liveness' ||
						product.key === 'faceMatch'
					) {
						product.required = value;
						if (product.key === 'liveness' || product.key === 'faceMatch') {
							product.disabled = !value;
						}
					}
				} else if (type === product.key) {
					product.required = value;
				}
				return product;
			});
			return products;
		});
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>, type) => {
		const value = event.target.checked;

		updateProducts(type, value);
	};

	const handleShowDetails = (type) => {
		updateDialogData(type);
		setOpenThresholdModal(true);
	};

	const handleAddButtonAction = (type) => {
		updateProducts(type, true);
	};

	const handleCancelButtonAction = (type) => {
		updateProducts(type, false);
	};

	const updateDialogData = (type) => {
		const product = products.find((product) => type === product.key);
		setDialogData(product);
	};

	const handleDialogSave = ({
		modalFor = '',
		threshold = 0,
		minThreshold = 0,
		maxThreshold = 0,
		isMVRequired = false,
		rescan = false,
		reScanValue = 0,
		MVMinThreshold = 0,
		MVMaxThreshold = 100,
		runMVForUpstreamCheckFailure = false,
		productEditablePerVerification = false,
		newIdentityOwnerSource = '',
	}) => {
		const value = threshold / 100;
		const minThresholdValue = minThreshold / 100;
		const maxThresholdValue = maxThreshold / 100;
		setProducts((prev) => {
			const products = prev.map((product) => {
				if (modalFor === product.key) {
					/**
					 * For V2 and reviewEdit, we must use min and max threshold
					 * For V1 and faceScan, we must just use threshold
					 */
					if (product.key === 'faceScan') {
						product.threshold = value;
					} else if (product.key === 'reviewEdit' || isV2Enabled) {
						product.minThreshold = minThresholdValue;
						product.maxThreshold = maxThresholdValue;
					} else {
						product.threshold = value;
					}

					if (product.key === 'ocr') {
						product.runManualVerificationForUpstreamCheckFailure = runMVForUpstreamCheckFailure;
						product.manualRequired = isMVRequired;
						product.manualMaxThreshold = MVMaxThreshold / 100;
						product.manualMinThreshold = MVMinThreshold / 100;
						product.reScan = {
							required: rescan,
							maxAttempt: reScanValue,
						};
					} else if (product.key === 'faceScan') {
						product.reScan = {
							required: rescan,
							maxAttempt: reScanValue,
						};
					} else if (product.key === 'faceMatch' || product.key === 'authenticityVerification') {
						product.manualRequired = isMVRequired;
						product.manualMaxThreshold = MVMaxThreshold / 100;
						product.manualMinThreshold = MVMinThreshold / 100;
					} else if (product.key === 'identityOwnerCheck') {
						product.requiredOverridable = productEditablePerVerification;
						product.sources = product.sources.map((source) => {
							if (source.code === newIdentityOwnerSource) {
								source.default = true;
							} else {
								source.default = false;
							}
							return source;
						});
					} else if (product.key === 'nameMatchVerification') {
						product.manualRequired = isMVRequired;
					}
				}
				return product;
			});
			return products;
		});
		setOpenThresholdModal(false);
	};

	const handleDialogClose = () => {
		setOpenThresholdModal(false);
		setDialogData({});
	};

	const cancelSettingUpdate = () => {
		const curPath = window.location.pathname;
		const redirectPath = curPath.substring(4).replace('features', 'account-details');
		navigate(redirectPath);
	};

	const saveSettings = () => {
		products.forEach((product) => {
			initialProductData.features[product.key].required = product.required;

			if (product.key === 'ocr') {
				initialProductData.features.ocr.reScan = product.reScan;
				initialProductData.features.ocr.manualRequired = product.required && product.manualRequired;
				initialProductData.features.ocr.runManualVerificationForUpstreamCheckFailure =
					product.required && (product.runManualVerificationForUpstreamCheckFailure || false);
				initialProductData.features[product.key].manualMaxThreshold = product.manualMaxThreshold;
				initialProductData.features[product.key].manualMinThreshold = product.manualMinThreshold;
			}

			if (product.key === 'identityOwnerCheck') {
				initialProductData.features.identityOwnerCheck.requiredOverridable =
					product.requiredOverridable;
				initialProductData.features.identityOwnerCheck.sources = product.sources;
			}

			if (product.key === 'faceScan') {
				initialProductData.features.faceScan.reScan = product.reScan;
			}

			if (product.key === 'faceMatch' || product.key === 'authenticityVerification') {
				initialProductData.features[product.key].manualRequired =
					product.required && product.manualRequired;
				initialProductData.features[product.key].manualMaxThreshold = product.manualMaxThreshold;
				initialProductData.features[product.key].manualMinThreshold = product.manualMinThreshold;
			}

			if (product.key === 'nameMatchVerification') {
				initialProductData.features[product.key].manualRequired =
					product.required && product.manualRequired;
			}

			/**
			 * For V2 and reviewEdit, we must use min and max threshold
			 * For V1 and faceScan, we must just use threshold
			 */
			if (product.key === 'faceScan') {
				initialProductData.features[product.key].threshold = product.threshold;
			} else if (product.key === 'reviewEdit' || isV2Enabled) {
				initialProductData.features[product.key].maxThreshold = product.maxThreshold;
				initialProductData.features[product.key].minThreshold = product.minThreshold;
			} else {
				initialProductData.features[product.key].threshold = product.threshold;
			}
		});
		updateTenantProducts(initialProductData);
	};

	return (
		<>
			<Features
				features={products}
				handleChange={handleChange}
				handleShowDetails={handleShowDetails}
				handleAddButtonAction={handleAddButtonAction}
				handleCancelButtonAction={handleCancelButtonAction}
				saveSettings={saveSettings}
				cancelSettingUpdate={cancelSettingUpdate}
			/>

			<DetailDialog
				open={openThresholdModal}
				type='products'
				checked={dialogData.required}
				modalFor={dialogData.key}
				thresholdLabel={dialogData.thresholdLabel}
				thresholdValue={Number(`${dialogData.threshold * 100}`)}
				thresholdRequired={dialogData.thresholdRequired}
				showMinMaxThreshold={dialogData.showMinMaxThreshold}
				maxThreshold={Number(`${dialogData.maxThreshold * 100}`)}
				minThreshold={Number(`${dialogData.minThreshold * 100}`)}
				editablePerVerification={dialogData.editablePerVerification}
				editPerVerificationChecked={dialogData.requiredOverridable}
				selectedIdentityOwnerSource={dialogData.selectedIdentityOwnerSource}
				identityOwnerSources={dialogData.sources}
				MVCheckRequired={dialogData.MVCheckRequired}
				runManualVerificationForUpstreamCheckFailure={
					dialogData.runManualVerificationForUpstreamCheckFailure || false
				}
				manualRequired={dialogData.manualRequired}
				MVThresholdRequired={dialogData.MVThresholdRequired}
				manualMinThreshold={dialogData.manualMinThreshold}
				manualMaxThreshold={dialogData.manualMaxThreshold}
				reScanFieldRequired={!isEmpty(dialogData.reScan)}
				reScan={dialogData.reScan?.required}
				reScanAttempts={dialogData.reScan?.maxAttempt}
				reScanSubtitle={dialogData.reScanSubtitle}
				title={dialogData.name}
				subTitle={dialogData.description}
				handleSave={handleDialogSave}
				handleClose={handleDialogClose}
			/>
		</>
	);
};

export default FeaturesPage;
