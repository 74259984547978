import React, { Suspense, useEffect, useLayoutEffect, useMemo } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import { isEmpty } from 'lodash';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';

import { Layout } from 'shared_components/src/components/layout';
import { Loading, NotMatch, NotificationBar } from 'shared_components/src/components/common';
import { clearGeneralCookies, setCookie, getCookie } from 'shared_components/src/service/common.service';
import {
	GENERAL_COOKIES,
	AUTH_ROLE,
	NOTIFICATION_STATES,
	CONFIGURATION_COOKIES,
	APP_URL_PREFIX,
} from 'shared_components/src/common/constants';
import { TENANT_STATUS } from 'shared_components/src/service/models/tenant';

import { LOGIN_USER_TYPES } from '../constants';
import {
	_getMenuKey,
	_getLoadingState,
	_getIsLoggedIn,
	_getAuthInfo,
	_getAuthRoles,
	_getNotification,
	_getProducts,
	_getTenants,
	_getSelectedTenant,
	_getTenantPlan,
	_getTenantAlias,
} from '../../store/selectors';

import { logoutAuthUser, updateAuthUser } from '../../store/auth/actions';
import { setProducts, setProduct } from '../../store/product/actions';
import {
	setTenants,
	setSelectedTenant,
	setTenantAlias,
	setTenantPlan,
	setCountryList,
} from '../../store/tenant/actions';
import { IAuthInfo } from '../../service/models/auth';
import KycApiService from '../../service/kycApi.service';
import AuthService from '../../service/auth.service';
import { clearLoading, setLoading, setNotification } from '../../store/common/actions';
import routes from './routes';
import { DESTROY_SESSION } from '../../store/common/actionTypes';
import { getIdleTimeCountFromConfiguration } from 'shared_components/src/common/utils';
import { gotoStartPage } from '../../common/utils';

import ImageViewer from '../../pages/manual-verification/ImageViewer';

interface CountryType {
	code: string;
	code2: string;
	name: string;
}

const Routers = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const menuKey = _getMenuKey();
	const loadings = _getLoadingState();
	const notification = _getNotification();
	const isLoggedIn = _getIsLoggedIn();
	const authRoles = _getAuthRoles();
	const authInfo = _getAuthInfo();
	const products = _getProducts();
	const tenants = _getTenants();
	const tenant = _getSelectedTenant();
	const tenantPlan = _getTenantPlan();
	const selectedTenant = getCookie('selectedTenant') ? JSON.parse(getCookie('selectedTenant')) : {};
	const userEmail = authInfo && JSON.parse(authInfo).email;
	const curToken = getCookie(GENERAL_COOKIES.token);
	const roles = getCookie(GENERAL_COOKIES.userRoles);
	const curPath = window.location.pathname;
	const featureFlags = useFlags();
	const ldClient = useLDClient();
	const selectedTenantAlias = _getTenantAlias();
	const params = useLocation();

	useLayoutEffect(() => {
		checkToken();
	}, []);

	useEffect(() => {
		getCountries();
	}, []);

	useEffect(() => {
		const tenant = selectedTenantAlias
			? selectedTenantAlias
			: params.pathname?.includes('verification-detail')
			? params.pathname.split('/')[2]
			: 'client';

		if (tenant) {
			console.log('selectedTenantAlias', tenant);
			ldClient && ldClient.identify({ key: tenant });
		}
	}, [selectedTenantAlias]);

	useEffect(() => {
		if (isEmpty(tenant)) {
			if (!isEmpty(selectedTenant)) {
				getTenant(selectedTenant?.alias);
			}
		} else {
			getPlanDetails(tenant?.product?.code, tenant?.product?.plan);
		}
	}, [tenant]);

	useEffect(() => {
		if (!isTenantPage()) {
			setCookie('selectedTenant', '');
		}
	}, [curPath]);

	useEffect(() => {
		switch (curPath) {
			case APP_URL_PREFIX:
			case `${APP_URL_PREFIX}/`:
				clearSignInfo();
				break;

			case `${APP_URL_PREFIX}/login`:
				break;

			default:
				break;
		}
	}, [curPath]);

	useEffect(() => {
		if (curToken) {
			getCountries();
			getProducts();
		}
	}, [curToken]);

	const getCountries = () => {
		dispatch(setLoading());
		return new Promise((resolve) => {
			KycApiService.getCountries()
				.then((res: any) => {
					if (res?.items?.length > 0) {
						const _listCountries: CountryType[] = res?.items?.map((item: any, index: number) => {
							return {
								name: item.name,
								code: item.alpha3Code,
								code2: item.alpha2Code,
							};
						});
						_listCountries.sort((a: any, b: any) => (a.name < b.name ? -1 : 1));
						dispatch(setCountryList(_listCountries));
					}
				})
				.catch((err: any) => {
					console.log('country err', err);
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const getTenant = (alias) => {
		dispatch(setLoading());
		return new Promise((resolve) => {
			KycApiService.getTenant(alias)
				.then((res: any) => {
					if (!res.error) {
						dispatch(setSelectedTenant(res));
						dispatch(setTenantAlias(alias));
						getPlanDetails(res?.product?.code, res?.product?.plan);
					}
				})
				.catch((err: any) => {
					console.log('getTenant err', err);
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const getPlanDetails = (productCode: string, planCode: string) => {
		dispatch(setLoading());
		return new Promise((resolve) => {
			KycApiService.getTenantPlanInfo(productCode, planCode)
				.then((res: any) => {
					if (!res.error) {
						dispatch(setTenantPlan(res));
					}
				})
				.catch((err: any) => {
					console.log('getPlanDetails err', err);
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const { idleTimeCount, hasConfiguration } = useMemo(() => {
		const _idleTimecCount = getIdleTimeCountFromConfiguration();
		const tenant = getCookie(CONFIGURATION_COOKIES.tenant);

		return {
			idleTimeCount: _idleTimecCount,
			hasConfiguration: tenant ? true : false,
		};
	}, []);

	const isTenantPage = () => {
		const paths = [
			'account-details',
			'account-overview',
			'add-ons',
			'billing-payment',
			'cancel-subscription',
			'change-plan',
			'features',
			'plan',
			'product-configuration',
			'integration',
			'product-overview',
			'subscribe',
			'verification',
			'verification-detail',
			'view-users',
			'user-detail',
		];
		const temp = curPath.split('/') || [];
		const isTenantPage = paths.filter((path) => temp.includes(path)) || [];
		return isTenantPage.length > 0 || false;
	};

	const showChangePlan = useMemo(() => {
		return tenant?.status !== TENANT_STATUS.CANCELLED;
	}, [tenant]);

	const showSubMenu = useMemo(() => {
		return isTenantPage();
	}, [curPath]);

	const handleOnIdle = () => {
		handleForceSignOut();
	};

	useIdleTimer({
		timeout: 1000 * 60 * idleTimeCount,
		onIdle: handleOnIdle,
		debounce: 300,
	});

	const checkToken = () => {
		if (curToken) {
			if (!isLoggedIn) {
				const loggedIn = getCookie(GENERAL_COOKIES.isLoggedIn);
				const _authInfo: IAuthInfo = {
					isLoggedin: loggedIn === 'true',
					token: getCookie(GENERAL_COOKIES.token),
					role: getCookie(GENERAL_COOKIES.userRole) as AUTH_ROLE,
					firstName: getCookie(GENERAL_COOKIES.userFirstName),
					lastName: getCookie(GENERAL_COOKIES.userLastName),
					info: getCookie(GENERAL_COOKIES.userInfo),
					session: getCookie(GENERAL_COOKIES.userSession),
					roles: getCookie(GENERAL_COOKIES.userRoles),
					tenant: getCookie(GENERAL_COOKIES.userTenant),
				};

				dispatch(updateAuthUser(_authInfo));
				KycApiService.init(_authInfo.token);

				if (_authInfo.session) {
					const sessionInfo = JSON.parse(_authInfo.session);
					setExpiring(sessionInfo.payload.exp);
				}
			}
		}
		// else {
		// 	refreshToken();
		// }
	};

	const setExpiring = (expireIn: number) => {
		const delta = expireIn * 1000 - new Date().getTime();

		if (delta > 0) {
			setTimeout(() => {
				refreshToken();
			}, delta);
		} else {
			refreshToken();
		}
	};

	const clearSignInfo = () => {
		navigate('/login');
		clearGeneralCookies();
		dispatch(logoutAuthUser());
		dispatch({ type: DESTROY_SESSION });
	};

	const refreshToken = () => {
		AuthService.currentSession()
			.then((res: any) => {
				if (res) {
					const userInfo: IAuthInfo = setAuthInfo(res);

					if (checkUser(userInfo.roles)) {
						setCookies(userInfo);

						dispatch(updateAuthUser(userInfo));
						KycApiService.init(userInfo.token);

						setExpiring(res.idToken.payload.exp);
					} else {
						handleForceSignOut();
					}
				} else {
					handleForceSignOut();
				}
			})
			.catch((err) => {
				handleForceSignOut();
			});
	};

	const setAuthInfo = (auth: any) => {
		return {
			isLoggedin: true,
			token: auth?.idToken?.jwtToken,
			role: auth?.idToken?.payload['cognito:groups'][0],
			firstName: auth?.idToken?.payload?.given_name ?? '',
			lastName: auth?.idToken?.payload?.family_name ?? '',
			info: JSON.stringify(auth?.idToken?.payload ?? ''),
			session: JSON.stringify(auth.idToken),
			roles: auth?.idToken?.payload['custom:roles'],
			tenant: auth?.idToken?.payload['custom:tenant'],
		};
	};

	const setCookies = (authSessionInfo: IAuthInfo) => {
		setCookie(GENERAL_COOKIES.isLoggedIn, authSessionInfo.isLoggedin.toString(), 1);
		setCookie(GENERAL_COOKIES.token, authSessionInfo.token ?? '', 1);
		setCookie(GENERAL_COOKIES.userRole, authSessionInfo.role ?? 0, 1);
		setCookie(GENERAL_COOKIES.userFirstName, authSessionInfo.firstName ?? '', 1);
		setCookie(GENERAL_COOKIES.userLastName, authSessionInfo.lastName ?? '', 1);
		setCookie(GENERAL_COOKIES.userInfo, authSessionInfo.info ?? '', 1);
		setCookie(GENERAL_COOKIES.userSession, authSessionInfo.session ?? '', 1);
		setCookie(GENERAL_COOKIES.userRoles, authSessionInfo.roles ?? '', 1);
		setCookie(GENERAL_COOKIES.userTenant, authSessionInfo.tenant ?? '', 1);
	};

	const checkUser = (roles: string) => {
		const arrRoles = roles?.split(',') || [];

		let hasPermission = false;

		arrRoles.forEach((role) => {
			if (LOGIN_USER_TYPES.includes(role)) {
				hasPermission = true;
			}
		});

		return hasPermission;
	};

	const handleSignOut = () => {
		if (isLoggedIn) {
			AuthService.signOut()
				.then((res) => {
					clearSignInfo();
				})
				.catch((err) => handleSignOutError(err));
		} else {
			clearSignInfo();
		}
	};

	const handleForceSignOut = () => {
		AuthService.signOut()
			.then((res) => {
				clearSignInfo();
			})
			.catch((err) => handleSignOutError(err));
	};

	const handleSignOutError = (err: any) => {
		console.log('Sign Out Error', err);
		clearSignInfo();
		localStorage.clear();
		sessionStorage.clear();
	};

	const handleNotificationClose = () => {
		dispatch(setNotification({ message: '', type: NOTIFICATION_STATES.info }));
	};

	const handleReturn = () => {
		if (isLoggedIn) {
			gotoStartPage(navigate, authRoles);
		} else {
			navigate('/login');
		}
	};

	useEffect(() => {
		if (products?.length > 0 && authRoles) {
			const roles = authRoles.split(',');
			const prod = products.find((_prod) => {
				const prodRole = _prod?.roles?.find((_role) => {
					if (!_role.visible) {
						return false;
					}

					const authRole = roles.find((_authRole) => _authRole === _role.name);

					return authRole ? true : false;
				});

				if (prodRole) {
					return true;
				}

				return false;
			});

			dispatch(setProduct(prod));

			// TODO: remove
			getTenantList();
		}
	}, [products]);

	const getProducts = () => {
		dispatch(setLoading());
		KycApiService.getProducts()
			.then((res) => {
				dispatch(setProducts(res?.items));
			})
			.catch((err) => {
				console.log('errr => ', err);
			})
			.finally(() => {
				dispatch(clearLoading());
			});
	};

	// TODO: remove
	const getTenantList = () => {
		products?.forEach((prod) => {
			if (tenants?.[prod.code]?.length > 0) {
				return;
			}

			dispatch(setLoading());
			KycApiService.getActiveTenantsForProduct(prod.code)
				.then((res) => {
					if (res) {
						dispatch(setTenants(prod.code, res.items));
					} else {
						dispatch(setTenants(prod.code, []));
					}
				})
				.catch((err) => {
					dispatch(setTenants(prod.code, []));
				})
				.finally(() => {
					dispatch(clearLoading());
				});
		});
	};

	const handleViewPlan = () => {
		const rootPath = `tenant/${selectedTenant.alias}`;
		navigate(`/${rootPath}/plan`);
	};

	const handleChangePlan = () => {
		const rootPath = `tenant/${selectedTenant.alias}`;
		navigate(`/${rootPath}/plan/change-plan`);
	};

	const showComp = (role, hasFlag, flag) => {
		const roles = authRoles.split(',');
		const hasAccess = role ? role.find((_role) => roles.includes(_role)) || false : false;
		if (hasFlag) {
			return hasAccess && featureFlags[flag];
		}
		return hasAccess;
	};

	const handleLogoAction = () => {
		navigate(`/home`);
	};

	return (
		<Suspense fallback={<Loading open={true} />}>
			<Loading open={loadings > 0} />
			<NotificationBar
				message={notification.message}
				type={notification.type}
				className={notification?.classes}
				onClose={handleNotificationClose}
			/>
			<Routes>
				{routes.map((route, i) => {
					const { path, component: Component, hasAuth, exact, roles, hasFlag, flag } = route;
					return (
						<Route
							key={i}
							path={path}
							element={
								hasAuth ? (
									curToken ? (
										showComp(roles, hasFlag, flag) ? (
											<Layout
												handleSignOut={handleSignOut}
												handleViewPlan={handleViewPlan}
												handleChangePlan={handleChangePlan}
												menu={menuKey}
												role={authRoles}
												email={userEmail}
												portal={'ADMIN'}
												title={selectedTenant?.organisationName || ''}
												showViewPlanButton={true}
												showChangePlanButton={showChangePlan}
												plan={tenantPlan || {}}
												productCode={tenant?.product?.code}
												showSubMenu={showSubMenu}
												featureFlags={featureFlags}
												handleLogoAction={handleLogoAction}
											>
												<Component />
											</Layout>
										) : (
											<NotMatch
												hasConfiguration={hasConfiguration}
												handleReturn={handleReturn}
											/>
										)
									) : (
										<Navigate to='/login' />
									)
								) : path === '/login' && curToken ? (
									<Navigate to={`/home`} />
								) : (
									<Component />
								)
							}
						/>
					);
				})}
				<Route key='imageViewer' path='/image/:imageUrl' element={curToken && <ImageViewer />} />
				<Route
					path='*'
					element={<NotMatch hasConfiguration={hasConfiguration} handleReturn={handleReturn} />}
				/>
			</Routes>
		</Suspense>
	);
};

export default Routers;
