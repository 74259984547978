import React, { useState, useLayoutEffect } from "react";
import dayjs from "dayjs";
import {
  Typography,
  Box,
  Chip,
  Stack,
  FormControl,
  Select,
  MenuItem,
  Theme,
  FormHelperText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { isEmpty } from "lodash";
import { _getProducts } from "../../store/selectors";

import { TruuthButton } from "shared_components/src/components/common";

const useStyles = makeStyles(() => ({
  root: {
    "& .styledStack": {
      display: "flex",
      flexDirection: "row",
      gap: "16px",

      "@media screen and (max-width: 1024px)": {
        flexWrap: "wrap",
      },

      "@media screen and (max-width: 428px)": {
        flexDirection: "column",
      },
    },

    "& .MuiInputLabel-root.Mui-focused": {
      color: "#8C07DD",
    },

    "& .MuiInput-root:after": {
      borderBottom: "2px solid #8C07DD",
    },

    "& .MuiInputBase-input": {
      color: "#20203A",
      padding: "4px 8px",
      height: "24px",
      fontSize: "12px",
      lineHeight: "24px",
    },

    "& .MuiTypography-root": {
      fontSize: "12px",
      lineHeight: "24px",
      marginRight: "8px",
    },

    "& .MuiOutlinedInput-root": {
      "& .MuiSelect-select": {
        padding: "4px 8px",
      },
    },

    "& .MuiFormLabel-root": {
      marginLeft: "-12px",
    },

    "& .MuiInputBase-root": {
      backgroundColor: "#fff",
      borderRadius: "4px",

      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          border: "2px solid #B3B3C6",
        },
      },
    },

    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "2px solid #8C07DD !important",
      },
    },

    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #E1E1EA",
    },

    "& svg": {
      fill: "#343451",
    },

    "& .chips-wrapper": {
      gap: "8px",

      "& .MuiButton-root": {
        backgroundColor: "transparent",
      },
    },
  },
}));

const TENANT_STATUS = [
  { value: "ACTIVE", display: "Active", key: "TENANT_STATUS" },
  {
    value: "AWAITING-PAYMENT-DETAILS",
    display: "Awaiting for payment details",
    key: "TENANT_STATUS",
  },
  { value: "CANCELLED", display: "Cancelled", key: "TENANT_STATUS" },
  { value: "ERROR", display: "Error", key: "TENANT_STATUS" },
  {
    value: "ACTIVE-PAYMENT-FAILED",
    display: "Payment failed",
    key: "TENANT_STATUS",
  },
  { value: "PENDING", display: "Pending", key: "TENANT_STATUS" },
  { value: "SUSPENDED", display: "Suspended", key: "TENANT_STATUS" },
  { value: "TERMINATED", display: "Terminated", key: "TENANT_STATUS" },
];

interface IProps {
  resetFilters: any;
  setFilters: any;
}

const TenantFilters = ({ resetFilters, setFilters }: IProps) => {
  const classes = useStyles();
  const products = _getProducts();

  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedTenantStatus, setSelectedTenantStatus] = useState([]);
  const [createdFrom, setCreatedFrom] = useState(dayjs("2020/01/01"));
  const [createdTo, setCreatedTo] = useState(dayjs());
  const [fromDateError, setFromDateError] = useState(false);
  const [toDateError, setToDateError] = useState(false);

  useLayoutEffect(() => {
    try {
      const filters = sessionStorage.getItem("tenantFilters");

      if (filters) {
        const parsedFilters = JSON.parse(filters);
        setSelectedProduct(parsedFilters.product);
        setSelectedTenantStatus(parsedFilters.status);
        setCreatedFrom(dayjs(parsedFilters.createddatestart));
        setCreatedTo(dayjs(parsedFilters.createddateend));
      }
    } catch (error) {
      console.error(
        "Error retrieving filter data from session storage:",
        error
      );
    }
  }, []);

  const PRODUCTS_LIST = products.map((product) => ({
    value: product.code,
    display: product.name,
    key: "PRODUCTS",
  }));

  const StyledContainer = (theme: Theme, width) => {
    return {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      width: width.lg,
      maxWidth: "400px",

      [theme.breakpoints.down(1025)]: {
        width: width.md,
        marginLeft: 0,
      },

      [theme.breakpoints.down(768)]: {
        width: width.sm,
        minWidth: width.sm,
      },

      [theme.breakpoints.down(429)]: {
        maxWidth: "384px",
      },

      [theme.breakpoints.down(376)]: {
        maxWidth: "334px",
      },

      [theme.breakpoints.down(321)]: {
        maxWidth: "275px",
      },
    };
  };

  const styledDatepickerWrapper = (theme: Theme) => {
    return {
      display: "flex",
      width: "60%",
      minWidth: "30%",

      [theme.breakpoints.down(1025)]: {
        width: "72%",
      },

      [theme.breakpoints.down(768)]: {
        width: "100%",
      },
    };
  };

  const styledDatepicker = (theme: Theme, width) => {
    return {
      width: width.lg,

      [theme.breakpoints.down(1025)]: {
        width: width.md,
        marginLeft: 0,
      },
    };
  };

  const buttonWrapper = (theme) => {
    return {
      flexDirection: "row",
      justifyContent: "end",
      gap: "16px",
      alignItems: "center",
      flexGrow: "1",

      "& .MuiButton-root": {
        height: "32px",
      },
    };
  };

  const setSelectedValue = (type, value) => {
    if (type === "TENANT_STATUS") {
      setSelectedTenantStatus(value);
    } else if (type === "PRODUCTS") {
      setSelectedProduct(value);
    }
  };

  const deleteSelectedValue = (type, item) => {
    if (type === "TENANT_STATUS") {
      setSelectedTenantStatus((status) => status.filter((el) => el !== item));
    } else if (type === "PRODUCTS") {
      setSelectedProduct((status) => status.filter((el) => el !== item));
    }
  };

  const validateDateChange = (type, newValue) => {
    if (type === "FROM") {
      setFromDateError(!dayjs(newValue, "YYYY/MM/DD", true).isValid());
    } else if (type === "TO") {
      setToDateError(!dayjs(newValue, "YYYY/MM/DD", true).isValid());
    }
    handleDateChange(type, newValue);
  };

  const handleDateChange = (type, value) => {
    if (type === "FROM") {
      setCreatedFrom(value);
    } else if (type === "TO") {
      setCreatedTo(value);
    }
  };

  const handleResetFilters = (event) => {
    event.persist();
    setSelectedProduct([]);
    setSelectedTenantStatus([]);
    setCreatedFrom(dayjs(dayjs("2020/01/01")));
    setCreatedTo(dayjs(dayjs()));
    resetFilters();
  };

  const handleFilterApply = (event) => {
    if (!fromDateError && !toDateError) {
      const filters = {
        product: selectedProduct,
        status: selectedTenantStatus,
        createddatestart: dayjs(createdFrom).format("YYYY-MM-DD"),
        createddateend: dayjs(createdTo).format("YYYY-MM-DD"),
      };

      try {
        sessionStorage.setItem("tenantFilters", JSON.stringify(filters));
      } catch (error) {
        console.error("Error saving tenant filters to session storage:", error);
      }

      setFilters(filters);
    }
  };

  const renderMultiSelectDropdown = (
    formLabel,
    value,
    type,
    options,
    width
  ) => {
    return (
      <FormControl sx={(theme) => StyledContainer(theme, width)}>
        <Typography
          sx={{
            color: "#726F76",
            fontSize: "12px",
            lineHeight: "24px",
            minWidth: "fit-content",
          }}
        >
          {formLabel}
        </Typography>
        <Select
          multiple
          value={value}
          onChange={(event) => {
            setSelectedValue(type, event.target.value);
          }}
          sx={{
            width: "calc(100% - 40px)",
          }}
          renderValue={(selected) => (
            <Typography
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {selected
                .map((value) => {
                  const option = options.find(
                    (status) => status.value === value
                  );
                  return option ? option.display : "";
                })
                .join(", ")}
            </Typography>
          )}
        >
          {options.map((status) => (
            <MenuItem
              key={status.value}
              value={status.value}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#F0ECF5",
                  ":hover": {
                    backgroundColor: "#DACFE6",
                  },
                },
              }}
            >
              {status.display}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const SelectedItemsChips = ({
    selectedProduct,
    selectedTenantStatus,
    onDelete,
  }: {
    selectedProduct: string[];
    selectedTenantStatus: string[];
    onDelete: (key, item) => void;
  }) => {
    const selectedItems = [...selectedProduct, ...selectedTenantStatus];
    return (
      <>
        {!isEmpty(selectedItems) && (
          <Stack
            className={"chips-wrapper"}
            direction="row"
            flexWrap="wrap"
            spacing={1}
            sx={{ marginTop: "16px" }}
          >
            {selectedItems.map((item) => {
              const items = [...PRODUCTS_LIST, ...TENANT_STATUS];
              const data = items.find((jt) => jt.value === item);
              return (
                <Chip
                  key={item}
                  label={data?.display}
                  onDelete={() => onDelete(data?.key, item)}
                  sx={{
                    whiteSpace: "nowrap",
                    "&.MuiButtonBase-root.MuiChip-root": {
                      color: "#470D82",
                      background: `linear-gradient(0deg, #F0F0F4, #F0F0F4), linear-gradient(0deg, #E1E1EA, #E1E1EA) !important`,
                      border: `1px solid #470D82`,
                      "& svg": {
                        "& path": {
                          fill: "#470D82",
                        },
                      },
                    },
                  }}
                />
              );
            })}
          </Stack>
        )}
      </>
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
        padding: "16px 0",
      }}
      className={classes.root}
    >
      <Stack className="styledStack">
        {renderMultiSelectDropdown(
          "Product",
          selectedProduct,
          "PRODUCTS",
          PRODUCTS_LIST,
          {
            lg: "35%",
            md: "48%",
            sm: "100%",
          }
        )}
        {renderMultiSelectDropdown(
          "Status",
          selectedTenantStatus,
          "TENANT_STATUS",
          TENANT_STATUS,
          {
            lg: "35%",
            md: "48%",
            sm: "100%",
          }
        )}
        <Box sx={(theme) => styledDatepickerWrapper(theme)}>
          <FormControl
            sx={(theme) =>
              StyledContainer(theme, {
                lg: "60%",
                md: "60%",
                sm: "60%",
              })
            }
            style={{ marginRight: "8px" }}
          >
            <Typography
              sx={{
                color: "#726F76",
                fontSize: "12px",
                lineHeight: "24px",
                minWidth: "fit-content",
              }}
            >
              Created on from
            </Typography>
            <FormControl
              variant="standard"
              sx={{ width: "100%" }}
              error={fromDateError}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className={`datePicker`}
                  sx={(theme) =>
                    styledDatepicker(theme, {
                      lg: "100%",
                      md: "100%",
                      sm: "100%",
                    })
                  }
                  value={createdFrom}
                  format={"DD/MM/YYYY"}
                  disableFuture={true}
                  onChange={(newValue) => validateDateChange("FROM", newValue)}
                />
              </LocalizationProvider>
              {fromDateError && (
                <FormHelperText style={{ color: "#d32f2f" }}>
                  Provide a valid Date
                </FormHelperText>
              )}
            </FormControl>
          </FormControl>
          <FormControl
            sx={(theme) =>
              StyledContainer(theme, {
                lg: "40%",
                md: "40%",
                sm: "38%",
              })
            }
          >
            <Typography
              sx={{
                color: "#726F76",
                fontSize: "12px",
                lineHeight: "24px",
              }}
            >
              to
            </Typography>
            <FormControl
              variant="standard"
              sx={{ width: "100%" }}
              error={toDateError}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  className={`datePicker`}
                  sx={(theme) =>
                    styledDatepicker(theme, {
                      lg: "100%",
                      md: "100%",
                      sm: "100%",
                    })
                  }
                  value={createdTo}
                  format={"DD/MM/YYYY"}
                  disableFuture={true}
                  onChange={(newValue) => validateDateChange("TO", newValue)}
                />
              </LocalizationProvider>
              {toDateError && (
                <FormHelperText style={{ color: "#d32f2f" }}>
                  Provide a valid Date
                </FormHelperText>
              )}
            </FormControl>
          </FormControl>
        </Box>
        <Stack sx={(theme) => buttonWrapper(theme)}>
          <TruuthButton
            buttontype="secondary"
            size="medium"
            onClick={(e) => handleResetFilters(e)}
          >
            Reset
          </TruuthButton>
          <TruuthButton
            buttontype="secondary"
            size="medium"
            onClick={(e) => handleFilterApply(e)}
          >
            Apply
          </TruuthButton>
        </Stack>
      </Stack>
      <Stack>
        <SelectedItemsChips
          selectedProduct={selectedProduct}
          selectedTenantStatus={selectedTenantStatus}
          onDelete={(type, item) => deleteSelectedValue(type, item)}
        />
      </Stack>
    </Box>
  );
};

export default TenantFilters;
