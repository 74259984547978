import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { Auth, Hub } from 'aws-amplify';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import {
	Login,
	Welcome,
	ForgotPassword,
	ResetPassword,
	ForgotPasswordNotification,
	CompleteNewPassword,
} from 'shared_components/src/components/login';
import { RootContainer } from 'shared_components/src/components/common';

import { clearGeneralCookies, setCookie, getCookie } from 'shared_components/src/service/common.service';
import {
	NOTIFICATION_STATES,
	APP_URL_PREFIX,
	CONFIGURATION_COOKIES,
} from 'shared_components/src/common/constants';
import { getAuthInfoFromCognitoResponse } from 'shared_components/src/common/utils';

import AuthService from '../../service/auth.service';
import KycApiService from '../../service/kycApi.service';
import { LOGIN_USER_TYPES } from '../../common/constants';
import { setLoading, clearLoading, setNotification } from '../../store/common/actions';
import { IAuthInfo } from '../../service/models/auth';
import { updateAuthUser, logoutAuthUser } from '../../store/auth/actions';
import { gotoStartPage } from '../../common/utils';

interface LocationState {
	params: string;
}

/**
 * Styles
 */
const useStyles = makeStyles(() => ({
	itemFit: {
		height: '100%',

		'@media screen and (max-width: 600px)': {
			height: 'fit-content',
		},
	},

	itemNoFit: {
		height: '100%',
	},
}));

/**
 * Main Component
 */
const LoginPage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const ldClient = useLDClient();

	const classes = useStyles();

	const biopassAuth = getCookie('biopass-auth');

	const [email, setEmail] = useState('');
	const [errors, setErrors] = useState({
		email: false,
		password: false,
	});

	const [showForgotPasswordNotification, setShowForgotPasswordNotification] = useState(false);
	const [showForgotPassword, setShowForgotPassword] = useState(false);
	const [showSetPassword, setShowSetPassword] = useState(false);
	const [showReset, setShowReset] = useState(false);
	const [expiredSendCode, setExpiredSendCode] = useState(false);
	const [tempUser, setTempUser] = useState(null);

	const tenant = getCookie(CONFIGURATION_COOKIES.tenant);
	const _tenantInfo = getCookie(tenant);
	const tenantInfo = _tenantInfo ? JSON.parse(_tenantInfo) : null;

	const openForgotPasswordNotification = () => setShowForgotPasswordNotification(true);
	const closeForgotPasswordNotification = () => setShowForgotPasswordNotification(false);
	const openForgotPassword = () => {
		setShowForgotPassword(true);
		setExpiredSendCode(false);
	};
	const closeForgotPassword = () => setShowForgotPassword(false);
	const openSetPassword = () => setShowSetPassword(true);
	const closeSetPassword = () => setShowSetPassword(false);
	const openReset = () => setShowReset(true);
	const closeReset = () => setShowReset(false);

	useEffect(() => {
		const error = new URLSearchParams(location.state?.params).get('error_description');
		console.log('error', error);

		Hub.listen('auth', ({ payload: { event, data } }) => {
			switch (event) {
				case 'signIn':
				case 'cognitoHostedUI':
					console.log(data);
					setAuthenticationStatus(data);
					dispatch(clearLoading());
					break;
				case 'signOut':
					console.log('Sign out');
					break;
				case 'signIn_failure':
				case 'cognitoHostedUI_failure':
					const { Auth: AuthConfig } = AuthService.getConfiguration();
					console.log('Sign in failure', event, data);
					if (!AuthConfig?.userPoolId) setErrors((prev) => ({ ...prev, email: true }));
					dispatch(clearLoading());
					break;
				default:
					console.log(event);
					console.log(data);
			}
		});

		if (error) {
			setErrors((prev) => ({ ...prev, email: true }));
		}
	}, []);

	const setUserInfo = (res: any, isReset = false) => {
		const authInfo: IAuthInfo = getAuthInfoFromCognitoResponse(res.signInUserSession);

		if (checkUser(authInfo.roles)) {
			AuthService.setAuthCookies(authInfo);

			dispatch(updateAuthUser(authInfo));
			KycApiService.init(authInfo.token);

			setExpiring(res.signInUserSession.idToken.payload.exp);

			gotoStartPage(navigate, authInfo.roles);
		} else {
			if (showReset) {
				openReset();
			} else {
				failedLogin();
			}

			AuthService.signOut()
				.then(() => {
					if (isReset) {
						const msg = 'Please login in proper site again.';
						dispatch(
							setNotification({
								message: msg,
								type: NOTIFICATION_STATES.error,
							})
						);
					}
					setErrors({
						email: false,
						password: true,
					});
				})
				.catch((err) => handleSignOutError(err));
		}
	};

	const handleSignIn = async (email: string, password: string) => {
		if (checkSignInState()) {
			openForgotPasswordNotification();

			return;
		}

		if (!email || !password) {
			setErrors({
				email: !email,
				password: !password,
			});

			return;
		}

		const userInfo = {
			username: email.toLowerCase(),
			password,
		};

		if (ldClient) {
			await ldClient
				?.identify({ key: email.toLowerCase() })
				.then(async (res) => {
					const useBiopassAuth = res['biopass-auth'];
					setCookie('biopass-auth', useBiopassAuth);
					if (useBiopassAuth) {
						await AuthService.initNew();
					}
					callSignIn(useBiopassAuth, userInfo);
				})
				.catch((err) => {
					callSignIn(false, userInfo);
				});
		}

		/* dispatch(setLoading());
		if (useBiopassAuth) {
			console.log('call new sign in');
			AuthService.signInNew(userInfo)
				.then((res) => {
					setAuthenticationStatus(res);
					dispatch(clearLoading());
				})
				.catch((err) => {
					failedLogin();
					dispatch(clearLoading());
				});
		} else {
			console.log('call old sign in');
			AuthService.signIn(userInfo)
				.then((res) => {
					setAuthenticationStatus(res);
					dispatch(clearLoading());
				})
				.catch((err) => {
					failedLogin();
					dispatch(clearLoading());
				});
		} */
	};

	const callSignIn = (biopassAuthLogin, userInfo) => {
		dispatch(setLoading());
		if (biopassAuthLogin) {
			AuthService.signInNew(userInfo)
				.then((res) => {
					setAuthenticationStatus(res);
					dispatch(clearLoading());
				})
				.catch((err) => {
					failedLogin();
					dispatch(clearLoading());
				});
		} else {
			AuthService.signIn(userInfo)
				.then((res) => {
					setAuthenticationStatus(res);
					dispatch(clearLoading());
				})
				.catch((err) => {
					failedLogin();
					dispatch(clearLoading());
				});
		}
	};

	useEffect(() => {
		if (biopassAuth === 'true') {
			dispatch(setLoading());
			AuthService.resumeSignIn()
				.then((user) => {
					dispatch(clearLoading());
					setAuthenticationStatus(user);
				})
				.catch((err) => {
					dispatch(clearLoading());
				});
		}
	}, [biopassAuth]);

	const handleSSO = () => {
		dispatch(setLoading());
		Auth.federatedSignIn({ customProvider: 'SAML' });
	};

	const setAuthenticationStatus = (res: any): void => {
		if (res?.challengeName === 'NEW_PASSWORD_REQUIRED') {
			setTempUser(res);
			openSetPassword();
		} else {
			switch (res?.code) {
				case 'UserNotFoundException':
				case 'NotAuthorizedException':
				case 'PasswordResetRequiredException':
					setErrors({
						email: false,
						password: true,
					});
					failedLogin();
					break;

				default:
					setErrors({
						email: false,
						password: false,
					});
					setUserInfo(res);
					AuthService.successedForAttemptSignIn();

					break;
			}
		}
	};

	const checkUser = (roles: string) => {
		const arrRoles = roles?.split(',') || [];

		let hasPermission = false;

		arrRoles.forEach((role) => {
			if (LOGIN_USER_TYPES.includes(role)) {
				hasPermission = true;
			}
		});

		return hasPermission;
	};

	const setExpiring = (expireIn: number) => {
		const delta = expireIn * 1000 - new Date().getTime();

		if (delta > 0) {
			setTimeout(() => {
				refreshToken();
			}, delta);
		} else {
			refreshToken();
		}
	};

	const clearSignInfo = () => {
		clearGeneralCookies();
		dispatch(logoutAuthUser());
		window.location.href = APP_URL_PREFIX + '/login';
	};

	const refreshToken = async () => {
		AuthService.currentSession()
			.then((res: any) => {
				if (res) {
					const userInfo: IAuthInfo = getAuthInfoFromCognitoResponse(res);

					if (checkUser(userInfo.roles)) {
						AuthService.setAuthCookies(userInfo);

						dispatch(updateAuthUser(userInfo));
						KycApiService.init(userInfo.token);

						setExpiring(res.idToken.payload.exp);
					} else {
						handleSignOut();
					}
				} else {
					handleSignOut();
				}
			})
			.catch((err) => {
				handleSignOut();
			});
	};

	const handleSignOut = () => {
		AuthService.signOut()
			.then((res) => {
				clearSignInfo();
			})
			.catch((err) => handleSignOutError(err));
	};

	const handleSignOutError = (err: any) => {
		console.log('Sign Out Error', err);
		clearSignInfo();
		localStorage.clear();
		sessionStorage.clear();
	};

	const handleForgotPassword = async (_email: string) => {
		dispatch(setLoading());

		return new Promise(async (resolve, reject) => {
			AuthService.forgotPassword(_email)
				.then((res) => {
					dispatch(clearLoading());
					setEmail(_email);
					setExpiredSendCode(false);
					openReset();
					return resolve({ status: 'success' });
				})
				.catch((err) => {
					dispatch(clearLoading());
					if (err?.code === 'LimitExceededException') {
						setEmail(_email);
						setExpiredSendCode(true);
						openReset();
					}

					return reject({ status: 'error', data: err });
				});
		});
	};

	const handleResetPassword = (code: string, newPassword: string) => {
		dispatch(setLoading());
		return new Promise(async (resolve, reject) => {
			AuthService.forgotPasswordSubmit(email, code, newPassword)
				.then((res: any) => {
					dispatch(
						setNotification({
							message: 'Successfully reset password!',
							type: NOTIFICATION_STATES.success,
						})
					);
					resolve({ status: 'success' });
					closeReset();
				})
				.catch((err: any) => {
					if (err) {
						if (err.code !== 'CodeMismatchException') {
							dispatch(
								setNotification({
									message: err.message,
									type: NOTIFICATION_STATES.error,
								})
							);
						}
						if (err.code === 'LimitExceededException') {
							setExpiredSendCode(true);
						}
					}
					reject({ status: 'error', data: err });
				})
				.finally(() => {
					dispatch(clearLoading());
				});
		});
	};

	const handleForgotPasswordNotification = (res: boolean) => {
		openForgotPassword();
		closeForgotPasswordNotification();
	};

	const checkSignInState = () => {
		return AuthService.checkFailedSignIn();
	};

	const handleSetPassword = (newPassword: string) => {
		dispatch(setLoading());
		return new Promise(async (resolve, reject) => {
			AuthService.completeNewPassword(tempUser, newPassword)
				.then((res: any) => {
					setUserInfo(res, true);
					resolve({ status: 'success' });
				})
				.catch((err: any) => {
					reject({ status: 'error', data: err });
				})
				.finally(() => {
					dispatch(clearLoading());
					closeSetPassword();
				});
		});
	};

	const failedLogin = () => {
		AuthService.failedForAttemptSignIn();
		if (checkSignInState()) {
			openForgotPasswordNotification();
		}
	};

	const onForgotPassword = async (_email: string) => {
		return new Promise(async (resolve, reject) => {
			handleForgotPassword(_email)
				.then((res: any) => {
					if (res && res.status === 'success') {
						setEmail(_email);
						return resolve(res);
					} else {
						return reject(res);
					}
				})
				.catch((err: any) => {
					return reject(err);
				})
				.finally(() => {
					closeForgotPassword();
				});
		});
	};

	useEffect(() => {
		if (biopassAuth === 'true') {
			AuthService.initNew();
		} else {
			AuthService.init();
		}
	}, [biopassAuth]);

	useEffect(() => {
		dispatch(logoutAuthUser());
	}, []);

	return (
		<RootContainer>
			<Grid container item sm={7} className={classes.itemFit}>
				<Welcome />
			</Grid>
			<Grid container item sm={5} className={classes.itemNoFit}>
				<Login
					errors={errors}
					handleSignIn={handleSignIn}
					handleSSO={handleSSO}
					showSSO={Boolean(tenantInfo?.cognito?.saml)}
					showPassword={tenantInfo?.cognito?.password ?? true}
					openForgotPassword={openForgotPassword}
				/>
				<CompleteNewPassword
					open={showSetPassword}
					onClose={closeSetPassword}
					handleSetPassword={handleSetPassword}
				/>
				<ForgotPasswordNotification
					open={showForgotPasswordNotification}
					onClose={closeForgotPasswordNotification}
					handleNotification={handleForgotPasswordNotification}
				/>
				<ForgotPassword
					open={showForgotPassword}
					onClose={closeForgotPassword}
					handleForgotPassword={onForgotPassword}
				/>
				<ResetPassword
					open={showReset}
					onClose={closeReset}
					handleResendCode={handleForgotPassword}
					handleResetPassword={handleResetPassword}
					email={email}
					expiredSendCode={expiredSendCode}
				/>
			</Grid>
		</RootContainer>
	);
};

export default LoginPage;
