import React, { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { Typography, Box } from "@mui/material";

import { NOTIFICATION_STATES } from "shared_components/src/common/constants";
import { VERIFIER_JOB_STATUS } from "shared_components/src/service/models/manual-verification";
import { TruuthButton } from "shared_components/src/components/common";

import {
  clearLoading,
  setLoading,
  setNotification,
  setJobState,
} from "../../store/actions";
import { _getVerifierJobState } from "../../store/selectors";

import KycApiService from "../../service/kycApi.service";
import { EndSession } from "./svg";
import MVJobLayout from "./JobLayout";

/*
 * Styles
 */

/**
 * Types
 */

interface IProps {
  setTenant: React.Dispatch<React.SetStateAction<string>>;
}

/**
 * Main Component
 */
const ManualVerificationV2 = ({ setTenant }: IProps) => {
  const dispatch = useDispatch();
  const jobState = _getVerifierJobState();

  const [isInited, setIsInited] = useState(false);
  const [jobQueue, setJobQueue] = useState<Array<string>>([]);

  const loadJobQueue = () => {
    setJobQueue([]);
  };

  useLayoutEffect(() => {
    setIsInited(true);
  }, []);

  useEffect(() => {
    if (isInited) {
      loadJobQueue();
    }
  }, [isInited]);

  function setCompleteJob(data) {
    console.log("data", data);

    if (jobState) {
      dispatch(setLoading());
      KycApiService.completeJobV2(data, jobState.jobId, true)
        .then((res) => {
          if (res.error) {
            console.dir("errr => ", res.error);

            if (res.error.code === 409) {
              dispatch(
                setNotification({
                  type: NOTIFICATION_STATES.error,
                  message:
                    "The job you have actioned has passed its lock period. Your work on this job will not be saved.",
                })
              );

              dispatch(setJobState(null));
              return setTenant("");
            }

            dispatch(
              setNotification({
                type: NOTIFICATION_STATES.error,
                message: "Job Completion failed.",
              })
            );
          } else {
            dispatch(setJobState(null));
            dispatch(
              setNotification({
                type: NOTIFICATION_STATES.success,
                message: "Verification Saved",
              })
            );
          }
        })
        .catch((err) => {
          console.dir("errr => ", err);
          if (err.response && err.response.status === 409) {
            dispatch(
              setNotification({
                type: NOTIFICATION_STATES.error,
                message:
                  "The job you have actioned has passed its lock period. Your work on this job will not be saved and you will be allocated a new job.",
              })
            );

            dispatch(setJobState(null));
            return setTenant("");
          }

          dispatch(
            setNotification({
              type: NOTIFICATION_STATES.error,
              message: "Job Completion failed.",
            })
          );
        })
        .finally(() => {
          dispatch(clearLoading());
          dispatch(
            setJobState({
              ...jobState,
              status: VERIFIER_JOB_STATUS.completed,
            })
          );
          dispatch(setJobState(null));
          setTenant("");
        });
    }
  }

  function handleEndSession() {
    if (!jobState?.jobId) {
      return;
    }

    dispatch(setLoading());
    KycApiService.unlockJob(jobState.jobId)
      .then((res: any) => {
        dispatch(clearLoading());
        dispatch(
          setNotification({
            type: NOTIFICATION_STATES.info,
            message: "Manual verification session ended successfully.",
          })
        );
        setTenant("");
      })
      .catch((err) => {
        dispatch(clearLoading());
        console.dir(err);
        setNotification({
          type: NOTIFICATION_STATES.error,
          message: "Unable to end manual verification session.",
        });
      });
  }

  function escalateJob(jobId: string) {
    dispatch(setLoading());
    KycApiService.escalateJob(jobId)
      .then((res: any) => {
        dispatch(clearLoading());
        dispatch(
          setNotification({
            type: NOTIFICATION_STATES.info,
            message: "Job escalated successfully.",
          })
        );
        setTenant("");
      })
      .catch((err) => {
        dispatch(clearLoading());
        console.dir(err);
        setNotification({
          type: NOTIFICATION_STATES.error,
          message: "Unable to escalate job.",
        });
      });
  }

  return (
    <div>
      {jobState ? (
        <React.Fragment>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{ marginBottom: "24px" }}
          >
            <div>
              <Typography
                style={{
                  fontFamily: "Inter, sans-serif",
                  fontWeight: 600,
                  fontSize: "20px",
                  lineHeight: "28px",
                  color: "#515170",
                }}
              >
                Verify Workspace
              </Typography>
              <Typography
                style={{
                  fontFamily: "Inter, sans-serif",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "24px",
                  color: "#515170",
                  marginTop: "4px",
                }}
              >
                Short description explaining verifying job
              </Typography>
            </div>
            <TruuthButton
              buttontype="secondary"
              size="large"
              startIcon={<EndSession />}
              onClick={handleEndSession}
            >
              End session
            </TruuthButton>
          </Box>
          <MVJobLayout completeJob={setCompleteJob} escalateJob={escalateJob} />
        </React.Fragment>
      ) : null}
    </div>
  );
};

export default ManualVerificationV2;
