/**
 * Verification List
 */
export const SET_VERIFICATION_LIST = 'verification/SET_VERIFICATION_LIST' as const;
export const RESET_VERIFICATION_LIST = 'verification/RESET_VERIFICATION_LIST' as const;
export const SET_VERIFICATION_PAGE_INDEX = 'verification/SET_VERIFICATION_PAGE_INDEX' as const;
export const REFRESH_VERIFICATION_LIST = 'verification/REFRESH_VERIFICATION_LIST' as const;
export const SET_VERIFICATIONS_PER_PAGE = 'verification/SET_VERIFICATIONS_PER_PAGE' as const;

/**
 * Verification Detail
 */
export const SET_VERIFICATION_ID = 'verification/SET_VERIFICATION_ID' as const;
export const SET_VERIFICATION_DETAIL = 'verification/SET_VERIFICATION_DETAIL' as const;
export const SET_VERIFICATION_EDIT_STATES = 'verification/SET_VERIFICATION_EDIT_STATES' as const;
export const SET_VERIFICATION_EDIT_STATE_DETAIL = 'verification/SET_VERIFICATION_EDIT_STATE_DETAIL' as const;
export const SET_VERIFICATION_EDIT_STATE_RESULT = 'verification/SET_VERIFICATION_EDIT_STATE_RESULT' as const;
