import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Box, Breadcrumbs, Typography } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { setCookie, getCookie } from 'shared_components/src/service/common.service';
import { TENANT_STATUS } from 'shared_components/src/service/models/tenant';
import { StatusMessage } from 'shared_components/src/components/tenants';
import { _getSelectedTenant } from '../../store/selectors';
import {
	AccountDetailsPage,
	AccountOverview,
	AddOnsPage,
	FeaturesPage,
	BillingAndPaymentPage,
	PlanPage,
	PlansPage,
	ProductConfigurationPage,
	ProductOverview,
	IntegrationPage,
} from './index';
import { VerificationPage } from '../verification';
import { setTenantAlias } from '../../store/tenant/actions';

const useStyles = makeStyles(() => ({
	tenantlayout: {
		'& .headerNavigation': {
			'& .MuiBreadcrumbs-li': {
				cursor: 'pointer',

				'&:hover': {
					color: '#5D5095',
					textDecoration: 'underline',
				},

				'& .MuiTypography-body1': {
					fontSize: '15px',
				},
			},

			'@media screen and (max-width: 600px)': {
				padding: '16px 0 0',
			},
		},
	},
}));

const TenantLayout = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const featureFlags = useFlags();
	const selectedTenant = getCookie('selectedTenant') ? JSON.parse(getCookie('selectedTenant')) : {};
	const selectedTenantData = _getSelectedTenant();

	const curPath = window.location.pathname;
	const temp = curPath.split('/') || [];

	const [topNav, setTopNav] = useState([] as any);

	useEffect(() => {
		const comp = temp[temp?.length - 1];
		const index = temp.indexOf('kyc');
		const path = temp.slice(index + 1).join('/');
		const productOverview = {
			path: `${temp.slice(index + 1, index + 3).join('/')}/product-overview`,
			name: `${selectedTenant?.organisationName}`,
		};
		const accountOverview = {
			path: `${temp.slice(index + 1, index + 3).join('/')}/account-overview`,
			name: `${selectedTenant?.organisationName}`,
		};
		if (comp === 'account-details') {
			setTopNav([accountOverview, { path, name: 'Account Details' }]);
		} else if (comp === 'billing-payment') {
			setTopNav([accountOverview, { path, name: 'Billing & Payment' }]);
		} else if (comp === 'product-configuration') {
			setTopNav([productOverview, { path, name: 'Product Configuration' }]);
		} else if (comp === 'integration') {
			setTopNav([productOverview, { path, name: 'Integration' }]);
		} else if (comp === 'features') {
			setTopNav([productOverview, { path, name: 'Features' }]);
		} else if (comp === 'add-ons') {
			setTopNav([productOverview, { path, name: 'Add Ons' }]);
		} else if (comp === 'plan') {
			setTopNav([productOverview, { path, name: 'Plan' }]);
		} else if (comp === 'change-plan') {
			const plan = {
				path: temp.slice(index + 1, temp.length - 1).join('/'),
				name: 'Plan',
			};
			setTopNav([productOverview, plan, { path, name: 'Changing Plan' }]);
		} else if (comp === 'verification') {
			setTopNav([productOverview, { path, name: 'Verifications' }]);
		} else {
			setTopNav([]);
		}
	}, [curPath]);

	useEffect(() => {
		if (selectedTenant.alias) {
			dispatch(setTenantAlias(selectedTenant.alias));
		}
	}, [selectedTenant.alias]);

	const showTenantSettings = useMemo(() => {
		return !featureFlags['Onboarding-v1'];
	}, [featureFlags]);

	const renderPage = () => {
		const comp = temp[temp?.length - 1];
		if (comp === 'product-overview') {
			return <ProductOverview />;
		} else if (comp === 'account-overview') {
			return <AccountOverview />;
		} else if (comp === 'account-details') {
			return <AccountDetailsPage />;
		} else if (showTenantSettings && comp === 'product-configuration') {
			return <ProductConfigurationPage />;
		} else if (showTenantSettings && comp === 'integration') {
			return <IntegrationPage />;
		} else if (showTenantSettings && comp === 'features') {
			return <FeaturesPage />;
		} else if (showTenantSettings && comp === 'add-ons') {
			return <AddOnsPage />;
		} else if (showTenantSettings && comp === 'billing-payment') {
			return <BillingAndPaymentPage />;
		} else if (showTenantSettings && comp === 'plan') {
			return <PlanPage />;
		} else if (showTenantSettings && comp === 'change-plan') {
			return <PlansPage />;
		} else if (comp === 'verification') {
			return <VerificationPage />;
		}
	};

	const showStatusMessage = useMemo(() => {
		return selectedTenantData?.status === TENANT_STATUS.CANCELLED;
	}, [selectedTenantData]);

	const statusMessageType = useMemo(() => {
		switch (selectedTenantData?.status) {
			case TENANT_STATUS.CANCELLED:
				return 'ERROR';
			default:
				return 'INFO';
		}
	}, [selectedTenantData]);

	const statusMessageElement = () => {
		return (
			<StatusMessage type={statusMessageType}>
				<div>
					<div>
						Please “Subscribe Again” on{' '}
						<span
							style={{
								color: '#2160F6',
								cursor: 'pointer',
								textDecoration: 'underline',
							}}
							onClick={() => handleNavigation(`tenant/${selectedTenant.alias}/account-details`)}
						>
							“Account Details”
						</span>{' '}
						to use all features.
					</div>
				</div>
			</StatusMessage>
		);
	};

	const handleNavigation = (page) => {
		navigate(`/${page}`);
	};

	return (
		<Box className={clsx(classes.tenantlayout)}>
			{showStatusMessage && statusMessageElement()}
			<Breadcrumbs aria-label='breadcrumb' style={{ color: '#70708F' }} className='headerNavigation'>
				{topNav.map((nav) => (
					<Typography key={nav.path} color='inherit' onClick={() => handleNavigation(nav.path)}>
						{nav.name}
					</Typography>
				))}
			</Breadcrumbs>
			{renderPage()}
		</Box>
	);
};

export default TenantLayout;
