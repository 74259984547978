import React, { useState, useEffect, useLayoutEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';

import { OutlinedInfoIcon } from 'shared_components/src/common/icons';
import MVCheckResult from 'shared_components/src/components/common/MVCheckResult';
import { STATUS, IDocumentSecuritySubCheck } from 'shared_components/src/service/models/verification';

/**
 * Props
 */
interface MVCheckResultProps {
	checkName: string;
	checkScore: number;
	checkStatus: STATUS;
	subChecks: IDocumentSecuritySubCheck[];
}

function MVChecksResult({ checkName, checkScore, checkStatus, subChecks }: MVCheckResultProps) {
	return (
		<Box display='flex' flexDirection='column'>
			<Box display='flex' alignItems='center'>
				<svg
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M23.5 17L18.5 22L15 18.5L16.5 17L18.5 19L22 15.5L23.5 17ZM6 2C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H13.81C13.45 21.38 13.2 20.7 13.08 20H6V4H13V9H18V13.08C18.33 13.03 18.67 13 19 13C19.34 13 19.67 13.03 20 13.08V8L14 2M8 12V14H16V12M8 16V18H13V16H8Z'
						fill='#70708F'
					/>
				</svg>

				<span
					style={{
						fontFamily: 'Inter, sans-serif',
						fontWeight: 600,
						fontSize: '1rem',
						lineHeight: '1.5rem',
						color: '#515170',
						marginLeft: '10px',
					}}
				>
					Check Result
				</span>
			</Box>
			<Box sx={{ marginTop: '1rem' }} display='flex' alignItems='center'>
				<OutlinedInfoIcon fill='none' />
				<MVCheckResult
					key={'sd'}
					status={checkStatus}
					checkName={checkName}
					checkScore={checkScore}
					// style={{ marginBottom: '1rem' }}
					subChecks={subChecks}
				/>
			</Box>
		</Box>
	);
}

export default MVChecksResult;
