import * as ActionTypes from './actionTypes';
import { TenantAction } from './actions';
import { VERIFICATION_STATES } from 'shared_components/src/service/models';
import { TENANT_MODE } from 'shared_components/src/service/models/tenant';

type Tenant = {
	countryList: [];
	tenants: {};
	tenantAlias: string;
	filter: string;
	kycState: string;
	tenantPlan: {};
	selectedTenant: {};
	accountDetailsMode: string;
	tenantsPerPage: number;
	tenantsPageIndex: number;
};

const initialState: Tenant = {
	countryList: [],
	tenants: {},
	tenantAlias: '',
	filter: '',
	selectedTenant: {},
	tenantPlan: {},
	kycState: VERIFICATION_STATES[0].value,
	accountDetailsMode: TENANT_MODE.VIEW,
	tenantsPerPage: 10,
	tenantsPageIndex: 1,
};

const TenantReducer = (state: Tenant = initialState, action: TenantAction) => {
	switch (action.type) {
		case ActionTypes.SET_TENANT_LIST:
			return {
				...state,
				tenants: {
					...state.tenants,
					...(action.payload as Object),
				},
			};

		case ActionTypes.SET_TENANT_ALIAS:
			return {
				...state,
				tenantAlias: action.payload,
			};

		case ActionTypes.SET_TENANT_FILTER:
			return {
				...state,
				filter: action.payload,
			};

		case ActionTypes.SET_TENANT_KYCSTATE:
			return {
				...state,
				kycState: action.payload,
			};

		case ActionTypes.SET_SELECTED_TENANT:
			return {
				...state,
				selectedTenant: action.payload,
			};

		case ActionTypes.SET_TENANT_PLAN:
			return {
				...state,
				tenantPlan: action.payload,
			};

		case ActionTypes.SET_ACCOUNT_DETAILS_MODE:
			return {
				...state,
				accountDetailsMode: action.payload,
			};

		case ActionTypes.SET_TENANT_PAGE_INDEX:
			return {
				...state,
				tenantsPageIndex: action.payload,
			};

		case ActionTypes.SET_TENANTS_PER_PAGE:
			return {
				...state,
				tenantsPerPage: action.payload,
			};

		case ActionTypes.SET_TENANT_COUNTRY_LIST:
			return {
				...state,
				countryList: action.payload,
			};

		default:
			return state;
	}
};

export default TenantReducer;
