import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function IDPResponse() {
	const navigate = useNavigate();
	const location = useLocation();

	React.useEffect(() => {
		navigate('/login', { state: { params: location.search } });
	}, [location]);

	return <div></div>;
}

export default IDPResponse;
