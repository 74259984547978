import React from "react";
import { Grid, Box, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { AdditionalInfo } from "./svg/AdditionalInfo";

const TextArea = styled(TextField)(() => ({
  "& .MuiInputBase-root": {
    color: "#343451",
    border: "1px solid #B3B3C6",
    padding: "1rem",
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Inter, sans-serif",
    lineHeight: "24px",

    "& fieldset": {
      display: "none",
    },
  },
}));

interface IProps {
  detail: Object;
}

const DVSAdditionalInfo = ({ detail = {} }: IProps) => {
  return (
    <Grid
      container
      style={{
        margin: 0,
        background: "white",
        overflow: "hidden",
        padding: "24px 0",
        borderBottom: "1px solid #EDEDF3",
      }}
    >
      <Grid item xs={12}>
        <Box
          display="flex"
          alignItems="center"
          style={{ marginBottom: "16px" }}
        >
          <AdditionalInfo />
          <span
            style={{
              fontFamily: "Inter, sans-serif",
              fontWeight: 600,
              fontSize: "1rem",
              lineHeight: "1.5rem",
              color: "#515170",
              marginLeft: "10px",
            }}
          >
            Additional Information
          </span>
        </Box>
        <TextArea fullWidth multiline value={JSON.stringify(detail)} />
      </Grid>
    </Grid>
  );
};

export default DVSAdditionalInfo;
