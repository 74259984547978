import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLDClient, useFlags } from "launchdarkly-react-client-sdk";
import { Typography, Button, Box } from "@mui/material";
import { PlayCircleOutlineOutlined } from "@mui/icons-material";

import { NOTIFICATION_STATES } from "shared_components/src/common/constants";
import { ManualVerification as MVIcon } from "shared_components/src/common/icons";
import { VERIFIER_JOB_TYPES } from "shared_components/src/service/models/manual-verification";

import { _getAuthRoles, _getVerifierJobState } from "../../store/selectors";
import {
  updateMenu,
  clearLoading,
  setLoading,
  setNotification,
  setJobState,
} from "../../store/actions";
import KycApiService from "../../service/kycApi.service";
import { MENU } from "../../common/routes/menu";

import ManualVerificationV1 from "./ManualVerificationPage";
import ManualVerificationV2 from "./ManualVerificationV2";
import MVJobFilterV2 from "./MVJobFilterV2";

/**
 * TYPES
 */
export type MV_FILTERS = {
  jobTypes?: VERIFIER_JOB_TYPES[];
  tenants?: string[];
  escalationStatus?: "ESCALATED" | "NOT_ESCALATED" | "ALL";
};

/**
 * MAIN COMPONENT
 */
const ManualVerification = () => {
  const dispatch = useDispatch();
  const ldClient = useLDClient();
  const featureFlags = useFlags();

  const [tenant, setTenant] = useState("");
  const [isDocumentProofingEnabled, setIsDocumentProofingEnabled] = useState<
    boolean | null
  >(null);
  const [isV2Authenticity, setIsV2Authenticity] = useState<boolean | null>(
    null
  );
  const [isV2FaceMatch, setIsV2FaceMatch] = useState<boolean | null>(null);
  const [isV2OCR, setIsV2OCR] = useState<boolean | null>(null);
  const [isV2NameMatch, setIsV2NameMatch] = useState<boolean | null>(null);
  const [isV2DVS, setIsV2DVS] = useState<boolean | null>(null);

  const jobState = _getVerifierJobState();

  useEffect(() => {
    if (tenant && ldClient) {
      ldClient &&
        ldClient.identify({ key: tenant }, "", (err: any, data: any) => {
          if (err) {
            console.dir("error", err);
          }

          setIsDocumentProofingEnabled(data["document-proofing-feature"]);
          setIsV2Authenticity(data["MV2-Authenticity"]);
          setIsV2FaceMatch(data["MV2-FaceMatch"]);
          setIsV2OCR(data["MV2-OCR"]);
          setIsV2NameMatch(data["MV2-NameMatch"]);
          setIsV2DVS(data["MV2-DVS"]);
        });
    }
  }, [tenant]);

  useEffect(() => {
    dispatch(updateMenu(MENU.manualVerify));
  }, []);

  const handleStart = (filters?: MV_FILTERS) => {
    const isV2 = featureFlags["MV2-CompleteJob"] || false;

    dispatch(setLoading());
    setTenant("");
    KycApiService.popJobQueueV2(isV2, filters)
      .then((res: any) => {
        if (res && !res.error) {
          console.log("job", res);
          const tenantAlias =
            res.jobDetail?.input?.tenantAlias || res?.tenantAlias || "client";
          if (tenantAlias) {
            setTenant(tenantAlias);
          }
          dispatch(setJobState(res));
        } else {
          if (
            res &&
            res.error &&
            (res.error.code === 400 || res.error.code === 404)
          )
            dispatch(
              setNotification({
                type: NOTIFICATION_STATES.info,
                message: "No current jobs in the queue.",
              })
            );
        }
      })
      .catch((err: any) => {
        dispatch(
          setNotification({
            type: NOTIFICATION_STATES.error,
            message: err.message,
          })
        );
      })
      .finally(() => {
        dispatch(clearLoading());
      });
  };

  if (
    !jobState ||
    !tenant ||
    isDocumentProofingEnabled === null ||
    isV2Authenticity === null ||
    isV2FaceMatch === null ||
    isV2OCR === null ||
    isV2NameMatch === null ||
    isV2DVS === null
  ) {
    return (
      <React.Fragment>
        <Box display="flex" alignItems="center" sx={{ marginBottom: "24px" }}>
          <MVIcon />
          <Typography
            style={{
              fontFamily: "Inter, sans-serif",
              fontWeight: 600,
              fontSize: "26px",
              lineHeight: "32px",
              color: "#343451",
              marginLeft: "10px",
            }}
          >
            Manual Verification
          </Typography>
        </Box>
        {featureFlags["MV2-JobFilter"] ? (
          <MVJobFilterV2 fetchJobs={handleStart} />
        ) : (
          <Button
            onClick={(e) => handleStart()}
            size="large"
            startIcon={<PlayCircleOutlineOutlined />}
            style={{
              backgroundColor: "#470D82",
              color: "white",
              textTransform: "none",
              borderRadius: "6px",
              paddingLeft: "1rem",
              paddingRight: "1rem",
            }}
          >
            Start Verify
          </Button>
        )}
      </React.Fragment>
    );
  }

  if (
    isDocumentProofingEnabled === true &&
    ((isV2Authenticity === true &&
      jobState?.jobType === VERIFIER_JOB_TYPES.authenticity) ||
      (isV2FaceMatch === true &&
        jobState?.jobType === VERIFIER_JOB_TYPES.faceMatch) ||
      (isV2OCR === true && jobState?.jobType === VERIFIER_JOB_TYPES.ocr) ||
      (isV2NameMatch === true &&
        jobState?.jobType === VERIFIER_JOB_TYPES.nameMatch) ||
      (isV2DVS === true && jobState?.jobType === VERIFIER_JOB_TYPES.dvs))
  ) {
    return <ManualVerificationV2 setTenant={setTenant} />;
  } else {
    return <ManualVerificationV1 setTenant={setTenant} />;
  }
};

export default ManualVerification;
