import React, { useState } from "react";
import { Box, Typography, Grid, Dialog, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";

import {
  TruuthAccordion,
  TruuthAccordionSummary,
  TruuthAccordionDetails,
  TruuthButton,
  TruuthChip,
} from "shared_components/src/components/common";
import {
  VERIFIER_JOB_STATUS,
  VERIFIER_JOB_RESULT_STATUS_V2 as VERIFIER_JOB_RESULT_STATUS,
  VERIFIER_JOB_RESULT_STATUS_REASON,
} from "shared_components/src/service/models/manual-verification";
import { DownArrowIcon, CloseIcon } from "shared_components/src/common/icons";
import { STATUS } from "shared_components/src/service/models/verification";
import MVCheckResult from "shared_components/src/components/common/MVCheckResult";
import Status from "shared_components/src/components/common/Status";

import { _getVerifierJobState } from "../../store/selectors";
import MVImagesV2 from "./MVImagesV2";
import {
  DocumentInformation,
  Escalate,
  Pass,
  NotPerformed,
  Fail,
  Warning,
  Reason,
  LinkingDocument,
} from "./svg";
import { REASONS } from "./constants/Reasons";

const DialogBox = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    boxShadow: "1px 1px 15px 0px #20203A14",
    borderRadius: "5px",
  },
}));

/**
 * Types
 */

/**
 * IProps
 */
interface IProps {
  completeJob: (data: any) => void;
  escalateJob: (jobId: string) => void;
}

function MVAuthenticityV2({ completeJob, escalateJob }: IProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedButton, setSelectedButton] =
    useState<VERIFIER_JOB_RESULT_STATUS | null>(null);
  const job = _getVerifierJobState();
  const jobDetailInput = job?.jobDetail?.input;

  const documents = jobDetailInput?.documents || [];
  const linkingDocuments = documents.filter(
    (doc) => doc.documentCategory === "LINKING"
  );
  const primaryDocuments = documents.filter(
    (doc) => doc.documentCategory !== "LINKING"
  );

  const handleOpenModal = (buttonType: VERIFIER_JOB_RESULT_STATUS) => {
    setSelectedButton(buttonType);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedButton(null);
  };

  const handleEscalateJob = () => {
    if (!job?.jobId) {
      return;
    }

    escalateJob(job.jobId);
  };

  const handleCompleteJob = (
    status: VERIFIER_JOB_RESULT_STATUS,
    statusReason?: string
  ) => {
    if (!job?.jobId) {
      return;
    }

    const data: any = {
      jobId: job?.jobId,
      status: VERIFIER_JOB_STATUS.completed,
      jobDetail: {
        input: jobDetailInput || null,
        output: {
          subChecks: jobDetailInput.subChecks,
          score: jobDetailInput.score,
        },
      },
    };

    switch (status) {
      case VERIFIER_JOB_RESULT_STATUS.passed:
        data.jobDetail.output.status = VERIFIER_JOB_RESULT_STATUS.passed;
        data.jobDetail.output.statusReason =
          VERIFIER_JOB_RESULT_STATUS_REASON.authentic;
        // data.jobDetail.output.authenticityScore = 0.98;
        break;
      case VERIFIER_JOB_RESULT_STATUS.failed:
        data.jobDetail.output.status = VERIFIER_JOB_RESULT_STATUS.failed;
        data.jobDetail.output.statusReason = statusReason
          ? statusReason
          : VERIFIER_JOB_RESULT_STATUS_REASON.fake;
        // data.jobDetail.output.authenticityScore = 0.08;
        break;
      case VERIFIER_JOB_RESULT_STATUS.notPerformed:
        data.jobDetail.output.status = VERIFIER_JOB_RESULT_STATUS.notPerformed;
        data.jobDetail.output.statusReason = statusReason ? statusReason : "";
        break;
      case VERIFIER_JOB_RESULT_STATUS.warning:
        data.jobDetail.output.status = VERIFIER_JOB_RESULT_STATUS.warning;
        data.jobDetail.output.statusReason = statusReason ? statusReason : "";
        break;
      default:
        break;
    }

    completeJob(data);
  };

  const renderReasons = () => {
    if (!selectedButton) {
      return null;
    }

    return REASONS["DOCUMENT_AUTHENTICITY"][selectedButton].map(
      (reason, index) => (
        <div key={`${index}${reason.key}`} style={{ margin: "0 0 16px 24px" }}>
          <TruuthChip
            code={reason.key}
            label={reason.text}
            selected={false}
            isMultipleSelection={false}
            variant="outlined"
            background="color"
            onClick={() => handleCompleteJob(selectedButton, reason.key)}
          />
        </div>
      )
    );
  };

  const renderDocument = () => {
    return linkingDocuments.length > 0 ? (
      <Box sx={{ marginTop: "24px" }}>
        <Box display="flex" alignItems="center">
          <LinkingDocument />
          <span
            style={{
              fontFamily: "Inter, sans-serif",
              fontWeight: 600,
              fontSize: "1rem",
              lineHeight: "1.5rem",
              color: "#515170",
              marginLeft: "10px",
            }}
          >
            Linking Document
          </span>
        </Box>

        {linkingDocuments.map((doc) => {
          return (
            <TruuthAccordion
              key={doc.documentClassificationCode}
              sx={{ marginTop: "1.5rem" }}
            >
              <TruuthAccordionSummary
                id="document-header"
                expandIcon={<DownArrowIcon />}
                aria-controls="document-information"
                sx={{ padding: "8px 32px" }}
              >
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center">
                    <Typography
                      style={{
                        fontFamily: "Inter, sans-serif",
                        fontWeight: 600,
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#343451",
                      }}
                    >
                      {doc?.documentDisplayName || ""}
                    </Typography>
                    {doc?.documentClassificationCode && (
                      <span
                        style={{
                          width: "8px",
                          height: "8px",
                          borderRadius: "100%",
                          backgroundColor: "#D0D0DD",
                          display: "inline-block",
                          marginLeft: "16px",
                        }}
                      ></span>
                    )}
                    <Typography
                      style={{
                        fontFamily: "Inter, sans-serif",
                        fontWeight: 500,
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#515170",
                        marginLeft: "16px",
                      }}
                    >
                      {doc?.documentClassificationCode || ""}
                    </Typography>
                  </Box>
                  <Typography
                    style={{
                      marginTop: "4px",
                      fontWeight: 400,
                      fontFamily: "Inter, sans-serif",
                      lineHeight: "24px",
                      fontSize: "14px",
                      color: "#515170",
                      display: "block",
                    }}
                  >
                    {doc?.documentDescription || ""}
                  </Typography>
                </Box>
              </TruuthAccordionSummary>

              <TruuthAccordionDetails sx={{ padding: "24px 24px 24px 32px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <MVImagesV2
                      faceImage={jobDetailInput?.faceImage || ""}
                      documentImages={doc?.documentImages || []}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                </Grid>
              </TruuthAccordionDetails>
            </TruuthAccordion>
          );
        })}
      </Box>
    ) : (
      <Box sx={{ marginTop: "24px" }}>
        <Box display="flex" alignItems="center">
          <DocumentInformation />
          <span
            style={{
              fontFamily: "Inter, sans-serif",
              fontWeight: 600,
              fontSize: "1rem",
              lineHeight: "1.5rem",
              color: "#515170",
              marginLeft: "10px",
            }}
          >
            Document Information
          </span>
        </Box>

        {primaryDocuments.map((doc) => {
          return (
            <TruuthAccordion
              key={doc.documentClassificationCode}
              sx={{ marginTop: "1.5rem" }}
            >
              <TruuthAccordionSummary
                id="document-header"
                expandIcon={<DownArrowIcon />}
                aria-controls="document-information"
                sx={{ padding: "8px 32px" }}
              >
                <Box display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center">
                    <Typography
                      style={{
                        fontFamily: "Inter, sans-serif",
                        fontWeight: 600,
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#343451",
                      }}
                    >
                      {doc?.documentDisplayName || ""}
                    </Typography>
                    {doc?.documentClassificationCode && (
                      <span
                        style={{
                          width: "8px",
                          height: "8px",
                          borderRadius: "100%",
                          backgroundColor: "#D0D0DD",
                          display: "inline-block",
                          marginLeft: "16px",
                        }}
                      ></span>
                    )}
                    <Typography
                      style={{
                        fontFamily: "Inter, sans-serif",
                        fontWeight: 500,
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: "#515170",
                        marginLeft: "16px",
                      }}
                    >
                      {doc?.documentClassificationCode || ""}
                    </Typography>
                  </Box>
                  <Typography
                    style={{
                      marginTop: "4px",
                      fontWeight: 400,
                      fontFamily: "Inter, sans-serif",
                      lineHeight: "24px",
                      fontSize: "14px",
                      color: "#515170",
                      display: "block",
                    }}
                  >
                    {doc?.documentDescription || ""}
                  </Typography>
                </Box>
              </TruuthAccordionSummary>

              <TruuthAccordionDetails sx={{ padding: "24px 24px 24px 32px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <MVImagesV2
                      faceImage={jobDetailInput?.faceImage || ""}
                      documentImages={doc?.documentImages || []}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                </Grid>
              </TruuthAccordionDetails>
            </TruuthAccordion>
          );
        })}
      </Box>
    );
  };

  const renderDialogStatus = (status: STATUS | null) => {
    if (!status) {
      return;
    }

    let color = "";
    let stat = "";

    switch (status) {
      case "PASS":
      case "PASSED":
        (color = "#0A7552"), (stat = "Pass");
        break;
      case "FAIL":
      case "FAILED":
        (color = "#C6323B"), (stat = "Fail");
        break;
      case "WARNING":
        (color = "#97640C"), (stat = "Warning");
        break;
      case "NOT_PERFORMED":
        (color = "#70708F"), (stat = "Not Performed");
    }

    return (
      <span
        style={{
          color,
          fontFamily: "Inter, sans-serif",
          fontWeight: 600,
          fontSize: "12px",
          lineHeight: "24px",
          marginLeft: "8px",
        }}
      >
        {stat}
      </span>
    );
  };

  return (
    <React.Fragment>
      {renderDocument()}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          paddingTop: "24px",
          borderTop: "1px solid #EDEDF3",
        }}
      >
        <TruuthButton
          buttontype="tertiary"
          size="medium"
          startIcon={<Escalate />}
          onClick={handleEscalateJob}
          disabled={job?.escalated || false}
        >
          Escalate
        </TruuthButton>
        <Box>
          <TruuthButton
            buttontype="tertiary"
            size="medium"
            sx={{ marginLeft: "16px" }}
            startIcon={<Pass />}
            onClick={() => handleCompleteJob(VERIFIER_JOB_RESULT_STATUS.passed)}
          >
            Pass
          </TruuthButton>
          <TruuthButton
            buttontype="tertiary"
            size="medium"
            sx={{ marginLeft: "16px" }}
            startIcon={<NotPerformed />}
            onClick={() =>
              handleOpenModal(VERIFIER_JOB_RESULT_STATUS.notPerformed)
            }
          >
            Not Performed
          </TruuthButton>
          <TruuthButton
            buttontype="tertiary"
            size="medium"
            sx={{ marginLeft: "16px" }}
            startIcon={<Fail />}
            onClick={() => handleOpenModal(VERIFIER_JOB_RESULT_STATUS.failed)}
          >
            Fail
          </TruuthButton>
          <TruuthButton
            buttontype="tertiary"
            size="medium"
            sx={{ marginLeft: "16px" }}
            startIcon={<Warning />}
            onClick={() => handleOpenModal(VERIFIER_JOB_RESULT_STATUS.warning)}
          >
            Warning
          </TruuthButton>
        </Box>
      </Box>

      <DialogBox open={modalOpen} onClose={closeModal} maxWidth="md">
        <div
          style={{
            display: "flex",
            backgroundColor: "#F0F0F4",
            padding: "16px 24px",
            borderBottom: "1px solid #DCDCE5",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              width: "100%",
              textAlign: "center",
              fontFamily: "Inter, sans-serif",
              fontSize: "18px",
              fontWeight: 600,
              lineHeight: "24px",
              color: "#20203A",
            }}
          >
            Submit Job
          </Typography>
          <IconButton
            aria-label="close"
            onClick={closeModal}
            style={{ padding: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <Box
          sx={{
            padding: "16px 0",
            borderBottom: "1px solid #DCDCE5",
            margin: "0 24px",
          }}
        >
          <Typography
            style={{
              fontFamily: "Inter, sans-serif",
              fontSize: "16px",
              lineHeight: "32px",
              color: "#515170",
              fontWeight: 400,
              display: "flex",
              alignItems: "center",
            }}
          >
            Job {job?.jobId}
            <b style={{ margin: "0 4px" }}>Authenticity Check</b>
            is selected as
            <Status
              status={selectedButton || "PASS"}
              height={16}
              width={16}
            />{" "}
            {renderDialogStatus(selectedButton)}
          </Typography>
          <Typography
            style={{
              fontFamily: "Inter, sans-serif",
              fontSize: "16px",
              lineHeight: "32px",
              color: "#515170",
              fontWeight: 400,
            }}
          >
            Please select a reason to submit this job.
          </Typography>
          <Typography
            style={{
              fontFamily: "Inter, sans-serif",
              fontSize: "16px",
              lineHeight: "32px",
              color: "#515170",
              fontWeight: 400,
            }}
          >
            After selecting a reason, this job will be automatically submitted.
          </Typography>
        </Box>
        <Box sx={{ padding: "24px" }}>
          <Box display="flex" alignItems="center" sx={{ marginBottom: "16px" }}>
            <Reason />
            <Typography
              style={{
                fontFamily: "Inter, sans-serif",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#515170",
                fontWeight: 600,
                marginLeft: "10px",
              }}
            >
              Reason
            </Typography>
          </Box>
          {renderReasons()}
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          sx={{
            padding: "16px 24px",
            borderTop: "1px solid #EDEDF3",
          }}
        >
          <TruuthButton
            buttontype="tertiary"
            size="medium"
            onClick={closeModal}
          >
            Cancel
          </TruuthButton>
        </Box>
      </DialogBox>
    </React.Fragment>
  );
}

export default MVAuthenticityV2;
