import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { styled, Box, Container } from '@mui/material';

import { NOTIFICATION_STATES } from 'shared_components/src/common/constants';

import { clearLoading, setLoading, setNotification } from '../../store/actions';
import KycApiService from '../../service/kycApi.service';

/**
 * STYLES
 */
const Image = styled('img')((props) => ({
	width: '90%',
	height: 'auto',
	display: 'block',
	margin: 'auto',
}));

/**
 * TYPES
 */
interface IProps {}

/**
 * Main Component
 */
const ImageViewer = () => {
	const dispatch = useDispatch();
	const { imageUrl } = useParams<{ imageUrl: string }>();

	const [image, setImage] = useState('');

	useEffect(() => {
		if (imageUrl) {
			getImageData(imageUrl).then((res: string) => {
				if (res) {
					setImage(res);
				} else {
					dispatch(
						setNotification({
							type: NOTIFICATION_STATES.error,
							message: 'Unable to fetch the image. Please try again!',
						})
					);
				}
			});
		}
	}, [imageUrl]);

	const getImageData = (url: string): Promise<string> => {
		return new Promise((resolve) => {
			dispatch(setLoading());
			KycApiService.getImageDataFromS3(url)
				.then(async (res) => {
					try {
						const blobToBase64 = (blob) => {
							const reader = new FileReader();
							reader.readAsDataURL(blob);
							return new Promise((reslve) => {
								reader.onloadend = () => {
									reslve(reader.result);
								};
							});
						};
						if (res) {
							const imgData = (await blobToBase64(res)) as string;
							resolve(imgData);
						} else {
							resolve('');
						}
					} catch (e) {
						resolve('');
					}
				})
				.catch((err) => {
					resolve('');
				})
				.finally(() => {
					dispatch(clearLoading());
				});
		});
	};

	return (
		<Container maxWidth='md'>
			<Box mt={4} mb={4}>
				{image ? <Image src={image} alt='Image' /> : <p>Loading...</p>}
			</Box>
		</Container>
	);
};

export default ImageViewer;
