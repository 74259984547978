import React from 'react';

interface IProps {
	sourceCheckProvider: String;
	requestNumber: String;
}

const ProviderInfo = ({ sourceCheckProvider, requestNumber }: IProps) => {
	return (
		<div
			style={{
				fontFamily: 'Inter, sans-serif',
				color: '#515170',
				fontSize: '14px',
				lineHeight: '30px',
				margin: '12px 0 0 32px',
			}}
		>
			<div>
				<span style={{ marginRight: '16px' }}>Source Check Provider</span>
				<span style={{ fontWeight: 600 }}>{sourceCheckProvider || ''}</span>
			</div>
			<div>
				<span style={{ marginRight: '16px' }}>Provider Reference ID</span>
				<span style={{ fontWeight: 600 }}>{requestNumber || ''}</span>
			</div>
		</div>
	);
};

export default ProviderInfo;
