import React from 'react';

export function AdditionalInfo() {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none">
			<g clipPath="url(#clip0_5428_26035)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M12 9V7H10V9H12ZM12 17V11H10V17H12ZM1 12C1 6.48 5.48 2 11 2C16.52 2 21 6.48 21 12C21 12.406 20.9758 12.8065 20.9286 13.1999C20.3648 12.7497 19.7105 12.4081 18.9974 12.2067C18.9991 12.138 19 12.0691 19 12C19 7.59 15.41 4 11 4C6.59 4 3 7.59 3 12C3 16.41 6.59 20 11 20C11.5221 20 12.0326 19.9497 12.5271 19.8537C12.842 20.5178 13.286 21.1089 13.8268 21.5943C12.9303 21.8583 11.9816 22 11 22C5.48 22 1 17.52 1 12Z"
					fill="#70708F"
				/>
				<path d="M22 17V19H19V22H17V19H14V17H17V14H19V17H22Z" fill="#70708F" />
			</g>
			<defs>
				<clipPath id="clip0_5428_26035">
					<rect width="24" height="24" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}
