import React, { useEffect, useMemo, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import _ from 'lodash';

import Routers from '../common/routes/Routers';
import { AdminTheme, makeTheme } from '../common/themes/adminTheme';

import store from '../store';
import KycApiService from '../service/kycApi.service';
import { Loading, NotMatch } from 'shared_components/src/components/common';
import { clearCookie, setCookie } from 'shared_components/src/service/common.service';
import {
	CONFIGURATION_COOKIES,
	APP_URL_PREFIX,
	GENERAL_COOKIES,
	CHARGEBEE_PUBLISHABLE_KEY,
	CHARGEBEE_SITE,
} from 'shared_components/src/common/constants';
import { getAdminConfigUrl } from 'shared_components/src/common/utils';
import AuthService from '../service/auth.service';
import { getCookie } from 'shared_components/src/service/common.service';

/**
 *  APP
 */
const App = () => {
	const [config, setConfig] = useState({ loading: true, data: {} as any });
	const { loading, data } = config;
	const [constructorHasRun, setConstructorHasRun] = useState(false);
	const biopassAuth = getCookie('biopass-auth');

	const constructor = () => {
		if (constructorHasRun) return;
		window['Chargebee'].init({
			site: CHARGEBEE_SITE,
			publishableKey: CHARGEBEE_PUBLISHABLE_KEY,
		});
		setConstructorHasRun(true);
	};
	constructor();

	const tenant = useMemo(() => {
		const baseUrl = window.location.hostname;
		let _tenant = '';

		if (baseUrl == 'localhost') {
			_tenant = 'console';
		} else {
			if (baseUrl.includes('truuth.id')) {
				const splittedUrl = baseUrl.split('.');
				_tenant = splittedUrl.length > 0 ? splittedUrl[0] : '';
			} else {
				_tenant = 'console';
			}
		}

		return _tenant;
	}, []);

	const theme = useMemo(() => {
		const _theme = AdminTheme;
		const style = {
			primaryColor: {
				main: '#8c07dd',
				dark: '#8c07dd',
			},
			secondaryColor: {
				main: '#3b86ff',
				light: '#c3daff',
				dark: '#EF9700',
			},
			theme: '',
			cssFile: '',
		};

		if (data && !data?.error) {
			if (data?.style?.primaryColor) {
				Object.keys(style.primaryColor).forEach((key) => {
					if (_theme?.palette?.primary) {
						_.set(_theme?.palette?.primary, key, style.primaryColor[key]);
					}
				});
			}

			if (data?.style?.secondaryColor) {
				Object.keys(style.secondaryColor).forEach((key) => {
					if (_theme?.palette?.secondary) {
						_.set(_theme.palette.secondary, key, style.secondaryColor[key]);
					}
				});
			}
		}

		return makeTheme(_theme);
	}, [data]);

	useEffect(() => {
		if (!tenant) {
			return;
		}

		const configUrl = getAdminConfigUrl(tenant);

		KycApiService.getConfiguration(configUrl)
			.then((res) => {
				setCookie(tenant, JSON.stringify(res), 1);
				setCookie(CONFIGURATION_COOKIES.tenant, tenant, 1);
				KycApiService.getApiUrlFromCookie();

				if (biopassAuth === 'true') {
					AuthService.initNew();
				} else {
					AuthService.init();
				}

				setConfig({
					loading: false,
					data: res,
				});
			})
			.catch((err) => {
				clearCookie();
				setConfig({
					loading: false,
					data: { error: 'Not found data' },
				});
			});
	}, [tenant]);

	useEffect(() => {
		function getRegion() {
			fetch('https://get.geojs.io/v1/ip/geo.json')
				.then(function (response) {
					return response.json();
				})
				.then(function (payload) {
					const region = {
						country: payload.country,
						countryCode: payload.country_code,
						countryCode3: payload.country_code3,
					};
					setCookie('region', JSON.stringify(region));
				})
				.catch(() => {
					const region = {
						country: 'Australia',
						countryCode: 'AU',
						countryCode3: 'AUS',
					};
					setCookie('region', JSON.stringify(region));
				});
		}

		getRegion();
	}, []);

	if (!tenant) {
		return <></>;
	}

	return (
		<Provider store={store}>
			<ThemeProvider theme={theme}>
				{/* <LDProvider> */}
				{loading ? (
					<Loading open={loading} />
				) : data.error ? (
					<NotMatch />
				) : (
					<Router basename={APP_URL_PREFIX}>
						<Routers />
					</Router>
				)}
				{/* </LDProvider> */}
			</ThemeProvider>
		</Provider>
	);
};

export default App;
