import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateMenu } from '../../store/menu/actions';
import { MENU } from '../../common/routes/menu';
import {
	SubscriptionFooter,
	ConfirmDialog,
	DisplayPlans,
	PlanConfirmDialog,
	PlanUpdateDialog,
} from 'shared_components/src/components/tenants';
import { NOTIFICATION_STATES } from 'shared_components/src/common/constants';
import { TENANT_PLAN, TENANT_PLAN_NAMES } from 'shared_components/src/service/models/tenant';
import { _getSelectedTenant, _getTenantPlan, _getProducts } from '../../store/selectors';
import { setTenantPlan, setSelectedTenant } from '../../store/tenant/actions';
import { setLoading, clearLoading, setNotification } from '../../store/common/actions';
import kycApiService from '../../service/kycApi.service';
import { getImageUrl } from 'shared_components/src/common/utils';
import { getCookie } from 'shared_components/src/service/common.service';

const PlansPage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const tenant = _getSelectedTenant();
	const plan = _getTenantPlan();
	const products = _getProducts();
	const tenantCookie = getCookie('selectedTenant') ? JSON.parse(getCookie('selectedTenant')) : {};

	const [planUpdateRequest, setPlanUpdateRequest] = useState({} as any);
	const [newPlanName, setNewPlanName] = useState('');
	const [openChangePlanDialog, setOpenChangePlanDialog] = useState(false);
	const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
	const [plansData, setPlansData] = useState([] as any);

	useEffect(() => {
		dispatch(updateMenu(MENU.plan));
	}, [dispatch]);

	const cancelMode = useMemo(() => {
		const paths = window?.location?.pathname.split('/');
		return paths[paths.length - 1] === 'cancel-subscription';
	}, [window?.location?.pathname]);

	const subscribeMode = useMemo(() => {
		const paths = window?.location?.pathname.split('/');
		return paths[paths.length - 1] === 'subscribe';
	}, [window?.location?.pathname]);

	const showSubscriptionButtonOnHeader = useMemo(() => {
		return subscribeMode && plan.code !== TENANT_PLAN.PLAN_FREE;
	}, [plan.code, subscribeMode]);

	const showSubscribeFooter = useMemo(() => {
		return subscribeMode && plan.code === TENANT_PLAN.PLAN_FREE;
	}, [plan.code, subscribeMode]);

	const handleChangePlan = React.useCallback(() => {
		const product = products.find((product) => product.code === tenantCookie?.product.code);
		// direct plans only
		const directPlans = product?.plans.filter((plan) => plan.salesChannel === 'DIRECT');
		let filteredPlans = directPlans?.map((planData) => {
			planData['selected'] = planData.code === plan.code;
			planData['images'] =
				planData.code === TENANT_PLAN.PLAN_FREE
					? [getImageUrl('PLAN_FREE-1.png'), getImageUrl('PLAN_FREE-2.png')]
					: [getImageUrl(`${planData.code}.png`)];
			return planData;
		});

		if (!subscribeMode && plan.code !== TENANT_PLAN.PLAN_FREE) {
			filteredPlans = filteredPlans?.filter((planData) => planData.code !== TENANT_PLAN.PLAN_FREE);
		}

		setPlansData(filteredPlans);
	}, [plan.code, products, subscribeMode]);

	useEffect(() => {
		handleChangePlan();
	}, [products, plan, handleChangePlan]);

	const handlePlanChange = (mode, newPlan) => {
		let currentPlanOrder = 0;
		let newPlanOrder = 0;
		const request = {
			productCode: tenant?.product?.code || tenantCookie?.product?.code,
			planCode: newPlan.code,
			currencyCode: tenant.currency || tenantCookie.currency,
		};
		const product = products.find((product) => product.code === tenant?.product?.code);
		product?.plans?.forEach((planData, index) => {
			if (planData.code === plan.code) {
				currentPlanOrder = index;
			}
			if (planData.code === newPlan.code) {
				newPlanOrder = index;
			}
		});
		setNewPlanName(newPlan.name);

		if (mode === 'SUBSCRIBE') {
			updateTenantPlanInfo(mode, request);
		} else if (newPlanOrder > currentPlanOrder) {
			updateTenantPlanInfo('UPGRADE', request);
		} else {
			setPlanUpdateRequest(request);
			setOpenChangePlanDialog(true);
		}
	};

	const downGradePlan = () => {
		updateTenantPlanInfo('DOWNGRADE', planUpdateRequest);
		setOpenChangePlanDialog(false);
	};

	const getPlanDetails = (productCode: string, planCode: string) => {
		dispatch(setLoading());
		return new Promise((resolve) => {
			kycApiService
				.getTenantPlanInfo(productCode, planCode)
				.then((res: any) => {
					if (!res.error) {
						dispatch(setTenantPlan(res));
					}
				})
				.catch((err: any) => {
					console.log('get PlanDetails err', err);
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const updateTenantPlanInfo = (type, request) => {
		dispatch(setLoading());
		return new Promise((resolve) => {
			kycApiService
				.updateTenantPlanInfo(tenant?.alias, request)
				.then((res: any) => {
					if (!res.error) {
						getPlanDetails(
							tenant?.product?.code || tenantCookie?.product?.code,
							request.planCode
						);
						if (type === 'SUBSCRIBE') {
							dispatch(setSelectedTenant(res));
							displayNotificatoinSuccess(
								`Subscribing again successful. Your plan is now ${
									TENANT_PLAN_NAMES[request.planCode]
								}`
							);
							pushToPlanPage();
						} else if (type === 'UPGRADE') {
							setOpenConfirmationDialog(true);
						} else {
							pushToPlanPage();
						}
					} else {
						if (type === 'SUBSCRIBE') {
							console.error('subscription update error', res.error);
							displayNotificatoinError(
								'Your request for Subscribing Again is incomplete. Please try again after some time.'
							);
						} else {
							console.error('plan update error', res.error);
							displayNotificatoinError('Failed to update Plan change.');
						}
					}
				})
				.catch((err: any) => {
					if (type === 'SUBSCRIBE') {
						console.error('subscription update error', err);
						displayNotificatoinError(
							'Your request for Subscribing Again is incomplete. Please try again after some time.'
						);
					} else {
						console.log('plan update error', err);
						displayNotificatoinError('Failed to update Plan change.');
					}
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const displayNotificatoinSuccess = (msg: string) => {
		dispatch(
			setNotification({
				message: msg,
				type: NOTIFICATION_STATES.success,
			})
		);
	};

	const displayNotificatoinError = (msg: string) => {
		dispatch(
			setNotification({
				message: msg,
				type: NOTIFICATION_STATES.error,
			})
		);
	};

	const onChangePlanDialogClose = () => {
		setOpenChangePlanDialog(false);
	};

	const handleDialogClose = () => {
		setOpenConfirmationDialog(false);
		pushToPlanPage();
	};

	const pushToPlanPage = () => {
		navigate(`/tenant/${tenantCookie.alias}/plan`);
	};

	const confirmCancelSubscription = () => {
		setOpenConfirmDialog(true);
	};

	const subscribeWithCurrentPlan = () => {
		handlePlanChange('SUBSCRIBE', plan);
	};

	const handleCancelSubscription = () => {
		setOpenConfirmDialog(false);
		const request = {
			tenantAlias: tenantCookie.alias,
			planCode: plan.code,
			productCode: tenant?.product?.code || tenantCookie?.product?.code,
		};
		dispatch(setLoading());
		return new Promise((resolve) => {
			kycApiService
				.cancelTenant(request)
				.then((res: any) => {
					if (!res.error) {
						displayNotificatoinSuccess(
							'Your subscription is cancelled successfully. Your status is cancelled now.'
						);
						getTenant(tenantCookie.alias);
						dispatch(setSelectedTenant(res));
						setTimeout(() => pushToPlanPage(), 350);
					} else {
						displayNotificatoinError('Failed to cancel your subscription.');
					}
				})
				.catch((err: any) => {
					displayNotificatoinError('Failed to cancel your subscription.');
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const getTenant = (alias) => {
		dispatch(setLoading());
		return new Promise((resolve) => {
			kycApiService
				.getTenant(alias)
				.then((res: any) => {
					if (!res.error) {
						dispatch(setSelectedTenant(res));
					}
				})
				.catch((err: any) => {
					// setTenant({});
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const getTitle = () => {
		if (cancelMode) {
			return 'Cancelling Subscription';
		} else if (subscribeMode) {
			return 'Resubscribe';
		} else {
			return 'Changing Plan';
		}
	};

	const getSubTitle = () => {
		if (cancelMode) {
			return 'After canceling your subscription, you will no longer be able to use the Truuth services until you rejoin a paid plan.';
		} else if (subscribeMode) {
			return plan.code === TENANT_PLAN.PLAN_FREE
				? `Current subscription is free trial and it's now expired`
				: `Your previous subscription was ${plan.name}`;
		} else {
			return 'Choose a plan that fit for you';
		}
	};

	const getSubTitle2 = () => {
		if (cancelMode) {
			return 'You can select other Plans to continue using our service:';
		} else if (subscribeMode) {
			return plan.code === TENANT_PLAN.PLAN_FREE
				? 'Please select other plans to continue using our service.'
				: 'Please select here to keep using your previous plan or select a new plan from below.';
		} else {
			return '';
		}
	};

	if (plan?.salesChannel === 'AWS') {
		navigate('/tenant/:tenantAlias/account-overview');
	}

	return (
		<>
			<DisplayPlans
				title={getTitle()}
				subTitle={getSubTitle()}
				subTitle2={getSubTitle2()}
				plans={plansData}
				productCode={tenantCookie?.product?.code}
				currency={tenant.currency}
				showSubscriptionButton={showSubscriptionButtonOnHeader}
				handleChange={(plan) => handlePlanChange(subscribeMode ? 'SUBSCRIBE' : 'CHANGE_PLAN', plan)}
				subscriptionAction={subscribeWithCurrentPlan}
			/>

			{cancelMode && (
				<SubscriptionFooter
					title={
						'Still want to cancel subscription ? Remember you can comeback after cancelling to continue using Truuth on a paid plan.'
					}
					buttonLabel={'Cancel'}
					buttonType={'primary'}
					buttonSize={'large'}
					subscriptionAction={confirmCancelSubscription}
				/>
			)}

			{showSubscribeFooter && (
				<SubscriptionFooter
					title={`Your previous subscription was ${plan.name}, do you want to keep using it?`}
					buttonLabel='Use previous plan'
					buttonType={'secondary'}
					buttonSize={'medium'}
					subscriptionAction={subscribeWithCurrentPlan}
				/>
			)}

			<PlanConfirmDialog
				open={openChangePlanDialog}
				planName={newPlanName}
				onClose={onChangePlanDialogClose}
				onConfirm={downGradePlan}
			/>

			<PlanUpdateDialog
				open={openConfirmationDialog}
				planName={newPlanName}
				onClose={handleDialogClose}
			/>

			<ConfirmDialog
				open={openConfirmDialog}
				title='Cancel Subscription'
				subTitle='Are you sure you want to cancel subscription ?'
				subTitle2='You will not be able to use Truuth services until you join a paid plan.'
				cancelButtonLabel='No'
				submitButtonLabel='Yes, Cancel'
				onClose={() => setOpenConfirmDialog(false)}
				onConfirm={handleCancelSubscription}
			/>
		</>
	);
};

export default PlansPage;
