import React from 'react';

export function NotPerformed() {
	return (
		<svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M0.918418 0.523886L0 1.43584L1.46818 2.90401C0.789064 3.92591 0.394532 5.14832 0.394532 6.46773C0.394532 10.0379 3.29208 12.9355 6.86227 12.9355C8.18168 12.9355 9.40409 12.5409 10.426 11.8618L11.8942 13.33L12.8061 12.418L0.918418 0.523886ZM6.86227 11.6419C4.00999 11.6419 1.68808 9.32 1.68808 6.46773C1.68808 5.51051 1.95326 4.61796 2.41246 3.84183L9.48817 10.9175C8.71204 11.3767 7.81949 11.6419 6.86227 11.6419ZM4.23637 2.01793L3.29854 1.07364C4.32045 0.394532 5.54285 0 6.86227 0C10.4325 0 13.33 2.89754 13.33 6.46773C13.33 7.78715 12.9355 9.00955 12.2564 10.0315L11.3121 9.08717C11.7713 8.31751 12.0365 7.42496 12.0365 6.46773C12.0365 3.61546 9.71454 1.29355 6.86227 1.29355C5.90504 1.29355 5.01249 1.55872 4.23637 2.01793Z'
				fill='#70708F'
			/>
		</svg>
	);
}
