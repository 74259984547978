import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Grid, Divider, Button, Paper, Avatar, Card, CardHeader, Theme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { _getAuth, _getTenantsPerPage, _getTenantsPageIndex } from '../../store/selectors';
import { updateMenu } from '../../store/menu/actions';
import { setLoading, clearLoading } from '../../store/common/actions';
import { setVerificationPageIndex, resetVerifications } from '../../store/verification/actions';
import { resetUsers } from '../../store/user/actions';
import {
	setSelectedTenant,
	setTenantAlias,
	setAccountDetailsMode,
	setTenantsPageIndex,
	setTenantsPerPage,
} from '../../store/tenant/actions';
import { MENU } from '../../common/routes/menu';
import kycApiService from '../../service/kycApi.service';

import { setCookie } from 'shared_components/src/service/common.service';
import { AddIcon, TenantManagement } from 'shared_components/src/common/icons';
import { BORDER_COLOR, BOX_SHADOW } from 'shared_components/src/common/styles';
import {
	ITenantData,
	TENANT_MODE,
	TENANT_STATUS,
	TENANT_STATUS_DISPLAY,
} from 'shared_components/src/service/models/tenant';

import TenantSearch from './TenantSearch';
import TenantFilters from './TenantFilters';
import TenantList from './TenantList';
import { Pagination } from 'shared_components/src/components/verification';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		backgroundColor: '#ffffff',

		'& .MuiCardHeader-root': {
			padding: '16px 0',

			'@media screen and (max-width: 600px)': {
				flexDirection: 'column',
				alignItems: 'flex-start',

				'& .MuiCardHeader-content': {
					marginBottom: '8px',
				},
			},
		},

		'& .MuiCardHeader-title': {
			color: '#343451',
			fontSize: '26px',
			fontWeight: 600,
			lineHeight: '32px',
		},

		'& .MuiCardHeader-action': {
			margin: 0,
		},
	},

	card: {
		'&.MuiPaper-root': {
			backgroundColor: '#fafafa',
		},
	},

	avatar: {
		'&.MuiAvatar-root': {
			width: '24px',
			height: '24px',
			borderRadius: 0,
			backgroundColor: 'transparent',

			'& svg': {
				width: '100%',
				height: '100%',
				fill: '#9191AC',
			},

			'@media screen and (max-width: 1280px)': {
				width: 26.3,
				height: 30,
			},
			'@media screen and (max-width: 900px)': {
				width: 26.3,
				height: 30,
			},
			'@media screen and (max-width: 600px)': {
				display: 'none',
			},
		},
	},

	wrapper: {
		padding: '16px 0',
		backgroundColor: '#fafafa',
	},

	addTenant: {
		'&.MuiButton-contained': {
			color: '#fff',
			padding: '4px 16px',
			fontSize: '14px',
			minWidth: '80px',
			background: '#470D82',
			boxShadow: 'none',
			lineHeight: '24px',
			borderRadius: '6px',
			textTransform: 'none',

			'&:hover': {
				background: '#8C07DD',
			},

			'&:focus': {
				background: '#5D0DA1',
			},
		},
	},

	divider: {
		width: '100%',
		backgroundColor: '#E8E8EE',
	},

	table: {
		paddingTop: '16px',

		'& .tableHeader': {
			marginBottom: 25,
			'@media screen and (max-width: 1440px)': {
				marginBottom: 20,
			},

			'@media screen and (max-width: 1280px)': {
				marginBottom: 15,
			},

			'@media screen and (max-width: 600px)': {
				flexWrap: 'wrap',
				flexFlow: 'column-reverse',
				marginBottom: 18.6,
				padding: '0 16px',
			},
		},
		overflow: 'auto',
		'@media screen and (max-width: 600px)': {
			minWidth: 0,
			marginTop: 14.5,
		},
	},
}));

const TenantsPage = () => {
	const navigate = useNavigate();
	const authInfo = _getAuth();
	const classes = useStyles();
	const dispatch = useDispatch();
	const tenantsPerPage = _getTenantsPerPage();
	const tenantsPageIndex = _getTenantsPageIndex();

	useEffect(() => {
		dispatch(updateMenu(MENU.tenants));
		// Reset Verification and user data
		dispatch(resetVerifications());
		dispatch(setTenantAlias(''));
		dispatch(setVerificationPageIndex(1));
		dispatch(resetUsers());
	}, []);

	const SORTING_KEYS = {
		TENANT_NAME: 'organisationName',
		TENANT_ALIAS: 'alias',
		CREATED_ON: 'createdAt',
	};

	const [tenants, setTenants] = useState([]);
	const [tenantList, setTenantList] = useState([] as any);
	const [tenantsData, setTenantsData] = useState({} as any);
	const [searchString, setSearchString] = useState('');
	const [showAdvanceFilters, setShowAdvanceFilters] = useState(false);
	const [filters, setFilters] = useState({} as any);
	const [showEmptyTable, setShowEmptyTable] = useState(false);
	const [sortOrder, setSortOrder] = useState('desc' as any);
	const [columnSort, setColumnSort] = useState('CREATED_ON');
	const [showRecordsCount, setShowRecordsCount] = useState(false);

	useLayoutEffect(() => {
		const updateFilters = async () => {
			try {
				const filterStatus = sessionStorage.getItem('tenantFilterStatus');
				const filters = sessionStorage.getItem('tenantFilters');

				if (filterStatus) {
					const parsedFilters = JSON.parse(filterStatus);
					await setShowAdvanceFilters(!parsedFilters);
				}

				if (filters) {
					const parsedFilters = JSON.parse(filters);
					await setFilters(parsedFilters);
				}
			} catch (error) {
				console.error('Error retrieving filter data from session storage:', error);
			}
		};

		updateFilters();
	}, []);

	useEffect(() => {
		getTenants();
	}, [authInfo?.isLoggedin, sortOrder, columnSort, tenantsPerPage, tenantsPageIndex, filters]);

	const getTenants = () => {
		dispatch(setLoading());
		return new Promise((resolve) => {
			kycApiService
				.getTenantsList(
					tenantsPageIndex,
					tenantsPerPage,
					`${sortOrder === 'desc' ? '-' : ''}${SORTING_KEYS[columnSort]}`,
					{
						search: searchString,
						...filters,
					}
				)
				.then((res: any) => {
					if (res?.items?.length > 0) {
						setTenantsData(res);
						setTenants(res?.items);
					} else {
						setTenantsData({});
						setTenants([]);
					}
					setShowEmptyTable(res.count === 0 || res.error);
				})
				.catch((err: any) => {
					setTenants([]);
					setShowEmptyTable(true);
				})
				.finally(() => {
					dispatch(clearLoading());
					return resolve({ status: 'success' });
				});
		});
	};

	const findTenant = (alias) => {
		return tenants.find((tenant: ITenantData) => tenant?.alias === alias);
	};

	const handleOpenTenantDetails = (alias) => {
		const data = findTenant(alias) as unknown as ITenantData;
		const selectedTenant = {
			alias: data?.alias,
			organisationName: data?.organisationName,
			product: data?.product,
			currency: data?.currency,
		};
		dispatch(setSelectedTenant(data));
		dispatch(setTenantAlias(data.alias));
		dispatch(setAccountDetailsMode(TENANT_MODE.VIEW));
		setCookie('selectedTenant', JSON.stringify(selectedTenant));
		navigate(`/tenant/${data?.alias}/product-overview`);
	};

	const handleDeleteTenantDetails = (alias) => {
		console.log('deleteTenantDetails', alias);
	};

	const handleEditPaymentDetails = (alias) => {
		const data = findTenant(alias) as unknown as ITenantData;
		const selectedTenant = {
			alias: data.alias,
			organisationName: data.organisationName,
			product: data.product,
			currency: data.currency,
		};
		dispatch(setSelectedTenant(data));
		dispatch(setTenantAlias(data.alias));
		setCookie('selectedTenant', JSON.stringify(selectedTenant));
		navigate(`/tenant/${alias}/billing-payment`);
	};

	const handleEditTenantDetails = (alias) => {
		const data = findTenant(alias) as unknown as ITenantData;
		const selectedTenant = {
			alias: data.alias,
			organisationName: data.organisationName,
			product: data.product,
			currency: data.currency,
		};
		dispatch(setSelectedTenant(data));
		dispatch(setTenantAlias(data.alias));
		dispatch(setAccountDetailsMode(TENANT_MODE.EDIT));
		setCookie('selectedTenant', JSON.stringify(selectedTenant));
		navigate(`/tenant/${data?.alias}/account-details`);
	};

	const handleSubscribeAgain = (alias) => {
		const data = findTenant(alias) as unknown as ITenantData;
		const selectedTenant = {
			alias: data.alias,
			organisationName: data.organisationName,
			product: data.product,
			currency: data.currency,
		};
		dispatch(setSelectedTenant(data));
		dispatch(setTenantAlias(data.alias));
		setCookie('selectedTenant', JSON.stringify(selectedTenant));
		navigate(`/tenant/${data?.alias}/plan/subscribe`);
	};

	const openAddTenant = () => {
		navigate(`/tenant/add-tenant`);
	};

	const handleTenantSearch = async (value) => {
		dispatch(setTenantsPerPage(10));
		dispatch(setTenantsPageIndex(1));
		await setSearchString(value);
		handleRefreshTable();
	};

	const updateFilters = (filters) => {
		dispatch(setTenantsPageIndex(1));
		dispatch(setTenantsPerPage(10));
		setFilters(filters);
		setShowRecordsCount(true);
	};

	const resetFilters = () => {
		dispatch(setTenantsPageIndex(1));
		dispatch(setTenantsPerPage(10));
		setFilters({});
		setShowRecordsCount(false);

		try {
			sessionStorage.removeItem('tenantFilters');
		} catch (error) {
			console.error('Error saving tenant filters to session storage:', error);
		}

		try {
			sessionStorage.removeItem('tenantFilterStatus');
		} catch (error) {
			console.error('Error saving tenant filters status to session storage:', error);
		}
	};

	const toggleFilters = (value) => {
		if (value) {
			resetFilters();
		} else {
			try {
				sessionStorage.setItem('tenantFilterStatus', value);
			} catch (error) {
				console.error('Error saving tenant filters status to session storage:', error);
			}
		}
		setShowAdvanceFilters(!value);
	};

	const handleRefreshTable = () => {
		getTenants();
	};

	const sortTenants = (value) => {
		setColumnSort(value);
	};

	const captureRowsPerPageChange = (rowsPerPage: number) => {
		dispatch(setTenantsPerPage(rowsPerPage));
	};

	const handleChangePageIndex = (curPage: number) => {
		dispatch(setTenantsPageIndex(curPage + 1));
	};

	return (
		<Paper className={clsx(classes.root)} elevation={0}>
			<Card className={classes.card} elevation={0}>
				<CardHeader
					avatar={
						<Avatar aria-label="tenant" className={classes.avatar}>
							<TenantManagement fill={'#9191AC'} />
						</Avatar>
					}
					action={
						<Button
							id="add-tenant"
							variant="contained"
							color="primary"
							className={classes.addTenant}
							onClick={() => openAddTenant()}
						>
							<AddIcon fill={'white'} />
							<div style={{ marginLeft: '12px' }}>Add New Tenant</div>
						</Button>
					}
					title="Tenant Management"
				/>
			</Card>
			<Grid container className={classes.wrapper}>
				<TenantSearch
					searchString={searchString}
					setSearchString={setSearchString}
					showAdvanceFilters={showAdvanceFilters}
					onSearch={handleTenantSearch}
					toggleFilters={toggleFilters}
					handleRefreshTable={handleRefreshTable}
				/>
				<Divider className={classes.divider} style={{ marginTop: '16px' }} />
				{showAdvanceFilters && (
					<>
						<TenantFilters resetFilters={resetFilters} setFilters={updateFilters} />
						<Divider className={classes.divider} />
					</>
				)}
				<Grid container className={classes.table}>
					{showRecordsCount && (
						<div
							style={{
								color: '#515170',
								lineHeight: '24px',
								marginBottom: '16px',
							}}
						>
							{tenantsData.count || 0} results are found
						</div>
					)}
					<TenantList
						data={tenants}
						orderBy={columnSort}
						order={sortOrder}
						showEmptyTable={showEmptyTable}
						handleView={handleOpenTenantDetails}
						handleEditAccountDetails={handleEditTenantDetails}
						handleEditPaymentDetails={handleEditPaymentDetails}
						handleSubscribeAgain={handleSubscribeAgain}
						handleDelete={handleDeleteTenantDetails}
						sortTenants={sortTenants}
						setOrder={setSortOrder}
					/>
					{tenants?.length > 0 && (
						<>
							<Pagination
								page={tenantsData?.page - 1 || 0}
								rowsPerPage={tenantsPerPage}
								total={tenantsData?.count}
								handleRowsPerPageChange={captureRowsPerPageChange}
								handlePageChange={handleChangePageIndex}
							/>
						</>
					)}
				</Grid>
			</Grid>
		</Paper>
	);
};

export default TenantsPage;
