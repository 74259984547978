import React from 'react';
import { createRoot } from 'react-dom/client';
import * as serviceWorker from './shared/serviceWorker';
import { asyncWithLDProvider, ProviderConfig } from 'launchdarkly-react-client-sdk';


import './styles.scss';
import 'shared_components/src/assets/fonts/fonts.css';
import 'prismjs/themes/prism-coy.css';

import App from './shared/App';
import {
	APP_URL_PREFIX,
	LD_CLIENT_ID,
	// LD_CLIENT_EMAIL,
	// LD_CLIENT_FIRST_NAME,
	// LD_CLIENT_LAST_NAME,
} from 'shared_components/src/common/constants';

let rootApp = document.getElementById('root');

if (rootApp) {
	const reg = new RegExp(APP_URL_PREFIX + '(/.*)$');
	const hasPrefix = location.pathname.search(APP_URL_PREFIX);
	const removedPath = (reg.exec(location.pathname) || [])[1];
	let path = APP_URL_PREFIX;

	if (removedPath != undefined) {
		path += removedPath;
	} else {
		if (hasPrefix != -1) {
			path = location.pathname;
		} else {
			path += location.pathname;
		}
	}

	if (path) {
		// history.replace(path + history.location.search);
		window.history.replaceState({}, '', window.location.origin + path + window.location.search);
	}

	const baseUrl = window.location.hostname;
	let tenant = '';

	if (baseUrl === 'localhost') {
		tenant = 'console';
	} else {
		if (baseUrl.includes('truuth.id')) {
			const splittedUrl = baseUrl.split('.');
			tenant = splittedUrl.length > 0 ? splittedUrl[0] : '';
		}
	}

	(async () => {
		const LDProvider = await asyncWithLDProvider({
			clientSideID: LD_CLIENT_ID,
			context: {
				kind: 'user',
				key: tenant,
				firstName: tenant,
				lastName: '',
				email: '',
			},
			reactOptions: {
				useCamelCaseFlagKeys: false,
			},
		} as ProviderConfig);

		createRoot(rootApp).render(
			<React.StrictMode>
				<LDProvider>
					<App />
				</LDProvider>
			</React.StrictMode>
		);
	})();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
